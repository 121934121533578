import '../tables.css';

import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { competitionsState } from '../../../recoil/atoms/competitionsState';
import { userSettingsState } from '../../../recoil/atoms/userSettingsState';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { useCallback, useMemo } from 'react';
import { useTable, useBlockLayout, useSortBy, useFilters } from 'react-table';
import { useSticky } from 'react-table-sticky';

import { getCareerColumns } from './careerColumns';
import { CareerIterationEntry } from '../../playerView/PlayerViewCareer';
import { useWindowSize } from '../../../../common/hooks/WindowSize';
import { getNavigationWidth } from '../../navigation/Navigation';
import { FlexibleJsonMapping } from '../../../types';


interface CareerTableProps {
  data: CareerIterationEntry[];
  isNationalTeam: boolean;
}

export const CareerTable: React.FC<CareerTableProps> = ({ data, isNationalTeam }) => {

  const userConfig = useRecoilValue(userConfigState);
  const competitions = useRecoilValue(competitionsState);
  const userSettings = useRecoilValue(userSettingsState);

  const width = useWindowSize().width;

  const getTableWidth = useCallback((currentWidth: number) => {
    return (currentWidth - 24 - getNavigationWidth(userConfig)) * 0.986;
  }, [userConfig]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: any[] = useMemo(
    () => getCareerColumns(
      isNationalTeam,
      userConfig,
      getTableWidth(width),
      competitions,
      userSettings?.seasonStatsToggles ?? {}
    ),
    [isNationalTeam, userConfig, getTableWidth, width, competitions, userSettings?.seasonStatsToggles]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useSortBy,
    useBlockLayout,
    useSticky
  );


  const getExtendedRowAndCellClassName = (index: number) => {
    return index % 2 === 0 ? 'even' : 'odd';
  };


  return (
    <div
      {...getTableProps()}
      className='season-table career-table'
      style={{ overflowX: 'hidden', overflowY: isNationalTeam ? 'hidden' : 'auto' }}
    >

      {headerGroups.map(headerGroup => {
        const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();
        return (
          <div
            key={key}
            {...restHeaderGroupProps}
            className='career-table-header-group'
          >
            {headerGroup.headers.map((column: FlexibleJsonMapping) => {
              const { key: columnKey, ...restColumnProps } = column.getHeaderProps(
                column.isStickyColumn ? undefined : column.getSortByToggleProps({ title: column.title })
              );
              return (
                <div
                  key={columnKey}
                  {...restColumnProps}
                  className='career-table-header-cell'
                >
                  {column.render('Header')}
                  <div className='table-sort-icon'>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? <KeyboardArrowDownIcon style={{ fontSize: 22, marginBottom: -6, marginTop: -6 }} />
                        : <KeyboardArrowUpIcon style={{ fontSize: 22, marginBottom: -6, marginTop: -6 }} />
                      : ''}
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}

      <div
        {...getTableBodyProps()}
        className='career-table-body'>
        {rows.map((row, i) => {
          prepareRow(row);
          const { key, ...restRowProps } = row.getRowProps();
          return (
            <div
              key={key}
              {...restRowProps}
              className={'season-table-body-row career-table-body-row-' + getExtendedRowAndCellClassName(i)}
            >
              {row.cells.map(cell => {
                const { key: cellKey, ...restCellProps } = cell.getCellProps();
                return (
                  <div
                    key={cellKey}
                    {...restCellProps}
                    className={
                      'season-table-body-cell career-table-body-cell career-table-body-cell-'
                      + getExtendedRowAndCellClassName(i)
                      + (isNationalTeam ? ' career-table-body-cell-national' : ' career-table-body-cell-club')
                    }
                  >
                    {cell.render('Cell')}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>

    </div>
  );
};
