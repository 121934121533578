import { useSetRecoilState } from 'recoil';
import { globalModalPropsState } from '../atoms/globalModalPropsState';
import { GlobalModalProps, PlayerEntry } from '../../types';


export const useGlobalModal = () => {

  const setGlobalModalProps = useSetRecoilState(globalModalPropsState);


  const handleOpenConfirmModal = (
    confirm: () => void,
    confirmTitleKey?: string,
    confirmInfoKey?: string,
    onClose?: () => void,
    confirmKey?: string,
    cancelKey?: string,
    delay?: number,
  ) => {
    const globalModalProps: GlobalModalProps = {
      globalModalType: 'ConfirmModal',
      closeGlobalModal: () => {
        setGlobalModalProps(undefined);
        if (onClose) {
          onClose();
        }
      },
      confirm: () => {
        confirm();
        setGlobalModalProps(undefined);
      },
      confirmTitleKey,
      confirmInfoKey,
      confirmKey,
      cancelKey,
      delay,
    };
    setGlobalModalProps(globalModalProps);
  };


  const handleOpenTextModal = (
    text: string,
    width?: number,
    fontSize?: number,
    delay?: number,
  ) => {
    const globalModalProps: GlobalModalProps = {
      globalModalType: 'TextModal',
      closeGlobalModal: () => setGlobalModalProps(undefined),
      text,
      width,
      fontSize,
      delay,
    };
    setGlobalModalProps(globalModalProps);
  };


  const handleOpenPdfModal = (
    fileUrl: string,
    fileName: string,
    delay?: number,
  ) => {
    const globalModalProps: GlobalModalProps = {
      globalModalType: 'PdfModal',
      closeGlobalModal: () => setGlobalModalProps(undefined),
      fileUrl,
      fileName,
      delay,
    };

    setGlobalModalProps(globalModalProps);
  };


  const handleOpenPlayerViewModal = (
    player: PlayerEntry,
    closeModal: (feedback?: boolean) => void,
    squadId?: string,
    squadName?: string,
    showPlayerNameInTitle?: boolean,
  ) => {
    const globalModalProps: GlobalModalProps = {
      globalModalType: 'PlayerViewModal',
      closeGlobalModal: closeModal,
      player,
      squadId,
      squadName,
      showPlayerNameInTitle,
    };

    setGlobalModalProps(globalModalProps);
  };


  return {
    handleOpenConfirmModal,
    handleOpenTextModal,
    handleOpenPdfModal,
    handleOpenPlayerViewModal,
  };
};
