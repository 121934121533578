import '../tables.css';

import { useCallback, useMemo, useState } from 'react';
import { useTable, useBlockLayout, useFilters } from 'react-table';
import { useSticky } from 'react-table-sticky';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import HomeIcon from '@mui/icons-material/Home';

import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { userSettingsState } from '../../../recoil/atoms/userSettingsState';
import { clubSettingsState } from '../../../recoil/atoms/clubSettingsState';
import { selectedPlayerState } from '../../../recoil/atoms/selectedPlayerState';
import { FlexibleJsonMapping, PlayerEntry } from '../../../types';
import { useWindowSize } from '../../../../common/hooks/WindowSize';
import { getNavigationWidth } from '../../navigation/Navigation';
import { getOwnTeamColumns } from './ownTeamColumns';
import { staticLanguageMap } from '../../../../common/static/staticLanguageMap';
import { Dialog } from '@mui/material';
import { EditPlayerInfoModal } from '../../modals/EditPlayerInfoModal';
import { userHasEconomyAccess } from '../../../utils/userUtils';
// import { setPlayerProperty } from '../../../services/firestore/players';


interface OwnTeamTableProps {
  data: FlexibleJsonMapping[];
  tableSortBy: FlexibleJsonMapping | undefined;
  setTableSortBy: (value: FlexibleJsonMapping | undefined) => void;
}

export const OwnTeamTable: React.FC<OwnTeamTableProps> = ({
  data,
  tableSortBy,
  setTableSortBy,
}) => {

  const userConfig = useRecoilValue(userConfigState);
  const userSettings = useRecoilValue(userSettingsState);
  const clubSettings = useRecoilValue(clubSettingsState);
  const setSelectedPlayer = useSetRecoilState(selectedPlayerState);

  const [isEditPlayerInfoModalOpen, setIsEditPlayerInfoModalOpen] = useState(false);
  const [editPlayerInfoProperty, setEditPlayerInfoProperty] = useState<string | undefined>(undefined);
  const [playerToEdit, setPlayerToEdit] = useState<PlayerEntry | undefined>(undefined);

  const width = useWindowSize().width;

  const getTableWidth = useCallback((currentWidth: number) => {
    return currentWidth - 24 - getNavigationWidth(userConfig);
  }, [userConfig]);

  const hasEconomyAccess = userHasEconomyAccess(userConfig);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: any[] = useMemo(
    () => getOwnTeamColumns(
      getTableWidth(width),
      hasEconomyAccess,
      userConfig,
      clubSettings?.countryCode ?? 'NO',
      userSettings?.seasonStatsToggles ?? {},
    ),
    [getTableWidth, width, hasEconomyAccess, userConfig, clubSettings?.countryCode, userSettings?.seasonStatsToggles]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useBlockLayout,
    useSticky
  );

  const handleCellClick = (row: FlexibleJsonMapping, cell: FlexibleJsonMapping, event: React.MouseEvent<HTMLElement>) => {
    if (cell.column.id === 'fullname' || !userConfig) return;

    event.stopPropagation();

    // if (cell.column.id === 'homegrown') {
    //   setPlayerProperty(row.original.id, 'homegrown', !row.original.homegrown, userConfig.club);
    //   return;
    // };

    setPlayerToEdit(row.original);
    setEditPlayerInfoProperty(cell.column.id);
    setIsEditPlayerInfoModalOpen(true);
  };


  const handleHeaderCellClick = (column: FlexibleJsonMapping) => {
    if (column.id === 'fullname') return;

    if (tableSortBy && tableSortBy.id === column.id) {
      if (tableSortBy.desc === false) {
        setTableSortBy({ id: column.id, desc: true });
      }
      else {
        setTableSortBy(undefined);
      }
    }
    else {
      setTableSortBy({ id: column.id, desc: false });
    }
  };


  const isSummaryRow = (row: FlexibleJsonMapping) => {
    return row.original.fullname === 'tableSummary';
  };


  const tableShouldOverflow = () => {
    const tableWidth = getTableWidth(width);
    const baseWidth = 805;
    const salaryOffset = hasEconomyAccess ? 300 : 0;
    const safetyOffset = 20;

    return tableWidth < baseWidth + salaryOffset + safetyOffset;
  };

  return (
    <div
      {...getTableProps()}
      className='player-table'
      style={{ overflowX: tableShouldOverflow() ? 'auto' : 'hidden' }}
    >

      <Dialog
        open={isEditPlayerInfoModalOpen}
        onClose={() => setIsEditPlayerInfoModalOpen(false)}
        PaperProps={{ sx: { borderRadius: '0px' } }}
      >
        <EditPlayerInfoModal
          editPlayerInfoProperty={editPlayerInfoProperty}
          closeModal={() => setIsEditPlayerInfoModalOpen(false)}
          playerToEdit={playerToEdit}
          isAcademyPlayer={userSettings?.seasonStatsToggles?.showAcademyPlayersInOwnTeamTable ?? false}
        />
      </Dialog>

      {headerGroups.map((headerGroup: FlexibleJsonMapping) => {
        const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();
        return (
          <div
            key={key}
            {...restHeaderGroupProps}
            className='player-table-header-group own-team-table-header-group'
          >
            {headerGroup.headers.map((column: FlexibleJsonMapping) => {
              const { key: columnKey, ...restColumnProps } = column.getHeaderProps(({ title: column.title }));
              return (
                <div
                  key={columnKey}
                  {...restColumnProps}
                  className={'own-team-table-header-cell' + (column.needAccess ? ' own-team-table-header-cell-need-access' : '')}
                  onClick={() => handleHeaderCellClick(column)}
                >
                  {column.needAccess && (
                    <div className='own-team-table-access-icon'>
                      <AdminPanelSettingsIcon style={{ fontSize: 20, color: '#ffffffee' }} />
                    </div>
                  )}

                  {column.id === 'homegrown' && <HomeIcon style={{ fontSize: 20, color: '#ffffffdd', marginBottom: -4, marginTop: -3 }} />}

                  {column.id !== 'homegrown' && column.render('Header')}

                  <div className='table-sort-icon'>
                    {tableSortBy && tableSortBy.id === column.id
                      ? tableSortBy.desc
                        ? <KeyboardArrowDownIcon style={{ fontSize: 22, marginBottom: -6, marginTop: -6 }} />
                        : <KeyboardArrowUpIcon style={{ fontSize: 22, marginBottom: -6, marginTop: -6 }} />
                      : ''
                    }
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}

      <div
        {...getTableBodyProps()}
        className='player-table-body'>
        {rows.map((row, i) => {
          prepareRow(row);
          const { key: rowKey, ...restRowProps } = row.getRowProps();
          return (
            <div
              key={rowKey}
              {...restRowProps}
              className={
                (i % 2 === 0 ? 'own-team-table-body-row-even' : 'own-team-table-body-row-odd') +
                (isSummaryRow(row) ? ' own-team-table-body-row-summary ' : '')
              }
              onClick={() => isSummaryRow(row) ? null : setSelectedPlayer({ id: row.original.id, fullname: row.original.fullname })}
            >
              {row.cells.map((cell: FlexibleJsonMapping) => {
                const { key: cellKey, ...restCellProps } = cell.getCellProps();
                return (
                  <div
                    key={cellKey}
                    {...restCellProps}
                    className={
                      (i % 2 === 0 ? 'own-team-table-body-cell-even' : 'own-team-table-body-cell-odd') +
                      (isSummaryRow(row) ? ' own-team-table-body-cell-summary' : ' own-team-table-body-cell')
                    }
                    title={userConfig && row.original.fullname !== 'tableSummary' ? staticLanguageMap['edit'][userConfig.language] : ''}
                    onClick={(event) => isSummaryRow(row) ? null : handleCellClick(row, cell, event)}
                  >
                    {cell.render('Cell')}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>

    </div>
  );
};
