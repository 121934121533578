/* eslint-disable max-len */
import { NestedJsonMapping } from '../../platform/types';


export const staticLanguageMap: NestedJsonMapping = {

  'about': {
    'en': 'About',
    'no': 'Om oss',
  },
  'academy': {
    'en': 'Academy',
    'no': 'Akademi',
  },
  'academyPlayers': {
    'en': 'Academy players',
    'no': 'Akademispillere',
  },
  'acceleration': {
    'en': 'Acceleration',
    'no': 'Rykk',
  },
  'access': {
    'en': 'Access',
    'no': 'Tilgang',
  },
  'account': {
    'en': 'Account',
    'no': 'Konto',
  },
  'accountCreated': {
    'en': 'Account was successfully created',
    'no': 'Kontoen ble opprettet',
  },
  'accountCreatedInfo': {
    'en': 'We have sent an email to the address below with instructions on how the user can set their own password and activate the account.',
    'no': 'Vi har sendt en e-post til e-postadressen under med instrukser om hvordan brukeren kan sette sitt eget passord og aktivere kontoen.',
  },
  'accounts': {
    'en': 'Accounts',
    'no': 'Kontoer',
  },
  'achieved': {
    'en': 'Achieved',
    'no': 'Oppnådd',
  },
  ' achieved': {
    'en': ' achieved',
    'no': ' oppnådd',
  },
  'activityLast14Days': {
    'en': 'Activity last 14 days',
    'no': 'Aktivitet siste 14 dagene',
  },
  'add': {
    'en': 'Add',
    'no': 'Legg til',
  },
  'addAttachment': {
    'en': 'Add attachment',
    'no': 'Legg til vedlegg',
  },
  'addClause': {
    'en': 'Add clause',
    'no': 'Legg til klausul',
  },
  'addClause?': {
    'en': 'Add clause?',
    'no': 'Legge til klausul?',
  },
  'addClauseForBoughtPlayerInfo': {
    'en': 'Do you want to add a clause for the bought player?',
    'no': 'Vil du legge til en klausul for den kjøpte spilleren?',
  },
  'addClauseForSoldPlayerInfo': {
    'en': 'Do you want to add a clause for the sold player?',
    'no': 'Vil du legge til en klausul for den solgte spilleren?',
  },
  'addClausesForNextClub': {
    'en': 'Do you want to add further clauses for the next club?',
    'no': 'Vil du legge til videre klausuler for neste klubb?',
  },
  'addCondition': {
    'en': 'Add condition',
    'no': 'Legg til betingelse',
  },
  'addFurtherClauses': {
    'en': 'Add further clauses',
    'no': 'Legg til videre klausuler',
  },
  'addNewAlert': {
    'en': 'Add new search',
    'no': 'Opprett nytt søk',
  },
  'addNewClause': {
    'en': 'Add new sale clause',
    'no': 'Opprett ny klausul',
  },
  'added': {
    'en': 'added',
    'no': 'la til',
  },
  'addedPlayerTo': {
    'en': 'added the player to',
    'no': 'la til spilleren på',
  },
  'addNewAccount': {
    'en': 'Add new account',
    'no': 'Legg til ny konto',
  },
  'addNewSquad': {
    'en': 'Add new squad',
    'no': 'Legg til ny stall',
  },
  'addNewTeam': {
    'en': 'Add new team',
    'no': 'Legg til nytt lag',
  },
  'addOrEditPayments': {
    'en': 'Add or edit payments',
    'no': 'Legg til eller endre betalinger',
  },
  'addPayment': {
    'en': 'Add payment',
    'no': 'Legg til betaling',
  },
  'addPayments': {
    'en': 'Add payments',
    'no': 'Legg til betalinger',
  },
  'addPercentage': {
    'en': 'Add percentage',
    'no': 'Legg til prosent',
  },
  'addPlayer': {
    'en': 'Add player',
    'no': 'Legg til spiller',
  },
  'addPlayerWithoutData': {
    'en': 'Add player without data',
    'no': 'Legg til spiller uten data',
  },
  'addPlayerWithoutData?': {
    'en': 'Add a player without data?',
    'no': 'Legge til en spiller uten data?',
  },
  'addRole': {
    'en': 'Provide role',
    'no': 'Oppgi rolle',
  },
  'addSubClause': {
    'en': 'Add sub-clause',
    'no': 'Opprett underklausul',
  },
  'addToSquad': {
    'en': 'Add to squad',
    'no': 'Legg til stall',
  },
  'addToTeam': {
    'en': 'Add to team',
    'no': 'Legg til lag',
  },
  'addUnconditionedPayment': {
    'en': 'Add unconditioned payment',
    'no': 'Legg til ubetinget betaling',
  },
  'addUnconditionedPayments': {
    'en': 'Add unconditioned payments',
    'no': 'Legg til ubetingede betalinger',
  },
  'admin': {
    'en': 'Admin',
    'no': 'Admin',
  },
  '_advanced': {
    'en': 'advanced',
    'no': 'avansert',
  },
  'advancedData': {
    'en': 'Advanced data',
    'no': 'Avansert data',
  },
  'advancedSeasonCondition': {
    'en': 'Advanced season condition',
    'no': 'Avansert sesongbetingelse',
  },
  'advancedSeasonConditionInfo': {
    'en': 'An advanced season condition consists of a set of sub-conditions, where each sub-condition is associated with a unique amount, and may also contain a set of common conditions.\nFor an advanced season condition to be fulfilled, one of the sub-conditions must be fulfilled in addition to all the common conditions.\nExample:\nYou will receive 300.000,- if the player plays 50% of the matches in any season and the club qualifies for the Champions League.\nYou will receive 200.000,- if the player plays 50% of the matches in any season and the club qualifies for the Europa League.\nYou will receive 100.000,- if the player plays 50% of the matches in any season and the club qualifies for the Conference League.\nHere, the player playing 50% of the matches is a common condition, because all the amounts (300.000, 200.000 and 100.000) are conditioned on this, while qualification for the different European cups will be sub-conditions, each associated with a unique amount.',
    'no': 'En avansert sesongbetingelse består av et sett med delbetingelser, hvor hver delbetingelse er tilknyttet et unikt beløp, og kan i tillegg inneholde et sett med felles betingelser.\nFor at en avansert sesongbetingelse skal oppfylles, må én av delbetingelsene oppfylles i tillegg til alle de felles betingelsene.\nEksempel:\nDere skal motta 300.000,- dersom spilleren, i en hvilken som helst sesong, spiller 50% av kampene og klubben kvalifiserer seg til Champions League.\nDere skal motta 200.000,- dersom spilleren, i en hvilken som helst sesong, spiller 50% av kampene og klubben kvalifiserer seg til Europa League.\nDere skal motta 100.000,- dersom spilleren, i en hvilken som helst sesong, spiller 50% av kampene og klubben kvalifiserer seg til Conference League.\nHer vil det at spilleren spiller 50% av kampene være en felles betingelse, fordi alle beløpene (300.000, 200.000 og 100.000) betinger dette, mens kvalifisering til de ulike Europa-cupene vil være delbetingelser tilknyttet hvert sitt beløp.',
  },
  'age': {
    'en': 'Age',
    'no': 'Alder',
  },
  'ageDistribution': {
    'en': 'Age distribution',
    'no': 'Aldersfordeling',
  },
  'alerts': {
    'en': 'Alerts',
    'no': 'Varsler',
  },
  'alphabetical': {
    'en': 'Alphabetical',
    'no': 'Alfabetisk',
  },
  'all': {
    'en': 'All',
    'no': 'Alle',
  },
  'allArchivedTeams': {
    'en': 'All archived teams',
    'no': 'Alle arkiverte lag',
  },
  'allCompetitions': {
    'en': 'All competitions',
    'no': 'Alle konkurranser',
  },
  '_allCompetitions': {
    'en': 'all competitions',
    'no': 'alle konkurranser',
  },
  '(allEuropeanQualifyingMatches)': {
    'en': '(qualifiers for all European cups)',
    'no': '(kvalifiseringskamper til alle e-cuper)',
  },
  'allLeagues': {
    'en': 'All leagues',
    'no': 'Alle ligaer',
  },
  '_allLeagues': {
    'en': 'all leagues',
    'no': 'alle ligaer',
  },
  'allLeaguesAndCups': {
    'en': 'All leagues and cups',
    'no': 'Alle ligaer og cuper',
  },
  'allNationalTeams': {
    'en': 'All national teams',
    'no': 'Alle landslag',
  },
  '_allNationalTeams': {
    'en': 'all national teams',
    'no': 'alle landslag',
  },
  'allReports': {
    'en': 'All reports',
    'no': 'Alle rapporter',
  },
  'allSearches': {
    'en': 'All searches',
    'no': 'Alle søk',
  },
  'allSeasons': {
    'en': 'All seasons',
    'no': 'Alle sesonger',
  },
  '_allSeasons': {
    'en': 'all seasons',
    'no': 'alle sesonger',
  },
  'amount': {
    'en': 'Amount',
    'no': 'Beløp',
  },
  '_amount': {
    'en': 'amount',
    'no': 'beløp',
  },
  '_amounts': {
    'en': 'amounts',
    'no': 'beløp',
  },
  'amountOrPercentage': {
    'en': 'Amount or percentage',
    'no': 'Antall eller prosent',
  },
  'anAttachment': {
    'en': 'an attachment',
    'no': 'et vedlegg',
  },
  '_and': {
    'en': 'and',
    'no': 'og',
  },
  'archive': {
    'en': 'Archive',
    'no': 'Arkiv',
  },
  'archived': {
    'en': 'archived',
    'no': 'arkiverte',
  },
  'archivedSquad': {
    'en': 'archived the squad',
    'no': 'arkiverte stallen',
  },
  'archivedTeam': {
    'en': 'archived the team',
    'no': 'arkiverte laget',
  },
  'archivedTeams': {
    'en': 'Archived teams',
    'no': 'Arkiverte lag',
  },
  'archiveSquad': {
    'en': 'Archive squad',
    'no': 'Arkiver stall',
  },
  'archiveSquadInfo': {
    'en': 'The current squad will be archived',
    'no': 'Nåværende stall vil bli arkivert',
  },
  'archiveTeam': {
    'en': 'Archive team',
    'no': 'Arkiver lag',
  },
  'archiveTeamInfo': {
    'en': 'The current team will be archived',
    'no': 'Nåværende lag vil bli arkivert',
  },
  'assists': {
    'en': 'Assists',
    'no': 'Assists', // 'Målgivende',
  },
  'atLeast': {
    'en': 'at least',
    'no': 'minst',
  },
  'attachment': {
    'en': 'attachment',
    'no': 'vedlegg',
  },
  'Attachment': {
    'en': 'Attachment',
    'no': 'Vedlegg',
  },
  'attachments': {
    'en': 'Attachments',
    'no': 'Vedlegg',
  },
  'Attacker': {
    'en': 'Attacker',
    'no': 'Angriper',
  },
  'attitude': {
    'en': 'Attitude',
    'no': 'Holdning',
  },
  'average': {
    'en': 'Average',
    'no': 'Snitt',
  },
  'averageAgePerMinutePlayed': {
    'en': 'Average age per minute played',
    'no': 'Gjennomsnittsalder per spilte minutt',
  },
  'averageAgePerMinutePlayedTitle': {
    'en': 'Average age per minute played',
    'no': 'Gjennomsnittsalder per spilte minutt',
  },
  'averageEliteserien': {
    'en': 'Eliteserien average',
    'no': 'Gjennomsnittet i Eliteserien',
  },
  'averageMatchReports': {
    'en': 'The gray ratings are the average of your match reports',
    'no': 'De grå vurderingene er snittet av dine kamprapporter',
  },
  'averageRating': {
    'en': 'Average of all scout reports',
    'no': 'Gjennomsnittet av alle speiderrapporter',
  },

  'birthDate': {
    'en': 'Birth date',
    'no': 'Fødselsdato',
  },
  'birthYear': {
    'en': 'Birth year',
    'no': 'Fødselsår',
  },
  'bonuses': {
    'en': 'Bonuses',
    'no': 'Bonuser',
  },
  'bonusesCost': {
    'en': 'Expected bonuses',
    'no': 'Forventede bonuser',
  },
  'boughtFrom': {
    'en': 'Bought from',
    'no': 'Kjøpt fra',
  },
  'boughtPlayers': {
    'en': 'Bought players',
    'no': 'Kjøpte spillere',
  },
  'budget': {
    'en': 'Budget',
    'no': 'Budsjett',
  },

  'caseStudy': {
    'en': 'Case study',
    'no': 'Case-studie',
  },
  'cancel': {
    'en': 'Cancel',
    'no': 'Avbryt',
  },
  'cannotSearchByNameAndFilters': {
    'en': 'You cannot search by both name and filters',
    'no': 'Man kan ikke søke på navn og filtre samtidig',
  },
  'career': {
    'en': 'Career',
    'no': 'Karriere',
  },
  'carry': {
    'en': 'Carry',
    'no': 'Driv',
  },
  'changePosition': {
    'en': 'Change position',
    'no': 'Endre posisjon',
  },
  'changesWillNotBeSaved': {
    'en': 'The changes will not be saved',
    'no': 'Endringene vil ikke bli lagret',
  },
  'clause': {
    'en': 'Clause',
    'no': 'Klausul',
  },
  'clauses': {
    'en': 'Clauses',
    'no': 'Klausuler',
  },
  'clauseWillNotBeSaved': {
    'en': 'The clause will not be saved',
    'no': 'Klausulen vil ikke bli lagret',
  },
  'club': {
    'en': 'Club',
    'no': 'Klubb',
  },
  'clubDeveloped': {
    'en': 'Club developed',
    'no': 'Klubbutviklet',
  },
  'clubs': {
    'en': 'Clubs',
    'no': 'Klubber',
  },
  'clubValue': {
    'en': 'Club value',
    'no': 'Klubbverdi',
  },
  'club_index': {
    'en': 'Club index',
    'no': 'Klubbindeks',
  },
  'club_rating': {
    'en': 'Club rating',
    'no': 'Klubbrating',
  },
  'clubIndex': {
    'en': 'Club index',
    'no': 'Klubbindeks',
  },
  'clubRating': {
    'en': 'Club rating',
    'no': 'Klubbrating',
  },
  'clubRatingDevelopment': {
    'en': 'Club rating development',
    'no': 'Utvikling i klubbrating',
  },
  'clubTeam': {
    'en': 'Club team',
    'no': 'Klubblag',
  },
  'clubTeamTitle': {
    'en': 'Club team (since 2015)',
    'no': 'Klubblag (siden 2015)',
  },
  'colorBasis': {
    'en': 'Color basis',
    'no': 'Fargegrunnlag',
  },
  'comment': {
    'en': 'comment',
    'no': 'kommentar',
  },
  'commented': {
    'en': 'commented',
    'no': 'kommenterte',
  },
  'Comment': {
    'en': 'Comment',
    'no': 'Kommentar',
  },
  'commonConditions': {
    'en': 'Common conditions',
    'no': 'Felles betingelser',
  },
  'compare': {
    'en': 'Compare',
    'no': 'Sammenlign',
  },
  'composure': {
    'en': 'Composure',
    'no': 'Ro',
  },
  'condition': {
    'en': 'Condition',
    'no': 'Betingelse',
  },
  ' condition': {
    'en': ' condition',
    'no': ' betingelse',
  },
  'conditions': {
    'en': 'Conditions',
    'no': 'Betingelser',
  },
  'conditionWillNotBeSaved': {
    'en': 'The condition will not be saved',
    'no': 'Betingelsen vil ikke bli lagret',
  },
  'confirm': {
    'en': 'Confirm',
    'no': 'Bekreft',
  },
  'confirmEdits': {
    'en': 'Confirm edits',
    'no': 'Bekreft endringer',
  },
  'confirmMaxAmount': {
    'en': 'Confirm max amount',
    'no': 'Bekreft maksbeløp',
  },
  'confirmNewAccess': {
    'en': 'Confirm new access',
    'no': 'Bekreft ny tilgang',
  },
  'confidence': {
    'en': 'Precision',
    'no': 'Presisjon',
  },
  'confirmContractExpiration': {
    'en': 'Confirm contract expiration',
    'no': 'Bekreft ny utløpsdato',
  },
  'connectPlayer': {
    'en': 'Connect player',
    'no': 'Koble spiller',
  },
  'contract': {
    'en': 'Contract',
    'no': 'Kontrakt',
  },
  'contracts': {
    'en': 'Contracts',
    'no': 'Kontrakter',
  },
  'contractLength': {
    'en': 'Contract length',
    'no': 'Kontraktslengde',
  },
  'contractLengths': {
    'en': 'Contract lengths',
    'no': 'Kontraktslengder',
  },
  'contractStatus': {
    'en': 'Contract status',
    'no': 'Kontraktsstatus',
  },
  'country': {
    'en': 'Country',
    'no': 'Land',
  },
  'createAccount': {
    'en': 'Create account',
    'no': 'Opprett konto',
  },
  'created': {
    'en': 'created',
    'no': 'opprettet',
  },
  'createdSquad': {
    'en': 'created the squad',
    'no': 'opprettet stallen',
  },
  'createdTeam': {
    'en': 'created the team',
    'no': 'opprettet laget',
  },
  'createSearch': {
    'en': 'Create search',
    'no': 'Opprett søk',
  },
  'createSquad': {
    'en': 'Create squad',
    'no': 'Opprett stall',
  },
  'createTeam': {
    'en': 'Create team',
    'no': 'Opprett lag',
  },
  'creatingSearchInfo': {
    'en': 'The search was created and is now being run - this may take up to 30 seconds',
    'no': 'Søket ble opprettet og kjøres nå - dette kan ta opptil 30 sekunder',
  },
  'cups': {
    'en': 'Cups',
    'no': 'Cuper',
  },
  'currency': {
    'en': 'Currency',
    'no': 'Valuta',
  },

  'date': {
    'en': 'Date',
    'no': 'Dato',
  },
  'day': {
    'en': 'Day',
    'no': 'Dag',
  },
  'decisions': {
    'en': 'Decisions',
    'no': 'Avgjørelser',
  },
  'Defender': {
    'en': 'Defender',
    'no': 'Forsvarer',
  },
  'defending': {
    'en': 'Defending',
    'no': 'Forsvar',
  },
  'delete': {
    'en': 'Delete',
    'no': 'Slett',
  },
  'deleteClause': {
    'en': 'Delete clause',
    'no': 'Slett klausul',
  },
  'deleteComment': {
    'en': 'Delete comment',
    'no': 'Slett kommentar',
  },
  'deleteCondition': {
    'en': 'Delete condition',
    'no': 'Slett betingelse',
  },
  'deleteMaxAmount': {
    'en': 'Delete max amount',
    'no': 'Slett maksbeløp',
  },
  'deletePayment': {
    'en': 'Delete payment',
    'no': 'Slett betaling',
  },
  'deletePlayer': {
    'en': 'Delete player',
    'no': 'Slett spiller',
  },
  'deletePlayerInfo': {
    'en': 'The player will  be permanently deleted from the platform',
    'no': 'Spilleren vil bli slettet permanent fra plattoformen',
  },
  'deleteProvidedValue': {
    'en': 'Delete provided value',
    'no': 'Slett oppgitt verdi',
  },
  'deleteRating': {
    'en': 'Delete rating',
    'no': 'Slett rangering',
  },
  'deleteSearch': {
    'en': 'Delete search',
    'no': 'Slett søk',
  },
  'deleteSubClause': {
    'en': 'Delete sub-clause',
    'no': 'Slett underklausul',
  },
  'deleteSubClauseInfo': {
    'en': 'If you delete this sub-clause, all subsequent sub-clauses will also be deleted, and the action cannot be undone',
    'no': 'Dersom du sletter denne underklausulen, vil også alle påfølgende underklausuler bli slettet, og handlingen kan ikke angres',
  },
  'deleteSquad': {
    'en': 'Delete squad',
    'no': 'Slett stall',
  },
  'deleteTeam': {
    'en': 'Delete team',
    'no': 'Slett lag',
  },
  'details': {
    'en': 'Details',
    'no': 'Detaljer',
  },
  'development': {
    'en': 'Development',
    'no': 'Utvikling',
  },
  'didReset': {
    'en': 'reset',
    'no': 'nullstilte',
  },
  'didResetSquad': {
    'en': 'reset the squad',
    'no': 'nullstilte stallen',
  },
  'didResetTeam': {
    'en': 'reset the team',
    'no': 'nullstilte laget',
  },
  'discard': {
    'en': 'Discard',
    'no': 'Fjern',
  },
  'discardChanges?': {
    'en': 'Discard changes?',
    'no': 'Forkaste endringene?',
  },
  'discardCondition': {
    'en': 'Discard condition',
    'no': 'Forkast betingelse',
  },
  'discardCondition?': {
    'en': 'Discard condition?',
    'no': 'Forkaste betingelsen?',
  },
  'discardedPlayersTitle': {
    'en': 'Players discarded from the search',
    'no': 'Spillere som er fjernet fra søket',
  },
  'discardPayments?': {
    'en': 'Discard payments?',
    'no': 'Forkaste betalingene?',
  },
  'dribble': {
    'en': 'Dribble',
    'no': 'Dribling',
  },
  'dynamicSellOnPercentage': {
    'en': 'Dynamic sell-on percentage',
    'no': 'Variabel prosent av videresalg',
  },

  'economy': {
    'en': 'Economy',
    'no': 'Økonomi',
  },
  'edit': {
    'en': 'Edit',
    'no': 'Endre',
  },
  'editAccess': {
    'en': 'Edit access',
    'no': 'Endre tilgang',
  },
  'editAccessFor': {
    'en': 'Edit access for',
    'no': 'Endre tilgangen til',
  },
  'editAlertInfo': {
    'en': 'Players who no longer meet the new criteria will be removed from the search',
    'no': 'Spillere som ikke lenger oppfyller de nye kriteriene bli fjernet fra søket',
  },
  'editClause': {
    'en': 'Edit clause',
    'no': 'Endre klausul',
  },
  'editCondition': {
    'en': 'Edit condition',
    'no': 'Endre betingelse',
  },
  'editConditionStatus': {
    'en': 'Edit condition status',
    'no': 'Endre betingelsesstatus',
  },
  'editContract': {
    'en': 'Edit contract',
    'no': 'Endre kontrakt',
  },
  'editedFormation': {
    'en': 'changed formation',
    'no': 'endret formasjon',
  },
  'editedFormationTo': {
    'en': 'changed formation to',
    'no': 'endret formasjon til',
  },
  'editedNameTo': {
    'en': 'edited the name to',
    'no': 'endret navnet til',
  },
  'editFormation': {
    'en': 'Change formation',
    'no': 'Endre formasjon',
  },
  'editFormationInfo': {
    'en': 'All teams will be updated with the new formation, and players may be moved to new positions',
    'no': 'Alle lag vil bli oppdatert med ny formasjon, og spillere kan bli flyttet til nye posisjoner',
  },
  'editingSearchInfo': {
    'en': 'The search was updated and is now being run again - this may take up to 30 seconds',
    'no': 'Søket ble oppdatert og kjøres på nytt nå  - dette kan ta opptil 30 sekunder',
  },
  'editMaxAmountForConditions': {
    'en': 'Edit max amount for conditions',
    'no': 'Endre maksbeløp for betingelser',
  },
  'editName': {
    'en': 'Edit name',
    'no': 'Endre navn',
  },
  'editPayment': {
    'en': 'Edit payment',
    'no': 'Endre betaling',
  },
  'editPayments': {
    'en': 'Edit payments',
    'no': 'Endre betalinger',
  },
  'editPercentage': {
    'en': 'Edit percentage',
    'no': 'Endre prosent',
  },
  'editRole': {
    'en': 'Edit role',
    'no': 'Endre rolle',
  },
  'editSearch': {
    'en': 'Edit search',
    'no': 'Endre søket',
  },
  'editSubClause': {
    'en': 'Edit sub-clause',
    'no': 'Endre underklausul',
  },
  'editSquadName': {
    'en': 'Edit squad name',
    'no': 'Endre navn på stallen',
  },
  'editSquadStatus': {
    'en': 'Edit squad status',
    'no': 'Endre stallstatus',
  },
  'editTeamName': {
    'en': 'Edit team name',
    'no': 'Endre navn på laget',
  },
  'editTeamStatus': {
    'en': 'Edit team status',
    'no': 'Endre lagsstatus',
  },
  'effort': {
    'en': 'Effort',
    'no': 'Innsats',
  },
  'email': {
    'en': 'Email',
    'no': 'E-post',
  },
  'emailSent': {
    'en': 'We have sent you an email',
    'no': 'Vi har sendt deg en e-post',
  },
  'existingClauses': {
    'en': 'Existing clauses',
    'no': 'Eksisterende klausuler',
  },
  'existingPlayersWithoutData': {
    'en': 'Existing players without data',
    'no': 'Eksisterende spillere uten data',
  },
  'existingReports': {
    'en': 'Existing reports',
    'no': 'Eksisterende rapporter',
  },
  'exitAttachment?': {
    'en': 'Exit attachment?',
    'no': 'Forlate vedlegget?',
  },
  'exitAttachmentInfo': {
    'en': 'The attachment will not be saved',
    'no': 'Vedlegget vil ikke bli lagret',
  },
  'exitClause?': {
    'en': 'Exit clause?',
    'no': 'Forlate klausulen?',
  },
  'exitComment?': {
    'en': 'Exit comment?',
    'no': 'Forlate kommentaren?',
  },
  'exitCommentInfo': {
    'en': 'The comment will not be saved',
    'no': 'Kommentaren vil ikke bli lagret',
  },
  'exitReport?': {
    'en': 'Exit report?',
    'no': 'Forlate rapporten?',
  },
  'exitReportInfo': {
    'en': 'The report will not be saved',
    'no': 'Rapporten vil ikke bli lagret',
  },
  'experienced': {
    'en': 'Experienced',
    'no': 'Erfaren',
  },

  'fileAlreadyUploaded': {
    'en': 'The file has already been uploaded',
    'no': 'Det er allerede lastet opp en fil med samme navn',
  },
  'for': {
    'en': 'for',
    'no': 'for',
  },
  'forgotPassword': {
    'en': 'Forgot password?',
    'no': 'Glemt passord?',
  },
  'formation': {
    'en': 'Formation',
    'no': 'Formasjon',
  },
  'freeAgent': {
    'en': 'Free agent',
    'no': 'Bosmanspiller',
  },
  'from': {
    'en': 'from',
    'no': 'fra',
  },
  '(fromAndIncludingRound3)': {
    'en': '(from round 3 and onwards)',
    'no': '(fra og med runde 3)',
  },
  'fromInclusive': {
    'en': 'from',
    'no': 'fra og med',
  },

  'genericMaintenanceInfo': {
    'en': 'The platform is currently undergoing maintenance',
    'no': 'Plattformen er for øyeblikket under vedlikehold',
  },
  'getStartedForFree': {
    'en': 'Get started for free',
    'no': 'Kom i gang gratis',
  },
  'goals': {
    'en': 'Goals',
    'no': 'Mål',
  },
  'goalsConceded': {
    'en': 'Goals conceded',
    'no': 'Mål sluppet inn',
  },
  'goalsScored': {
    'en': 'Goals scored',
    'no': 'Mål scoret',
  },
  ' goalsScored': {
    'en': ' goals scored',
    'no': ' mål scoret',
  },
  'goalDifference': {
    'en': 'Goal difference',
    'no': 'Målforskjell',
  },
  'Goalkeeper': {
    'en': 'Goalkeeper',
    'no': 'Keeper',
  },
  'gross': {
    'en': 'Gross',
    'no': 'Brutto',
  },
  '_gross': {
    'en': 'gross',
    'no': 'brutto',
  },
  '(groupStageAndKnockoutStage)': {
    'en': '(group stage & knockout stage)',
    'no': '(gruppespill & sluttspill)',
  },

  'height': {
    'en': 'Height',
    'no': 'Høyde',
  },
  'help': {
    'en': 'Help',
    'no': 'Hjelp',
  },
  'hideCups': {
    'en': 'Hide cups',
    'no': 'Skjul cuper',
  },
  'hideSeasonsWithoutData': {
    'en': 'Hide seasons without data',
    'no': 'Skjul sesonger uten data',
  },
  'hideUnavailablePlayers': {
    'en': 'Hide unavailable players',
    'no': 'Skjul utilgjengelige',
  },
  'hideUnavailablePlayersDescription': {
    'en': 'Hide injured and loaned out players',
    'no': 'Skjul skadede og utlånte spillere',
  },
  'home': {
    'en': 'Home',
    'no': 'Hjem',
  },
  'homegrown': {
    'en': 'Homegrown',
    'no': 'Egenutviklet',
  },

  'in': {
    'en': 'in',
    'no': 'i',
  },
  'include': {
    'en': 'Include',
    'no': 'Inkluder',
  },
  'includeSecondaryPosition': {
    'en': 'Include secondary position',
    'no': 'Inkluder sekundærposisjon',
  },
  'includeUnknownContractStatus': {
    'en': 'Include unknown status',
    'no': 'Inkluder ukjent status',
  },
  'incorrectTransfer?': {
    'en': 'Incorrect transfer?',
    'no': 'Feil overgang?',
  },
  'incorrectTransferInfo': {
    'en': 'If the transfer is confirmed to be incorrect, for instance due to a loan, the clause will remain active',
    'no': 'Dersom overgangen bekreftes feil, for eksempel grunnet et lån, vil klausulen forbli aktiv',
  },
  'incorrectValue?': {
    'en': 'Incorrect value?',
    'no': 'Feil verdi?',
  },
  'indexDiff': {
    'en': 'Index difference',
    'no': 'Indeks-differanse',
  },
  'influence': {
    'en': 'Influence',
    'no': 'Innflytelse',
  },
  'inputMustBeNumericAndPositive': {
    'en': 'Please provide a positive number',
    'no': 'Vennligst oppgi et positivt tall',
  },
  'intensity': {
    'en': 'Intensity',
    'no': 'Intensitet',
  },
  'international': {
    'en': 'International',
    'no': 'Internasjonal',
  },
  'internationalLocality': {
    'en': 'International',
    'no': 'Fri spiller',
  },
  'invalidAmount': {
    'en': 'The amount must be a positive number',
    'no': 'Beløpet må være et positivt tall',
  },
  'invalidAmounts': {
    'en': 'All amounts must be positive numbers',
    'no': 'Alle beløp må være positive tall',
  },
  'invalidDate': {
    'en': 'Invalid date provided',
    'no': 'Ugyldig dato oppgitt',
  },
  'invalidEmail': {
    'en': 'Invalid email',
    'no': 'Ugyldig e-post',
  },
  'invalidEmailProvided': {
    'en': 'The email provided is invalid',
    'no': 'E-posten som er oppgitt er ugyldig',
  },
  'invalidInput': {
    'en': 'Invalid email or password',
    'no': 'Ugyldig e-post eller passord',
  },
  'invalidMaxAmount': {
    'en': 'The max amount must be a positive number',
    'no': 'Maksbeløpet må være et positivt tall',
  },
  'invalidSellOnPercentage': {
    'en': 'Please provide a number between 0 and 100',
    'no': 'Vennligst oppgi et tall mellom 0 og 100',
  },
  'invalidTablePosition': {
    'en': 'The table position must be a positive number',
    'no': 'Tabellplasseringen må være et positivt tall',
  },
  'invalidThreshold': {
    'en': 'The threshold must be a positive number',
    'no': 'Antallet må være et positivt tall',
  },
  'invalidThresholdOrAmount': {
    'en': 'Both threshold and amount must be positive numbers',
    'no': 'Både antall og beløp må være positive tall',
  },
  'invalidThresholdOrPercentage': {
    'en': 'The threshold or percentage is invalid',
    'no': 'Antallet eller prosenten er ugyldig',
  },

  'Keeper': {
    'en': 'Goalkeeper',
    'no': 'Keeper',
  },

  'language': {
    'en': 'Language',
    'no': 'Språk',
  },
  'league': {
    'en': 'League',
    'no': 'Liga',
  },
  'leagues': {
    'en': 'Leagues',
    'no': 'Ligaer',
  },
  'leaguesAndCupsWithEventData': {
    'en': 'Leagues and cups with event data',
    'no': 'Ligaer og cuper med event data',
  },
  'leftA': {
    'en': 'left a',
    'no': 'la inn en',
  },
  'loadPlayersFromTeam': {
    'en': 'Load players from a team',
    'no': 'Last inn spillere fra et lag',
  },
  'loadSearch': {
    'en': 'Load search',
    'no': 'Last inn søk',
  },
  '_loanFrom': {
    'en': 'loan from',
    'no': 'lån fra',
  },
  'loanPlayers': {
    'en': 'loan players',
    'no': 'lånespillere',
  },
  'local': {
    'en': 'Local',
    'no': 'Lokal',
  },
  'localLocality': {
    'en': 'Local',
    'no': 'Klubbutviklet',
  },
  'locality': {
    'en': 'Locality',
    'no': 'Lokalitet',
  },
  'log': {
    'en': 'Log',
    'no': 'Logg',
  },
  'login': {
    'en': 'Sign in',
    'no': 'Logg inn',
  },
  'logisticTeams': {
    'en': 'Teams',
    'no': 'Logistikklag',
  },
  'logout': {
    'en': 'Sign out',
    'no': 'Logg ut',
  },

  'markedAsIncorrectTransfer': {
    'en': 'Marked as incorrect transfer',
    'no': 'Markert som feil overgang',
  },
  'marketValue': {
    'en': 'Market value',
    'no': 'Markedsverdi',
  },
  'matchDate': {
    'en': 'Match date',
    'no': 'Kampdato',
  },
  'matches': {
    'en': 'Matches',
    'no': 'Kamper',
  },
  'matchesDrawn': {
    'en': 'Draw',
    'no': 'Uavgjort',
  },
  'matchesDrawnShort': {
    'en': 'D',
    'no': 'U',
  },
  'matchesLost': {
    'en': 'Loss',
    'no': 'Tap',
  },
  'matchesLostShort': {
    'en': 'L',
    'no': 'T',
  },
  'matchesPlayed': {
    'en': 'Matches played',
    'no': 'Kamper spilt',
  },
  ' matchesPlayed': {
    'en': ' matches played',
    'no': ' kamper spilt',
  },
  'matchesPlayedShort': {
    'en': 'MP',
    'no': 'S',
  },
  'matchesStarted': {
    'en': 'Matches started',
    'no': 'Kamper startet',
  },
  ' matchesStarted': {
    'en': ' matches started',
    'no': ' kamper startet',
  },
  'matchesTitle': {
    'en': 'Matches played (matches started)',
    'no': 'Kamper spilt (kamper startet)',
  },
  'matchesWon': {
    'en': 'Won',
    'no': 'Vunnet',
  },
  'matchesWonShort': {
    'en': 'W',
    'no': 'V',
  },
  'MatchReport': {
    'en': 'Match report',
    'no': 'Kamprapport',
  },
  'matrix': {
    'en': 'Matrix',
    'no': 'Matrise',
  },
  'maxAge': {
    'en': 'Max age',
    'no': 'Maks alder',
  },
  'maxAmount': {
    'en': 'Max amount',
    'no': 'Maksbeløp',
  },
  'maxAmountReachedMessage': {
    'en': 'The total max amount for all conditions is reached',
    'no': 'Maksbeløpet for alle betingelsene er nådd',
  },
  'mentality': {
    'en': 'Mentality',
    'no': 'Mentalitet',
  },
  'Midfielder': {
    'en': 'Midfielder',
    'no': 'Midtbane',
  },
  'minAge': {
    'en': 'Min age',
    'no': 'Min alder',
  },
  'minClubIndex': {
    'en': 'Min club index',
    'no': 'Min. klubbindeks',
  },
  'minClubRating': {
    'en': 'Min club rating',
    'no': 'Min. klubbrating',
  },
  'minConfidence': {
    'en': 'Min precision',
    'no': 'Min. presisjon',
  },
  'minimumTablePosition': {
    'en': 'Minimum table position',
    'no': 'Minimum tabellplassering',
  },
  'minMinutesPlayed': {
    'en': 'Min minutes played',
    'no': 'Min. minutter spilt',
  },
  'minSkillIndex': {
    'en': 'Min skill index',
    'no': 'Min. skillindeks',
  },
  'minSkillRating': {
    'en': 'Min skill rating',
    'no': 'Min. skillrating',
  },
  'minutes': {
    'en': 'Minutes',
    'no': 'Minutter',
  },
  'minutesPercentage': {
    'en': 'Minutes (%)',
    'no': 'Minutter (%)',
  },
  'minutesPlayed': {
    'en': 'Minutes played',
    'no': 'Minutter spilt',
  },
  ' minutesPlayed': {
    'en': ' minutes played',
    'no': ' minutter spilt',
  },
  'minutesPlayedPercentage': {
    'en': 'Minutes played (%)',
    'no': 'Minutter spilt (%)',
  },
  'minuteRequirement?': {
    'en': 'Minute requirement?',
    'no': 'Minuttkrav per kamp?',
  },
  'missingData': {
    'en': 'Missing data',
    'no': 'Mangler data',
  },
  'month': {
    'en': 'Month',
    'no': 'Måned',
  },
  'monthsShort': {
    'en': 'months',
    'no': 'mnd',
  },
  'moreFilters': {
    'en': 'More filters',
    'no': 'Flere filtre',
  },
  'moved': {
    'en': 'moved',
    'no': 'flyttet',
  },
  'movedPlayerFrom': {
    'en': 'moved the player from',
    'no': 'flyttet spilleren fra',
  },
  'movePlayer': {
    'en': 'Move player',
    'no': 'Flytt spiller',
  },

  'name': {
    'en': 'Name',
    'no': 'Navn',
  },
  'national': {
    'en': 'National',
    'no': 'Nasjonal',
  },
  'nationality': {
    'en': 'Nationality',
    'no': 'Nasjonalitet',
  },
  'nationalities': {
    'en': 'Nationalities',
    'no': 'Nasjonaliteter',
  },
  'nationalLocality': {
    'en': 'National',
    'no': 'Forbundsutviklet',
  },
  'nationalTeam': {
    'en': 'National team',
    'no': 'Landslag',
  },
  'nationalTeamTitle': {
    'en': 'National team (since 2015)',
    'no': 'Landslag (siden 2015)',
  },
  'theNationalTeam': {
    'en': 'National team',
    'no': 'Landslaget',
  },
  '_theNationalTeam': {
    'en': 'national team',
    'no': 'landslaget',
  },
  'net': {
    'en': 'Net',
    'no': 'Netto',
  },
  '_net': {
    'en': 'net',
    'no': 'netto',
  },
  'netSellOn': {
    'en': 'Net sell-on',
    'no': 'Netto videresalg',
  },
  'new': {
    'en': 'New',
    'no': 'Ny',
  },
  'newClauseForBoughtPlayer': {
    'en': 'New clause for bought player',
    'no': 'Ny klausul for kjøpt spiller',
  },
  'newClauseForSoldPlayer': {
    'en': 'New clause for sold player',
    'no': 'Ny klausul for solgt spiller',
  },
  'newAttachment': {
    'en': 'New attachment',
    'no': 'Nytt vedlegg',
  },
  'newComment': {
    'en': 'New comment',
    'no': 'Ny kommentar',
  },
  'newMatchReport': {
    'en': 'New match report',
    'no': 'Ny kamprapport',
  },
  'newPayment': {
    'en': 'New payment',
    'no': 'Ny betaling',
  },
  'newPlayers': {
    'en': 'new players',
    'no': 'nye spillere',
  },
  'newScoutingReport': {
    'en': 'New scouting report',
    'no': 'Ny speiderapport',
  },
  'newTransferFee': {
    'en': 'New transfer fee',
    'no': 'Ny overgangssum',
  },
  'seasonCondition': {
    'en': 'Season condition',
    'no': 'Sesongbetingelse',
  },
  ' seasonCondition': {
    'en': ' season condition',
    'no': ' sesongbetingelse',
  },
  'seasonConditionInfo': {
    'en': 'A season condition is a condition where the specified criteria must be met in a given season, independent of other seasons.',
    'no': 'En sesongbetingelse er en betingelse hvor de oppgitte kriteriene må oppfylles i løpet av en sesong, uavhengig av andre sesonger.',
  },
  'noAccessInfo': {
    'en': 'You do not have access to the platform',
    'no': 'Du har ikke lenger tilgang til plattformen',
  },
  'noAmountProvided': {
    'en': 'You must provide an amount',
    'no': 'Du må oppgi et beløp',
  },
  'noArchivedTeams': {
    'en': 'No archived teams',
    'no': 'Ingen arkiverte lag',
  },
  'noAttachments': {
    'en': 'No attachments',
    'no': 'Ingen vedlegg',
  },
  'noChangesMade': {
    'en': 'You have not made any changes',
    'no': 'Du har ikke gjort noen endringer',
  },
  'noClubOrDateSelectedForCondition': {
    'en': 'You must select a club and a transfer date before you can add conditions',
    'no': 'Du må velge klubb og overgangsdato før du kan legge til betingelser',
  },
  'noClubSelectedForCondition': {
    'en': 'You must select a club before you can add conditions',
    'no': 'Du må velge klubb før du kan legge til betingelser',
  },
  'noCommentsProvided': {
    'en': 'No comments were provided',
    'no': 'Ingen kommentarer ble oppgitt',
  },
  'noData': {
    'en': 'No data',
    'no': 'Ingen data',
  },
  'noDateSelectedForCondition': {
    'en': 'You must select a transfer date before you can add conditions',
    'no': 'Du må velge overgangsdato før du kan legge til betingelser',
  },
  'noDayProvided': {
    'en': 'You need to provide a day',
    'no': 'Du må oppgi dag',
  },
  'noExistingClauses': {
    'en': 'No existing clauses',
    'no': 'Ingen eksisterende klausuler',
  },
  'noExistingReports': {
    'en': 'No existing reports',
    'no': 'Ingen eksisterende rapporter',
  },
  'noHistory': {
    'en': 'No history',
    'no': 'Ingen historikk',
  },
  'noMonthProvided': {
    'en': 'You need to provide a month',
    'no': 'Du må oppgi måned',
  },
  'noNationalCareer': {
    'en': 'No national team career (since 2015)',
    'no': 'Ingen landslagskamper (siden 2015)',
  },
  'noNewPlayersMatchingTheSearch': {
    'en': 'There are no new players matching the search',
    'no': 'Det er ingen nye spillere som matcher søket',
  },
  'noOptionsAvailable': {
    'en': 'No options available',
    'no': 'Ingen alternativer tilgjengelige',
  },
  'noPlayerSelected': {
    'en': 'No player selected',
    'no': 'Ingen spiller valgt',
  },
  'noPlayersRemovedFromSearch': {
    'en': 'No players have been removed from the search',
    'no': 'Ingen spillere har blitt fjerent fra søket',
  },
  'noRegretInfo': {
    'en': 'You cannot regret this action',
    'no': 'Du kan ikke angre på denne handlingen',
  },
  '(noTransferRegistered)': {
    'en': '(no transfer registered)',
    'no': '(ingen overgang registrert)',
  },
  'noYearProvided': {
    'en': 'You need to provide a year',
    'no': 'Du må oppgi år',
  },
  'noResult': {
    'en': 'No results',
    'no': 'Ingen treff',
  },
  'noSavedSearches': {
    'en': 'No saved searches',
    'no': 'Ingen lagrede søk',
  },
  'notAchieved': {
    'en': 'Not achieved',
    'no': 'Ikke oppnådd',
  },
  ' notAchieved': {
    'en': ' not achieved',
    'no': ' ikke oppnådd',
  },
  'notHomegrown': {
    'en': 'Not homegrown',
    'no': 'Ikke egenutviklet',
  },
  'numberOfForeigners': {
    'en': 'Number of foreigners',
    'no': 'Antall utlendinger',
  },
  'numberOfInternationalPlayers': {
    'en': 'Number of international players',
    'no': 'Antall frie spillere',
  },
  'numberOfLocalPlayers': {
    'en': 'Number of local players',
    'no': 'Antall klubbutviklede spillere',
  },
  'numberOfLocalAndNationalPlayers': {
    'en': 'Number of local and national players',
    'no': 'Antall lokalt utviklede spillere',
  },
  'numberOfMinutesPlayed': {
    'en': 'Number of minutes played',
    'no': 'Antall minutter spilt',
  },
  'numberOfNationalPlayers': {
    'en': 'Number of national players',
    'no': 'Antall forbundsutviklede spillere',
  },
  'numberOfPlayerLoanedIn': {
    'en': 'Number of players loaned in',
    'no': 'Antall spillere på innlån',
  },
  'numberOfPlayerLoanedOut': {
    'en': 'Number of players loaned out',
    'no': 'Antall spillere på utlån',
  },
  'numberOfReportsLastMonth': {
    'en': 'Number of reports last month',
    'no': 'Antall rapporter siste måned',
  },
  'numberOfReportsPerMonth': {
    'en': 'Number of reports per month',
    'no': 'Antall rapporter per måned',
  },
  'numberOfReportsThisYear': {
    'en': 'Number of reports this year',
    'no': 'Antall rapporter i år',
  },

  ' of ': {
    'en': ' of ',
    'no': ' av ',
  },
  'on': {
    'en': 'on',
    'no': 'på',
  },
  'ongoing': {
    'en': 'Ongoing',
    'no': 'Pågående',
  },
  'onLoan': {
    'en': 'On loan',
    'no': 'På lån'
  },
  'opponentTeam': {
    'en': 'Opponent team',
    'no': 'Motstanderlag',
  },
  '_or': {
    'en': 'or',
    'no': 'eller',
  },
  'orderBy': {
    'en': 'Order by',
    'no': 'Sorter etter',
  },
  'orderByActualValues': {
    'en': 'Sort by actual values',
    'no': 'Sorter etter faktiske verdier',
  },
  'orderByActualValuesTitle': {
    'en': 'Order by actual values (number per 90 or percentage)',
    'no': 'Sorter etter faktiske verdier (antall per 90 eller prosent)',
  },
  'other': {
    'en': 'Other',
    'no': 'Annet',
  },
  'overallRating': {
    'en': 'Overall rating',
    'no': 'Overordnet vurdering',
  },
  'overrideValue': {
    'en': 'Override value',
    'no': 'Overskriv verdi',
  },
  'overrideValueInfo': {
    'en': 'If the value is incorrect, you may override the value. In this case, future values will be based on the new value and only count onwards from the provided date.',
    'no': 'Dersom verdien er feil, kan du overskrive verdien. Da vil fremtidige verdier ta utgangspunkt i den nye verdien og kun telle videre fra den oppgitte datoen.',
  },
  'overview': {
    'en': 'Overview',
    'no': 'Oversikt',
  },
  'ownPlayer': {
    'en': 'Own player',
    'no': 'Egen spiller',
  },

  'pace': {
    'en': 'Pace',
    'no': 'Hurtighet',
  },
  'pass': {
    'en': 'Pass',
    'no': 'Pasning',
  },
  'password': {
    'en': 'Password',
    'no': 'Passord',
  },
  'passwordMustContainAtLeastSixCharacters': {
    'en': 'Password must contain at least six characters',
    'no': 'Passordet må inneholde minst seks tegn',
  },
  'paymentDate': {
    'en': 'Payment date',
    'no': 'Betalingsdato',
  },
  'payments': {
    'en': 'Payments',
    'no': 'Betalinger',
  },
  'paymentsWillNotBeSaved': {
    'en': 'The payments will not be saved',
    'no': 'Betalingene vil ikke bli lagret',
  },
  'peak': {
    'en': 'Peak',
    'no': 'På topp',
  },
  'percentage': {
    'en': 'Percentage',
    'no': 'Prosent',
  },
  'percentageMinutesPlayed': {
    'en': 'Percentage of minutes played',
    'no': 'Prosentandel minutter spilt',
  },
  'percentageWithDateAlreadyExists': {
    'en': 'A dynamic percentage with the same date already exists',
    'no': 'Det eksisterer allerede en variabel prosent med samme dato',
  },
  'physique': {
    'en': 'Physique',
    'no': 'Fysikk',
  },
  'planner': {
    'en': 'Planner',
    'no': 'Planlegger',
  },
  ' placeInTheLeague': {
    'en': ' place in the league',
    'no': ' plass i ligaen',
  },
  ' player': {
    'en': 'player',
    'no': 'spiller',
  },
  'player': {
    'en': 'Player',
    'no': 'Spiller',
  },
  'playerIsDeleted': {
    'en': 'The player was added manually and has since been deleted from the platform',
    'no': 'Spilleren ble lagt til manuelt og har siden blitt slettet fra plattformen',
  },
  'Players': {
    'en': 'Players',
    'no': 'Spillere',
  },
  'players': {
    'en': 'players',
    'no': 'spillere',
  },
  'playersMatchTheSearch': {
    'en': 'players match the search',
    'no': 'spillere matcher søket',
  },
  'playerHistory': {
    'en': 'Player history',
    'no': 'Spillerlogg',
  },
  'playerSearch': {
    'en': 'Player search',
    'no': 'Spillersøk',
  },
  'playerSquad': {
    'en': 'Squad',
    'no': 'Spillerstall',
  },
  'playerWasAddedWithoutData': {
    'en': 'The player was added manually and is not connected to a player in the database.',
    'no': 'Spilleren ble lagt til manuelt og er ikke koblet opp mot en spiller i databasen.',
  },
  'playerWasAddedWithoutDataDescription': {
    'en': 'You may connect the player to an existing player in the database if you find the player below.',
    'no': 'Du kan koble spilleren opp mot en eksisterende spiller i databasen dersom du finner spilleren nedenfor.',
  },
  'points': {
    'en': 'Points',
    'no': 'Poeng',
  },
  'pointsShort': {
    'en': 'P',
    'no': 'P',
  },
  'position': {
    'en': 'Position',
    'no': 'Posisjon',
  },
  'positioning': {
    'en': 'Positioning',
    'no': 'Posisjonering',
  },
  'positions': {
    'en': 'Positions',
    'no': 'Posisjoner',
  },
  'positionsWithData': {
    'en': 'Position data are based on',
    'no': 'Posisjon dataen baseres på',
  },
  'possiblePrecision': {
    'en': 'Possible precision',
    'no': 'Mulig presisjon',
  },
  'postpone': {
    'en': 'Postpone',
    'no': 'Utsett',
  },
  'precision': {
    'en': 'Precision',
    'no': 'Presisjon',
  },
  'previousOverridenValue': {
    'en': 'Previous overriden value',
    'no': 'Forrige overskrevne verdi',
  },
  'provideComment': {
    'en': 'Write a comment',
    'no': 'Skriv inn en kommentar',
  },
  'provideCommentOrInformation': {
    'en': 'You may provide a comment or other information about the player here',
    'no': 'Her kan du skrive inn en kommentar eller annen informasjon om spilleren',
  },
  'provideComments': {
    'en': 'You may provide comments here',
    'no': 'Her kan du skrive inn kommentarer',
  },
  'provideConditionStatus': {
    'en': 'Provide condition status',
    'no': 'Oppgi betingelsesstatus',
  },
  'provideCustomCondition': {
    'en': 'Provide custom condition',
    'no': 'Oppgi annen betingelse',
  },
  'provideDate': {
    'en': 'Provide date',
    'no': 'Oppgi dato',
  },
  'provideEmail': {
    'en': 'Provide email',
    'no': 'Skriv inn e-post',
  },
  'provideMaxAmount': {
    'en': 'Provide max amount',
    'no': 'Oppgi maksbeløp',
  },
  'provideMaxAmountForConditions': {
    'en': 'Provide max amount for conditions',
    'no': 'Oppgi maksbeløp for betingelser',
  },
  'provideMaxAmountForConditionsInfo': {
    'en': 'You may provide a maximum amount for all conditions (except sell-on clause)',
    'no': 'Her kan du oppgi et maksbeløp for alle betingelser (unntatt videresalg)',
  },
  'provideName': {
    'en': 'Provide name',
    'no': 'Skriv inn navn',
  },
  'provideNameOfSearch': {
    'en': 'Provide name of search',
    'no': 'Skriv inn navn på søket',
  },
  'provideNewContractExpiration': {
    'en': 'Provide new contract expiration date',
    'no': 'Oppgi ny utløpsdato for kontrakt',
  },
  'providePassword': {
    'en': 'Provide password',
    'no': 'Skriv inn passord',
  },
  'providePlayerName': {
    'en': 'Provide the player name',
    'no': 'Oppgi navn på spilleren',
  },
  'provideSquadName': {
    'en': 'Provide the squad name',
    'no': 'Oppgi navn på stallen',
  },
  'provideTeam': {
    'en': 'Provide team',
    'no': 'Oppgi lag',
  },
  'provideTeamName': {
    'en': 'Provide the team name',
    'no': 'Oppgi navn på laget',
  },
  'provideNewValue': {
    'en': 'Provide new value',
    'no': 'Oppgi ny verdi',
  },

  'provideNewPlayerInfo_birth_date': {
    'en': 'Provide birth date for',
    'no': 'Oppgi fødselsdato for',
  },
  'provideNewPlayerInfo_contract_expiration': {
    'en': 'Provide contract expiration for',
    'no': 'Oppgi utløpsdato på kontrakten til',
  },
  'provideNewPlayerInfo_country_code': {
    'en': 'Provide nationality for',
    'no': 'Oppgi nasjonalitet for',
  },
  'provideNewPlayerInfo_market_value': {
    'en': 'Provide market value for',
    'no': 'Oppgi markedsverdi for',
  },
  'provideNewPlayerInfo_clubValue': {
    'en': 'Provide internal market value',
    'no': 'Oppgi klubbens verdi for',
  },
  'provideNewPlayerInfo_signingCost': {
    'en': 'Provide signing cost for',
    'no': 'Oppgi signeringskostnaden for',
  },
  'provideNewPlayerInfo_salary': {
    'en': 'Provide salary cost for',
    'no': 'Oppgi lønnskostnaden for',
  },
  'provideNewPlayerInfo_bonuses': {
    'en': 'Provide expected total bonus for',
    'no': 'Oppgi forventet total bonus for',
  },
  'provideNewPlayerInfo_locality': {
    'en': 'Provide locality for',
    'no': 'Oppgi lokaliteten til',
  },
  'provideNewPlayerInfo_ABC': {
    'en': 'Provide classification for',
    'no': 'Oppgi klassifisering for',
  },
  'provideNewPlayerInfo_role': {
    'en': 'Provide new role for',
    'no': 'Endre rollen til',
  },

  'ratingRequirements': {
    'en': 'Minimum rating requirements',
    'no': 'Minimum ferdighetskrav',
  },
  'reactivateClause': {
    'en': 'Reactivate clause',
    'no': 'Reaktiver klausul',
  },
  'reactivateCondition': {
    'en': 'Reactivate condition',
    'no': 'Reaktiver betingelse',
  },
  'reactivateConditionInfo': {
    'en': 'The condition will be reactivated and the associated payment will be deleted',
    'no': 'Betingelsen vil bli reaktivert og den tilhørende betalingen vil bli slettet',
  },
  'reactivateOrDiscardCondition': {
    'en': 'Reactivate or discard condition',
    'no': 'Reaktiver eller forkast betingelse',
  },
  'refresh': {
    'en': 'Refresh',
    'no': 'Oppdater',
  },
  'registeredTransfersMarkedAsIncorrect': {
    'en': 'Registered transfers marked as incorrect',
    'no': 'Registrerte overganger som er markert som feil',
  },
  'regretIncorrectTransfer?': {
    'en': 'Correct transfer?',
    'no': 'Korrekt overgang?',
  },
  'regretIncorrectTransferInfo': {
    'en': 'The transfer has previously been marked as incorrect. Do you want to mark the transfer as correct?',
    'no': 'Overgangen har tidligere blitt markert som feil. Ønsker du å markere overgangen som korrekt?',
  },
  'removeClub': {
    'en': 'Remove club',
    'no': 'Fjern klubb',
  },
  'removeClubInfo': {
    'en': 'If you remove the club, the selected competitions will automatically be updated for existing conditions, and you risk that the new competitions are not correct.',
    'no': 'Dersom du fjerner klubben vil de valgte konkurransene automatisk oppdateres for eksisterende betingelser, og du riskerer at de nye konkurransene ikke er korrekte.',
  },
  'removed': {
    'en': 'removed',
    'no': 'fjernet',
  },
  'removedFromSearch': {
    'en': 'Removed from search',
    'no': 'Fjernet fra søk',
  },
  'removeFromSearch': {
    'en': 'Remove from search',
    'no': 'Fjern fra søk',
  },
  'removePlayer': {
    'en': 'Remove player',
    'no': 'Fjern spiller',
  },
  'removedPlayerFrom': {
    'en': 'removed the player from',
    'no': 'fjernet spilleren fra',
  },
  'removePlayerFromSquad': {
    'en': 'Remove from squad',
    'no': 'Fjern spiller fra stall',
  },
  'report': {
    'en': 'report',
    'no': 'rapport',
  },
  'reports': {
    'en': 'Reports',
    'no': 'Rapporter',
  },
  'reserveAndLoanPlayers': {
    'en': 'Reserve and loan players',
    'no': 'Reserver og lånespillere',
  },
  'resetExcludedPlayers': {
    'en': 'Reset players removed from this search',
    'no': 'Nullstill spillere fjernet fra dette søket',
  },
  'resetFilters': {
    'en': 'Reset filters',
    'no': 'Nullstill filtre',
  },
  'resetOverriddenValue': {
    'en': 'Reset overridden value',
    'no': 'Nullstill overskrevet verdi',
  },
  'resetPassword': {
    'en': 'Reset password',
    'no': 'Tilbakestill passord',
  },
  'resetSquad': {
    'en': 'Reset squad',
    'no': 'Nullstill stall',
  },
  'resetSquadInfo': {
    'en': 'All players will be removed from the squad',
    'no': 'Alle spillerne vil bli fjernet fra stallen',
  },
  'resetTeam': {
    'en': 'Reset team',
    'no': 'Nullstill lag',
  },
  'resetTeamInfo': {
    'en': 'All players will be removed from the team',
    'no': 'Alle spillerne vil bli fjernet fra laget',
  },
  'resolveClause': {
    'en': 'Resolve clause',
    'no': 'Fullfør klausul',
  },
  'resolveCondition': {
    'en': 'Resolve condition',
    'no': 'Løs ut betingelse',
  },
  'resolveOrDiscardCondition': {
    'en': 'Resolve or discard condition',
    'no': 'Løs ut eller forkast betingelse',
  },
  'resolveOrReactivateCondition': {
    'en': 'Resolve or reactivate condition',
    'no': 'Løs ut eller reaktiver betingelse',
  },
  'restore': {
    'en': 'Restore',
    'no': 'Gjenopprett',
  },
  'restoreClause': {
    'en': 'Restore clause',
    'no': 'Gjenopprett klausul',
  },
  'role': {
    'en': 'Role',
    'no': 'Rolle',
  },

  'salary': {
    'en': 'Salary',
    'no': 'Lønn',
  },
  'salaryCost': {
    'en': 'Salary',
    'no': 'Lønnskostnad',
  },
  'salaryPlusBonus': {
    'en': 'Salary + bonuses',
    'no': 'Lønn + bonuser',
  },
  'saveSearch': {
    'en': 'Save search',
    'no': 'Lagre søk',
  },
  'scouting': {
    'en': 'Scouting',
    'no': 'Scouting',
  },
  'ScoutingReport': {
    'en': 'Scouting report',
    'no': 'Speiderapport',
  },
  'scoutTeams': {
    'en': 'Scout teams',
    'no': 'Speiderlag',
  },
  'scrollAttackers': {
    'en': 'Scroll forwards',
    'no': 'Scroll angripere',
  },
  'scrollDefenders': {
    'en': 'Scroll defenders',
    'no': 'Scroll forsvarere',
  },
  'scrollGoalkeepers': {
    'en': 'Scroll goalkeepers',
    'no': 'Scroll keepere',
  },
  'scrollMidfielders': {
    'en': 'Scroll midfielders',
    'no': 'Scroll midtbanen',
  },
  'scrollPlayers': {
    'en': 'Scroll players',
    'no': 'Scroll spillere',
  },
  'search': {
    'en': 'Search',
    'no': 'Søk',
  },
  'searchAgain?': {
    'en': 'Do you want to search again?',
    'no': 'Vil du søke på nytt?',
  },
  'searchForClub': {
    'en': 'Search for club',
    'no': 'Søk etter klubb',
  },
  'searchForCountry': {
    'en': 'Search for country',
    'no': 'Søk etter land',
  },
  'searchForIteration': {
    'en': 'Search for season',
    'no': 'Søk etter sesong',
  },
  'searchForLeague': {
    'en': 'Search for league',
    'no': 'Søk etter liga',
  },
  'searchForLeagueOrCup': {
    'en': 'Search for league or cup',
    'no': 'Søk etter liga eller cup',
  },
  'searchForName': {
    'en': 'Search for name',
    'no': 'Søk etter navn',
  },
  'searchForPlayers': {
    'en': 'Search for players',
    'no': 'Søk etter spillere',
  },
  'searchForPlayer?': {
    'en': 'Search for player?',
    'no': 'Søk etter spiller?',
  },
  'searchForSeasons': {
    'en': 'Search for seasons',
    'no': 'Søk etter sesonger',
  },
  'searchNameAlreadyExists': {
    'en': 'A search with the same name already exists',
    'no': 'Det eksisterer allerede et søk med samme navn',
  },
  'season': {
    'en': 'Season',
    'no': 'Sesong',
  },
  'seasons': {
    'en': 'Seasons',
    'no': 'Sesonger',
  },
  'seasonSearch': {
    'en': 'Season search',
    'no': 'Sesongsøk',
  },
  'select': {
    'en': 'Select',
    'no': 'Velg',
  },
  'selectAll': {
    'en': 'Select all',
    'no': 'Velg alle',
  },
  'selectClub': {
    'en': 'Select club',
    'no': 'Velg klubb',
  },
  'selectCompetitions': {
    'en': 'Select competitions',
    'no': 'Velg konkurranser',
  },
  'selectCondition': {
    'en': 'Select condition',
    'no': 'Velg betingelse',
  },
  'selectNumberOfSeasonConditionAmounts': {
    'en': 'Select the number of unique amounts in the season condition',
    'no': 'Velg antall ulike beløp som inngår i sesongbetingelsen',
  },
  'selectSeasons': {
    'en': 'Select seasons',
    'no': 'Velg sesonger',
  },
  'selectedClub': {
    'en': 'Selected club',
    'no': 'Valgt klubb',
  },
  'selectedClubs': {
    'en': 'Selected clubs',
    'no': 'Valgte klubber',
  },
  'selectedCountries': {
    'en': 'Selected countries',
    'no': 'Valgte land',
  },
  'selectedCountry': {
    'en': 'Selected country',
    'no': 'Valgt land',
  },
  'selectedIterations': {
    'en': 'Selected seasons',
    'no': 'Valgte sesonger',
  },
  'selectedLeagues': {
    'en': 'Selected ligaer',
    'no': 'Valgte ligaer',
  },
  'selectedPlayer': {
    'en': 'Selected player',
    'no': 'Valgt spiller',
  },
  'selectFormation': {
    'en': 'Select formation',
    'no': 'Velg formasjon',
  },
  'selectNewPosition': {
    'en': 'Select new position',
    'no': 'Velg ny posisjon',
  },
  'selectNewRoleOrTeam': {
    'en': 'Select new role or team',
    'no': 'Velg ny rolle eller nytt lag',
  },
  'selectNewTeam': {
    'en': 'Select new team',
    'no': 'Velg nytt lag',
  },
  'selectPlayer': {
    'en': 'Select player',
    'no': 'Velg spiller',
  },
  'selectPlayerForComparison': {
    'en': 'Select player to compare with',
    'no': 'Velg spiller å sammenligne med',
  },
  'selectPosition': {
    'en': 'Select position',
    'no': 'Velg posisjon',
  },
  'selectRoleOrTeam': {
    'en': 'Select role or team',
    'no': 'Velg rolle eller lag',
  },
  'selectSeason': {
    'en': 'Select season',
    'no': 'Velg sesong',
  },
  'selectTeam': {
    'en': 'Select team',
    'no': 'Velg lag',
  },
  'sellOn': {
    'en': 'Sell-on',
    'no': 'Videresalg',
  },
  'sellOnCondition': {
    'en': 'Sell-on clause',
    'no': 'Videresalgsklausul',
  },
  ' sellOnCondition': {
    'en': ' sell-on clause',
    'no': ' videresalgsklausul',
  },
  'sellOnConditionAlreadyExists': {
    'en': 'A sell-on clause already exists',
    'no': 'En videresalgsklausul er allerede lagt til',
  },
  'sellOnPercentage': {
    'en': 'Sell-on percentage',
    'no': 'Prosent av videresalg',
  },
  'settings': {
    'en': 'Settings',
    'no': 'Innstillinger',
  },
  'shot': {
    'en': 'Shot',
    'no': 'Skudd',
  },
  'showAcademyPlayers': {
    'en': 'Show academy players',
    'no': 'Vis akademispillere',
  },
  'showActualValues': {
    'en': 'Show actual values (number per 90 or percentage)',
    'no': 'Vis faktiske verdier (antall per 90 eller prosent)',
  },
  'showColors': {
    'en': 'Show colors',
    'no': 'Vis farger',
  },
  'showDeletedArchivedTeams': {
    'en': 'Show deleted archived teams',
    'no': 'Vis slettede arkiverte lag',
  },
  'showDeletedClauses': {
    'en': 'Show deleted clauses',
    'no': 'Vis slettede klausuler',
  },
  'showDeletedReports': {
    'en': 'Show deleted reports',
    'no': 'Vis slettede rapporter',
  },
  'showNormalizedValues': {
    'en': 'Show normalized values',
    'no': 'Vis normaliserte verdier',
  },
  'showNumberOfMinutes': {
    'en': 'Show absolute minutes',
    'no': 'Vis antall minutter',
  },
  'showOnlyDomesticLeagues': {
    'en': 'Show only domestic leagues',
    'no': 'Vis kun hjemlige ligaer',
  },
  'showOnlyScoutReports': {
    'en': 'Show only scout reports',
    'no': 'Vis kun speiderapporter',
  },
  'showOnlySeasonsWith180Minutes': {
    'en': 'Show only seasons with at least 180 minutes',
    'no': 'Vis kun sesonger med minst 180 minutter',
  },
  'showPercentageMinutesPlayed': {
    'en': 'Show percentage of minutes played',
    'no': 'Vis prosentandel minutter spilt',
  },
  'showPlayerInfo': {
    'en': 'Show player info',
    'no': 'Vis spillerinformasjon',
  },
  'showPlayersRemovedFromSearch': {
    'en': 'Show players removed from the search',
    'no': 'Vis spillere som er fjernet fra søket',
  },
  'showResolvedClauses': {
    'en': 'Show resolved clauses',
    'no': 'Vis fullførte klausuler',
  },
  'shortNames': {
    'en': 'Short names',
    'no': 'Korte navn',
  },
  'showStartingEleven': {
    'en': 'Show starting eleven',
    'no': 'Vis startellever',
  },
  'signingCost': {
    'en': 'Signing cost',
    'no': 'Signering',
  },
  'signingCostLong': {
    'en': 'Signing cost',
    'no': 'Signeringskostnad',
  },
  'skill_rating': {
    'en': 'Skill rating',
    'no': 'Skillrating',
  },
  'skill_index': {
    'en': 'Skill index',
    'no': 'Skillindeks',
  },
  'skillRating': {
    'en': 'Skill rating',
    'no': 'Skillrating',
  },
  'skillIndex': {
    'en': 'Skill index',
    'no': 'Skillindeks',
  },
  'skills': {
    'en': 'Skills',
    'no': 'Ferdigheter',
  },
  'soldFrom': {
    'en': 'Sold from',
    'no': 'Solgt fra',
  },
  'soldPlayers': {
    'en': 'Sold players',
    'no': 'Solgte spillere',
  },
  'soldTo': {
    'en': 'Sold to',
    'no': 'Solgt til',
  },
  'ourSolution': {
    'en': 'Our solution',
    'no': 'Vår løsning',
  },
  'someCompetitions': {
    'en': 'Selected competitions',
    'no': 'Utvalgte konkurranser',
  },
  '_someCompetitions': {
    'en': 'selected competitions',
    'no': 'utvalgte konkurranser',
  },
  'someSeasons': {
    'en': 'Selected seasons',
    'no': 'Utvalgte sesonger',
  },
  '_someSeasons': {
    'en': 'selected seasons',
    'no': 'utvalgte sesonger',
  },
  'somethingWentWrong': {
    'en': 'Something went wrong, please try again or contact us',
    'no': 'Noe gikk galt, vennligst prøv igjen eller kontakt oss',
  },
  'squadActions': {
    'en': 'Squad actions',
    'no': 'Stallhandlinger',
  },
  'squadBuilder': {
    'en': 'Squad builder',
    'no': 'Stallbygger',
  },
  'squadStatus': {
    'en': 'Squad status',
    'no': 'Stallstatus',
  },
  'stamina': {
    'en': 'Stamina',
    'no': 'Utholdenhet',
  },
  '_standard': {
    'en': 'standard',
    'no': 'standard',
  },
  'standardSeasonCondition': {
    'en': 'Standard season condition',
    'no': 'Standard sesongbetingelse',
  },
  'standardSeasonConditionInfo': {
    'en': 'A standard season condition includes one amount, and is fulfilled for a given season if all the specified criteria are met during the season.\nExample:\nYou will receive 100.000,- if the player plays at least 20 matches during any season.',
    'no': 'En standard sesongbetingelse omfatter ett beløp, og oppfylles for en gitt sesong dersom alle oppgitte kriterier oppfylles i løpet av sesongen.\nEksempel:\nDere skal motta 100.000,- dersom spilleren spiller minst 20 kamper i løpet av en hvilken som helst sesong.',
  },
  'startingPercentage': {
    'en': 'Starting %',
    'no': 'Startprosent',
  },
  'starts': {
    'en': 'Starts',
    'no': 'Starter',
  },
  'staticSellOnPercentage': {
    'en': 'Static sell-on percentage',
    'no': 'Fast prosent av videresalg',
  },
  'strength': {
    'en': 'Strength',
    'no': 'Styrke',
  },
  'subConditions': {
    'en': 'Sub conditions',
    'no': 'Delbetingelser',
  },
  'submitAttachment': {
    'en': 'Submit attachment',
    'no': 'Send inn vedlegg',
  },
  'submitClause': {
    'en': 'Submit clause',
    'no': 'Send inn klausul',
  },
  'submitComment': {
    'en': 'Submit comment',
    'no': 'Send inn kommentar',
  },
  'submitMatchReport': {
    'en': 'Submit match report',
    'no': 'Send inn kamprapport',
  },
  'submitScoutingReport': {
    'en': 'Submit scouting report',
    'no': 'Send inn speiderapport',
  },
  'substitutes': {
    'en': 'Substitutes',
    'no': 'Innbyttere',
  },
  'suggestedStatusInfo': {
    'en': 'Based on the data, this condition is',
    'no': 'Ifølge dataen er denne betingelsen',
  },
  'sum': {
    'en': 'Sum',
    'no': 'Sum',
  },
  'summary': {
    'en': 'Summary',
    'no': 'Oppsummering',
  },

  'table': {
    'en': 'Table',
    'no': 'Tabell',
  },
  'tablePosition': {
    'en': 'Table position',
    'no': 'Tabellplassering',
  },
  'team': {
    'en': 'Team',
    'no': 'Lag',
  },
  'teamActions': {
    'en': 'Team actions',
    'no': 'Laghandlinger',
  },
  'teamMenu': {
    'en': 'Team menu',
    'no': 'Lagmeny',
  },
  'teamOfPlayer': {
    'en': 'Team of player',
    'no': 'Spillerens lag',
  },
  'teamStatus': {
    'en': 'Team status',
    'no': 'Lagstatus',
  },
  'technique': {
    'en': 'Technique',
    'no': 'Teknikk',
  },
  'theClub': {
    'en': 'the club',
    'no': 'klubben',
  },
  'thisSearch': {
    'en': 'This search',
    'no': 'Dette søket',
  },
  'threshold': {
    'en': 'Threshold',
    'no': 'Antall',
  },
  'thresholdAlreadyExists': {
    'en': 'An entry with this threshold already exists',
    'no': 'Det finnes allerede en betingelse med samme antall',
  },
  'tier': {
    'en': 'Tier',
    'no': 'Nivå',
  },
  'to': {
    'en': 'to',
    'no': 'til',
  },
  'total': {
    'en': 'Total',
    'no': 'Total',
  },
  'totalCondition': {
    'en': 'Total condition',
    'no': 'Total betingelse',
  },
  ' totalCondition': {
    'en': ' total condition',
    'no': ' total betingelse',
  },
  'totalConditionInfo': {
    'en': 'A total condition is a condition that counts the total number of times a given criterion occurs, and it applies for the entire period of the player at the new club.',
    'no': 'En total betingelse er en betingelse som teller opp antall ganger et oppgitt kriterium inntreffer, og gjelder for hele oppholdet til spilleren i den nye klubben.',
  },
  'totalCost': {
    'en': 'Total cost',
    'no': 'Total kostnad',
  },
  'totalMarketValue': {
    'en': 'Total market value',
    'no': 'Total markedsverdi',
  },
  'totalNumberOfReports': {
    'en': 'Total number of reports',
    'no': 'Totalt antall rapporter',
  },
  'totalTransferFee': {
    'en': 'Total transfer fee',
    'no': 'Total overgangssum',
  },
  'transferDate': {
    'en': 'Transfer date',
    'no': 'Overgangsdato',
  },
  'transferFee': {
    'en': 'Transfer fee',
    'no': 'Overgangssum',
  },
  'transferRegisteredInfo': {
    'en': 'We have registered a transfer for the player and the conditions will no longer be updated',
    'no': 'Vi har registrert en overgang for spilleren og betingelsene vil ikke lenger oppdateres',
  },

  'u21MinutesPerMinute': {
    'en': 'Percentage of minutes U21',
    'no': 'Prosentandel minutter U21',
  },
  'u21MinutesPerMinuteTitle': {
    'en': 'Percentage of minutes by U21',
    'no': 'Prosentandel minutter spilt av U21',
  },
  'unavailableOnMobile': {
    'en': 'This page is not yet available on mobile',
    'no': 'Denne siden er ikke tilgjengelig på mobil enda',
  },
  'unconditioned': {
    'en': 'Unconditioned',
    'no': 'Ubetinget',
  },
  'under21': {
    'en': 'Under 21',
    'no': 'Under 21',
  },
  'under6months': {
    'en': 'Under 6 months',
    'no': 'Under 6 måneder',
  },
  'under12months': {
    'en': 'Under 12 months',
    'no': 'Under 12 måneder',
  },
  'under18months': {
    'en': 'Under 18 months',
    'no': 'Under 18 måneder',
  },
  'under2years': {
    'en': 'Under 2 years',
    'no': 'Under 2 år',
  },
  'under3years': {
    'en': 'Under 3 years',
    'no': 'Under 3 år',
  },
  'under4years': {
    'en': 'Under 4 years',
    'no': 'Under 4 år',
  },
  'underDevelopment': {
    'en': 'In development',
    'no': 'Under utvikling',
  },
  'understanding': {
    'en': 'Understanding',
    'no': 'Spilleforståelse',
  },
  'undo': {
    'en': 'Undo',
    'no': 'Angre',
  },
  'unknownUser': {
    'en': 'A previous user',
    'no': 'En tidligere bruker',
  },
  'updateAvailable': {
    'en': 'A new update is available',
    'no': 'En ny oppdatering er tilgjengelig',
  },
  'updateAvailableInfo': {
    'en': 'Please refresh the page',
    'no': 'Vennligst last inn siden på nytt for å oppdatere',
  },
  'uploaded': {
    'en': 'uploaded',
    'no': 'lastet opp',
  },
  'uploadPdf': {
    'en': 'Upload PDF',
    'no': 'Last opp PDF',
  },

  'valueInOurDatabase': {
    'en': 'Value in our database',
    'no': 'Verdi i vår database',
  },

  'watchlist': {
    'en': 'Watchlist',
    'no': 'Watchlist',
  },
  'weight': {
    'en': 'Weight',
    'no': 'Vekt',
  },
  'withoutData': {
    'en': 'without data',
    'no': 'uten data',
  },

  'xG': {
    'en': 'xG created',
    'no': 'xG skapt',
  },
  'xGC': {
    'en': 'xG conceded',
    'no': 'xG sluppet til',
  },
  'xGData': {
    'en': 'xG data',
    'no': 'xG data',
  },
  'xGDataTitle': {
    'en': 'xG data per 90 for ',
    'no': 'xG data per 90 for ',
  },
  'xGDifference': {
    'en': 'xG difference',
    'no': 'xG differanse',
  },
  'xGPer90': {
    'en': 'xG created per 90',
    'no': 'xG skapt per 90',
  },
  'xGCPer90': {
    'en': 'xG conceded per 90',
    'no': 'xG sluppet til per 90',
  },
  'xGDifferencePer90': {
    'en': 'xG difference per 90',
    'no': 'xG differanse per 90',
  },

  'year': {
    'en': 'Year',
    'no': 'År',
  },
  '_year': {
    'en': 'year',
    'no': 'år',
  },
  'years': {
    'en': 'years',
    'no': 'år',
  },
  'you': {
    'en': 'You',
    'no': 'Du',
  },
  'youMustProvideCustomCondition': {
    'en': 'You must provide a custom condition',
    'no': 'Du må oppgi en betingelse',
  },
  'youMustProvideEitherStaticOrDynamicSellOnPercentage': {
    'en': 'You must provide either a static or dynamic sell-on percentage',
    'no': 'Du må oppgi enten fast eller variabel prosent av videresalg',
  },
  'youMustProvideSubConditions': {
    'en': 'You must provide at least one condition',
    'no': 'Du må oppgi minst én betingelse',
  },
  'youMustProvideSubConditionsForEachAmount': {
    'en': 'Each amount must have at least one associated condition',
    'no': 'Hvert beløp må ha minst én tilhørende betingelse',
  },
  'youMustProvideTablePosition': {
    'en': 'You must provide a minimum table position',
    'no': 'Du må oppgi en minimum tabellplassering',
  },
  'youMustProvideThreshold': {
    'en': 'You must provide a threshold',
    'no': 'Du må oppgi et antall',
  },
  'youMustProvideThresholdAndAmount': {
    'en': 'You must provide both threshold and amount',
    'no': 'Du må oppgi både antall og beløp',
  },
  'youMustProvideThresholdOrPercentage': {
    'en': 'You must provide an amount or percentage',
    'no': 'Du må oppgi et antall eller en prosent',
  },
  'youMustSelectAtLeastOneFilter': {
    'en': 'You must select at least one filter',
    'no': 'Du må velge minst ett filter',
  },
  'youMustSelectCondition': {
    'en': 'You must select a condition',
    'no': 'Du må velge en betingelse',
  },
  'yourLeaguesAndCups': {
    'en': 'Your leagues and cups',
    'no': 'Dine ligaer og cuper',
  },
  'yourPercentageOfAmounts': {
    'en': 'Your percentage of the amounts',
    'no': 'Deres prosentandel av beløpene',
  },
  'youthNationalTeams': {
    'en': 'Youth national teams',
    'no': 'Aldersbestemte landslag',
  },
  '_youthNationalTeams': {
    'en': 'youth national teams',
    'no': 'aldersbestemte landslag',
  },
  'yourTransferFee': {
    'en': 'Your transfer fee',
    'no': 'Deres overgangssum',
  },
  'youWillNotBeAbleToDeleteAccount': {
    'en': 'You will not be able to delete the account yourself',
    'no': 'Du vil ikke kunne slette kontoen selv',
  },


  // positions
  'GK': {
    'en': 'GK',
    'no': 'K'
  },
  'LWB': {
    'en': 'LWB',
    'no': 'VVB'
  },
  'LB': {
    'en': 'LB',
    'no': 'VB'
  },
  'LCB': {
    'en': 'LCB',
    'no': 'VMS'
  },
  'CB': {
    'en': 'CB',
    'no': 'MS'
  },
  'RCB': {
    'en': 'RCB',
    'no': 'HMS'
  },
  'RB': {
    'en': 'RB',
    'no': 'HB'
  },
  'RWB': {
    'en': 'RWB',
    'no': 'HVB'
  },
  'CDM': {
    'en': 'CDM',
    'no': 'SDM'
  },
  'LCM': {
    'en': 'LCM',
    'no': 'VSM'
  },
  'CM': {
    'en': 'CM',
    'no': 'SM'
  },
  'RCM': {
    'en': 'RCM',
    'no': 'HSM'
  },
  'CAM': {
    'en': 'CAM',
    'no': 'SOM'
  },
  'LM': {
    'en': 'LM',
    'no': 'VM'
  },
  'LW': {
    'en': 'LW',
    'no': 'VV'
  },
  'RW': {
    'en': 'RW',
    'no': 'HV'
  },
  'RM': {
    'en': 'RM',
    'no': 'HM'
  },
  'LS': {
    'en': 'LS',
    'no': 'VS'
  },
  'S': {
    'en': 'S',
    'no': 'S'
  },
  'RS': {
    'en': 'RS',
    'no': 'HS'
  },


  // static team names
  'masterTeam': {
    'en': 'Master team',
    'no': 'Masterlag'
  },
  'shadowTeam': {
    'en': 'Shadow team',
    'no': 'Skyggelag'
  },
  'ownTeam': {
    'en': 'Own team',
    'no': 'Eget lag'
  },
  'academyTeam': {
    'en': 'Academy team',
    'no': 'Akademilag'
  },


  // role keys
  'keyPlayer': {
    'en': 'Key player',
    'no': 'Nøkkelspiller'
  },
  'rotationPlayer': {
    'en': 'Rotation player',
    'no': 'Rotasjonsspiller'
  },
  'loanedIn': {
    'en': 'Loaned in',
    'no': 'På innlån'
  },
  'developmentPlayer': {
    'en': 'Development player',
    'no': 'Utviklingsspiller'
  },
  'reserve': {
    'en': 'Reserve',
    'no': 'Reserve'
  },
  'longTermInjured': {
    'en': 'Long term injured',
    'no': 'Langtidsskadet'
  },
  'loanedOut': {
    'en': 'Loaned out',
    'no': 'På utlån'
  },

  'trialist': {
    'en': 'Trialist',
    'no': 'Hospitant'
  },
  'talent': {
    'en': 'Talent',
    'no': 'Talent',
  },
  'potential': {
    'en': 'Potential',
    'no': 'Potensial',
  },


  // access keys
  'adminAccess': {
    'en': 'Admin',
    'no': 'Admin'
  },
  'fullAccess': {
    'en': 'Full access',
    'no': 'Full tilgang'
  },
  'economyAccess': {
    'en': 'Economy access',
    'no': 'Økonomitilgang'
  },
  'scoutAccess': {
    'en': 'Scout access',
    'no': 'Speidertilgang'
  },
  'noneAccess': {
    'en': 'No access',
    'no': 'Ingen tilgang'
  },


  // access info
  'adminAccessInfo': {
    'en': 'Admin gives access to the entire platform.',
    'no': 'Admin gir tilgang til hele plattformen.'
  },
  'fullAccessInfo': {
    'en': 'Full access gives access to the entire platform, except for adding new users or changing the access of existing users',
    'no': 'Full tilgang gir tilgang til hele plattformen, med unntak av å legge til nye brukere eller endre tilgangen til eksisterende brukere.'
  },
  'economyAccessInfo': {
    'en': 'Users with economy access will have access to the Home, Own team, Clauses and Settings tabs, but they will not be able to see the activity log on the Home screen or add or remove players from Own team.',
    'no': 'Brukere med økonomitilgang vil ha tilgang til fanene Hjem, Eget lag, Klausuler og Innstillinger, men de vil ikke kunne se aktivitetsloggen på Hjem-skjermen eller legge til eller fjerne spillere på Eget lag.'
  },
  'scoutAccessInfo': {
    'en': 'Users with scout access will not see the Clauses tab or the protected columns in the table and matrix under Own team. They will also not have the ability to change the club\'s formation, add or remove players from Own team, or manage other users.',
    'no': 'Brukere med speidertilgang vil ikke se fanen Klausuler eller de beskyttede kolonnene i tabellen og matrisen under Eget lag. De vil heller ikke ha muligheten til å endre klubbens formasjon, legge til eller fjerne spillere på Eget lag, eller administrere andre brukere.'
  },
  'noneAccessInfo': {
    'en': 'Users without access will not have access to any part of the platform.',
    'no': 'Brukere uten tilgang vil ikke ha tilgang til noe av plattformen.'
  },

};
