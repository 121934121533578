import '../../../platform.css';
import './../scouting.css';

import { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { teamsState } from '../../../recoil/atoms/teamsState';

import SearchIcon from '@mui/icons-material/Search';

import { searchPlayerOverviews } from '../../../services/fokusServer/playerOverviews';
import { Club, FlexibleJsonMapping, PlayerOverview, PlayerOverviews, PlayerOverviewsQueryOptions } from '../../../types';
import { PlayerTable } from '../../../components/tables/playerTable/PlayerTable';
import { AuthContextType, useAuthContext } from '../../../../common/contexts/AuthContext';
import { staticLanguageMap } from '../../../../common/static/staticLanguageMap';
import { debounce, stringArraysContainSameElements } from '../../../utils/utils';
import { pagesPerChapter } from '../Scouting';
import { PlayerScoutingFilters } from './PlayerScoutingFilters';
import { getPlayerNumberIdsOnTeams } from '../../../utils/teamUtils';
import { contractKeysToMonths } from '../../../static/propertyValues';


interface PlayerScoutingProps {
  playerData: PlayerOverviews | undefined;
  setPlayerData: (playerData: PlayerOverviews | undefined) => void;
}


export const PlayerScouting: React.FC<PlayerScoutingProps> = ({ playerData, setPlayerData }) => {

  const { currentUser } = useAuthContext() as AuthContextType;

  const userConfig = useRecoilValue(userConfigState);
  const teams = useRecoilValue(teamsState);

  const [tableData, setTableData] = useState<FlexibleJsonMapping[]>([]);

  // currentModuloPage equals page % pagesPerChapter and is 1-indexed, while chapters are 0-indexed
  const [currentModuloPage, setCurrentModuloPage] = useState(0);
  const [currentChapter, setCurrentChapter] = useState(0);
  const [totalHits, setTotalHits] = useState(0);
  const [hitsPerPage, setHitsPerPage] = useState(50);

  const [nameSearchString, setNameSearchString] = useState('');
  const [debouncedNameSearchString, setDebouncedNameSearchString] = useState('');
  const clearNameDebounceRef = useRef<() => void>(() => null);

  const [selectedMaxAge, setSelectedMaxAge] = useState<string[]>([]);
  const [selectedContractStatus, setSelectedContractStatus] = useState<string[]>([]);
  const [selectedLeagues, setSelectedLeagues] = useState<string[]>([]);
  const [selectedPositions, setSelectedPositions] = useState<string[]>([]);
  const [selectedOrderBy, setSelectedOrderBy] = useState<string[]>(['clubIndex']);

  const [selectedClubs, setSelectedClubs] = useState<Club[]>([]);
  const [selectedMinConfidence, setSelectedMinConfidence] = useState<string[]>([]);
  const [selectedMinClubIndex, setSelectedMinClubIndex] = useState<string[]>([]);
  const [selectedMinSkillIndex, setSelectedMinSkillIndex] = useState<string[]>([]);
  const [selectedNationalities, setSelectedNationalities] = useState<string[]>([]);
  const [selectedLogisticTeams, setSelectedLogisticTeams] = useState<string[]>([]);

  const [currentNameSearchString, setCurrentNameSearchString] = useState<string>('');
  const [currentMaxAge, setCurrentMaxAge] = useState<string[]>([]);
  const [currentContractStatus, setCurrentContractStatus] = useState<string[]>([]);
  const [currentLeagues, setCurrentLeagues] = useState<string[]>([]);
  const [currentPositions, setCurrentPositions] = useState<string[]>([]);
  const [currentOrderBy, setCurrentOrderBy] = useState<string[]>([]);

  const [currentClubs, setCurrentClubs] = useState<Club[]>([]);
  const [currentMinConfidence, setCurrentMinConfidence] = useState<string[]>([]);
  const [currentMinClubIndex, setCurrentMinClubIndex] = useState<string[]>([]);
  const [currentMinSkillIndex, setCurrentMinSkillIndex] = useState<string[]>([]);
  const [currentNationalities, setCurrentNationalities] = useState<string[]>([]);
  const [currentLogisticTeams, setCurrentLogisticTeams] = useState<string[]>([]);

  const [isContractStatusToggled, setIsContractStatusToggled] = useState(false);
  const [isPositionsToggled, setIsPositionsToggled] = useState(false);

  const [currentIsContractStatusToggled, setCurrentIsContractStatusToggled] = useState(false);
  const [currentIsPositionsToggled, setCurrentIsPositionsToggled] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isEmptySearchResult, setIsEmptySearchResult] = useState(false);

  const [isAnyFilterExpandedToggle, setIsAnyFilterExpandedToggle] = useState(false);
  const [isAdvancedFiltersExpanded, setIsAdvancedFiltersExpanded] = useState(false);
  const [scoutingViewResultContainerTop, setScoutingViewResultContainerTop] = useState(85);

  const [searchWasLoaded, setSearchWasLoaded] = useState(false);


  const anyFilterSelected = () => {
    return (
      nameSearchString.length !== 0
      || selectedMaxAge.length !== 0
      || selectedContractStatus.length !== 0
      || selectedLeagues.length !== 0
      || selectedPositions.length !== 0
      || selectedOrderBy.length !== 0

      || selectedClubs.length !== 0
      || selectedMinConfidence.length !== 0
      || selectedMinClubIndex.length !== 0
      || selectedMinSkillIndex.length !== 0
      || selectedNationalities.length !== 0
      || selectedLogisticTeams.length !== 0
    );
  };


  const onKeyDownNameSearchField = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && nameSearchString !== currentNameSearchString) {
      handleSearchButtonPressed(true, true);
    }
  };


  const setCurrentFilters = (reset: boolean) => {
    setCurrentNameSearchString(reset ? '' : nameSearchString);
    setCurrentMaxAge(reset ? [] : selectedMaxAge);
    setCurrentContractStatus(reset ? [] : selectedContractStatus);
    setCurrentLeagues(reset ? [] : selectedLeagues);
    setCurrentPositions(reset ? [] : selectedPositions);
    setCurrentOrderBy(reset ? ['clubIndex'] : selectedOrderBy);

    setCurrentClubs(reset ? [] : selectedClubs);
    setCurrentMinConfidence(reset ? [] : selectedMinConfidence);
    setCurrentMinClubIndex(reset ? [] : selectedMinClubIndex);
    setCurrentMinSkillIndex(reset ? [] : selectedMinSkillIndex);
    setCurrentNationalities(reset ? [] : selectedNationalities);
    setCurrentLogisticTeams(reset ? [] : selectedLogisticTeams);

    setCurrentIsContractStatusToggled(reset ? false : isContractStatusToggled);
    setCurrentIsPositionsToggled(reset ? false : isPositionsToggled);
  };


  const handleSearchButtonPressed = async (isUserInitiated: boolean, isNewSearch: boolean, nextChapter?: number) => {

    setIsLoading(true);

    if (isUserInitiated) {
      clearNameDebounceRef.current();
    }

    const nextPageToQuery = isNewSearch
      ? 1
      : (nextChapter !== undefined
        ? ((nextChapter * pagesPerChapter) + 1)
        : ((currentChapter * pagesPerChapter) + (currentModuloPage + 1)));

    const playerIds = teams && selectedLogisticTeams.length > 0 ? getPlayerNumberIdsOnTeams(teams, selectedLogisticTeams) : undefined;

    const queryOptions: PlayerOverviewsQueryOptions = {
      name: nameSearchString.length > 0 ? nameSearchString.toLowerCase() : undefined,
      maxAge: selectedMaxAge.length > 0 ? parseInt(selectedMaxAge[0]) : undefined,
      maxContractLength: selectedContractStatus.length > 0 ? contractKeysToMonths[selectedContractStatus[0]] : undefined,
      includeUnknownContracts: isContractStatusToggled,
      competitions: selectedLeagues.length > 0 ? selectedLeagues.map(league => parseInt(league)) : undefined,
      primaryPositions: selectedPositions.length > 0 ? selectedPositions : undefined,
      includeSecondaryPosition: isPositionsToggled,

      clubs: selectedClubs.length > 0 ? selectedClubs.map(club => club.id) : undefined,
      minConfidence: selectedMinConfidence.length > 0 ? (parseInt(selectedMinConfidence[0]) / 100) : undefined,
      minClubIndex: selectedMinClubIndex.length > 0 ? parseInt(selectedMinClubIndex[0]) : undefined,
      minSkillIndex: selectedMinSkillIndex.length > 0 ? parseInt(selectedMinSkillIndex[0]) : undefined,
      countryCodes: selectedNationalities.length > 0 ? selectedNationalities : undefined,
      playerIds: playerIds,

      sortBy: nameSearchString.length > 0
        ? undefined
        : selectedOrderBy.length > 0
          ? selectedOrderBy[0]
          : undefined,

      page: nextPageToQuery,
    };

    try {
      const result: FlexibleJsonMapping | undefined = await searchPlayerOverviews(currentUser, queryOptions);

      const totalHits = result?.total_hits;
      const hitsPerPage = result?.hits_per_page;
      const page = result?.current_page;
      const players: PlayerOverview[] = result?.players ?? [];

      if (!result || totalHits === undefined || !page) {
        handleSearchReset();
        return;
      }

      setCurrentFilters(false);

      if (totalHits === 0 || (players.length === 0 && isNewSearch)) {
        setEmptySearchData();
        setIsEmptySearchResult(true);
      }
      else {
        const playersObject = players.reduce((acc: PlayerOverviews, player: PlayerOverview) => {
          acc[player['id']] = player;
          return acc;
        }, {});

        const isFirstPageOfChapter = isNewSearch || nextChapter !== undefined;

        setPlayerData(isFirstPageOfChapter ? playersObject : { ...playerData, ...playersObject });
        setTableData(isFirstPageOfChapter ? players : [...tableData, ...players]);
        setTotalHits(totalHits);
        setHitsPerPage(hitsPerPage);
        setCurrentModuloPage(isFirstPageOfChapter ? 1 : (page - (currentChapter * 4)));
        setCurrentChapter(isNewSearch ? 0 : (nextChapter !== undefined ? nextChapter : currentChapter));
        setIsEmptySearchResult(false);
      }
    }
    catch (error) {
      console.log(error); // eslint-disable-line no-console
    }

    setIsLoading(false);
  };


  const handleChangeCurrentChapter = async (isIncrement: boolean) => {
    setTableData([]);
    const nextChapter = isIncrement ? currentChapter + 1 : currentChapter - 1;
    handleSearchButtonPressed(false, false, nextChapter);
  };


  const handleSearchReset = () => {
    setEmptySearchData();
    setCurrentFilters(true);
    setIsEmptySearchResult(false);
  };


  const setEmptySearchData = () => {
    setPlayerData(undefined);
    setTableData([]);
    setCurrentModuloPage(0);
    setCurrentChapter(0);
    setTotalHits(0);
    setHitsPerPage(0);
  };


  const handleAdvancedFiltersExpansion = () => {
    if (isAdvancedFiltersExpanded) {
      setScoutingViewResultContainerTop(85);
      setIsAdvancedFiltersExpanded(false);
    }
    else {
      setScoutingViewResultContainerTop(155);
      setIsAdvancedFiltersExpanded(true);
    }
  };


  const handleFilterUnexpansion = (filter: string) => {
    if (tableData.length === 0 && !isEmptySearchResult) return;

    let shouldTriggerSearch = false;

    switch (filter) {
      case 'maxAge':
        shouldTriggerSearch = !stringArraysContainSameElements(currentMaxAge, selectedMaxAge);
        break;
      case 'contractStatus':
        shouldTriggerSearch = !stringArraysContainSameElements(currentContractStatus, selectedContractStatus)
          || currentIsContractStatusToggled !== isContractStatusToggled;
        break;
      case 'leagues':
        shouldTriggerSearch = !stringArraysContainSameElements(currentLeagues, selectedLeagues);
        break;
      case 'positions':
        shouldTriggerSearch = !stringArraysContainSameElements(currentPositions, selectedPositions)
          || currentIsPositionsToggled !== isPositionsToggled;
        break;
      case 'orderBy':
        shouldTriggerSearch = !stringArraysContainSameElements(currentOrderBy, selectedOrderBy);
        break;
      case 'clubs':
        shouldTriggerSearch = !stringArraysContainSameElements(currentClubs.map(club => String(club.id)), selectedClubs.map(club => String(club.id)));
        break;
      case 'minConfidence':
        shouldTriggerSearch = !stringArraysContainSameElements(currentMinConfidence, selectedMinConfidence);
        break;
      case 'minClubIndex':
        shouldTriggerSearch = !stringArraysContainSameElements(currentMinClubIndex, selectedMinClubIndex);
        break;
      case 'minSkillIndex':
        shouldTriggerSearch = !stringArraysContainSameElements(currentMinSkillIndex, selectedMinSkillIndex);
        break;
      case 'nationalities':
        shouldTriggerSearch = !stringArraysContainSameElements(currentNationalities, selectedNationalities);
        break;
      case 'logisticTeams':
        shouldTriggerSearch = !stringArraysContainSameElements(currentLogisticTeams, selectedLogisticTeams);
        break;
    }

    if (shouldTriggerSearch) {
      handleSearchButtonPressed(false, true);
    }
  };


  useEffect(() => {
    if (searchWasLoaded) {
      handleSearchButtonPressed(true, true);
    }
    setSearchWasLoaded(false);
  }, [searchWasLoaded]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (nameSearchString.length === 0) {
      if (anyFilterSelected() && nameSearchString !== currentNameSearchString) {
        handleSearchButtonPressed(false, true);
      }
      else {
        handleSearchReset();
      }
    }

    const [debounceFilter, clearDebounce] = debounce(() => {
      if (nameSearchString !== debouncedNameSearchString) {
        setDebouncedNameSearchString(nameSearchString);
      }
    }, 500);

    debounceFilter();

    clearNameDebounceRef.current = clearDebounce;

    return () => {
      clearDebounce();
    };
  }, [nameSearchString]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (debouncedNameSearchString !== '' && debouncedNameSearchString !== currentNameSearchString) {
      handleSearchButtonPressed(false, true);
    }
  }, [debouncedNameSearchString]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div className='scouting-view-content-container'>

      <div className='full-size-container absolute-container' id='player-scouting-empty-background' onClick={() => setIsAnyFilterExpandedToggle(false)}>
        &nbsp;
      </div>

      <PlayerScoutingFilters
        nameSearchString={nameSearchString}
        setNameSearchString={setNameSearchString}
        onKeyDownNameSearchField={onKeyDownNameSearchField}

        selectedMaxAge={selectedMaxAge}
        setSelectedMaxAge={setSelectedMaxAge}
        selectedContractStatus={selectedContractStatus}
        setSelectedContractStatus={setSelectedContractStatus}
        selectedLeagues={selectedLeagues}
        setSelectedLeagues={setSelectedLeagues}
        selectedPositions={selectedPositions}
        setSelectedPositions={setSelectedPositions}
        selectedOrderBy={selectedOrderBy}
        setSelectedOrderBy={setSelectedOrderBy}

        selectedClubs={selectedClubs}
        setSelectedClubs={setSelectedClubs}
        selectedMinConfidence={selectedMinConfidence}
        setSelectedMinConfidence={setSelectedMinConfidence}
        selectedMinClubIndex={selectedMinClubIndex}
        setSelectedMinClubIndex={setSelectedMinClubIndex}
        selectedMinSkillIndex={selectedMinSkillIndex}
        setSelectedMinSkillIndex={setSelectedMinSkillIndex}
        selectedNationalities={selectedNationalities}
        setSelectedNationalities={setSelectedNationalities}
        selectedLogisticTeams={selectedLogisticTeams}
        setSelectedLogisticTeams={setSelectedLogisticTeams}

        isContractStatusToggled={isContractStatusToggled}
        setIsContractStatusToggled={setIsContractStatusToggled}
        isPositionsToggled={isPositionsToggled}
        setIsPositionsToggled={setIsPositionsToggled}

        isAnyFilterExpandedToggle={isAnyFilterExpandedToggle}
        setIsAnyFilterExpandedToggle={setIsAnyFilterExpandedToggle}
        isAdvancedFiltersExpanded={isAdvancedFiltersExpanded}
        handleAdvancedFiltersExpansion={handleAdvancedFiltersExpansion}

        handleFilterUnexpansion={handleFilterUnexpansion}
        handleSearchReset={handleSearchReset}
        setSearchWasLoaded={setSearchWasLoaded}
      />

      <div
        className='scouting-view-result-container'
        style={{ top: scoutingViewResultContainerTop }}
      >
        {tableData.length > 0 && (
          <PlayerTable
            data={tableData}
            isLoading={isLoading}
            handleSearchButtonPressed={handleSearchButtonPressed}
            currentModuloPage={currentModuloPage}
            currentChapter={currentChapter}
            totalHits={totalHits}
            hitsPerPage={hitsPerPage}
            handleChangeCurrentChapter={handleChangeCurrentChapter}
            selectedOrderBy={selectedOrderBy.length > 0 ? selectedOrderBy[0] : undefined}
          />
        )}

        {tableData.length === 0 && isEmptySearchResult && !isLoading && (
          <div className='scouting-info-title fade-in'>
            {userConfig ? staticLanguageMap['noResult'][userConfig.language] : ''}
          </div>
        )}

        {tableData.length === 0 && !isEmptySearchResult && !isLoading && (
          <div className='scouting-info-button' onClick={() => handleSearchButtonPressed(true, true)}>
            <div className='scouting-info-button-text'>
              {userConfig ? staticLanguageMap['searchForPlayers'][userConfig.language] : ''}
            </div>
            <div className='scouting-info-button-icon'>
              <SearchIcon style={{ fontSize: 24, marginLeft: 20, marginTop: 8 }} />
            </div>
          </div>
        )}

      </div>
    </div>
  );
};
