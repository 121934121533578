import '../../platform.css';
import './home.css';

import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { competitionsState } from '../../recoil/atoms/competitionsState';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import RocketIcon from '@mui/icons-material/Rocket';
import EqualizerIcon from '@mui/icons-material/Equalizer';

import { getClubColor } from '../../utils/configUtils';
import { TeamTable } from '../../components/tables/teamTable/TeamTable';
import { FixedTabLine } from '../../components/tabLines/FixedTabLine';
import { PlatformHistory } from '../../components/history/PlatformHistory';
import { HomeViewLinePlot } from '../../components/plots/HomeViewLinePlot';
import { staticLanguageMap } from '../../../common/static/staticLanguageMap';
import { Toggle } from '../../components/input/Toggle';
import { FlexibleJsonMapping, JsonMapping } from '../../types';
import { clubSettingsState } from '../../recoil/atoms/clubSettingsState';
import { getHomePageData } from '../../services/firestore/homePage';


export const tierColors: JsonMapping = {
  '1': '#79bbc3',
  '2': '#c6a66c',
};


export const Home: React.FC = () => {

  const userConfig = useRecoilValue(userConfigState);
  const clubSettings = useRecoilValue(clubSettingsState);
  const competitions = useRecoilValue(competitionsState);

  const [activeLineTab, setActiveLineTab] = useState(0);
  const [activeTableTab, setActiveTableTab] = useState(0);

  const [tableDataArray, setTableDataArray] = useState<FlexibleJsonMapping[][]>([]);
  const [tableOptionsArray, setTableOptionsArray] = useState<string[]>([]);
  const [lineData, setLineData] = useState<FlexibleJsonMapping | undefined>(undefined);
  const [comparisonCompetitionIdToTierMap, setComparisonCompetitionIdToTierMap] = useState<FlexibleJsonMapping>({});
  const [clubName, setClubName] = useState<string | undefined>(undefined);
  const [clubCountryCode, setClubCountryCode] = useState<string | undefined>(undefined);

  const [isAdvancedTeamDataToggled, setIsAdvancedTeamDataToggled] = useState(false);


  const lineTabOptions = [
    <EqualizerIcon key={'equializer-icon'} style={{ fontSize: 21, marginTop: 1 }} />,
    <CalendarMonthIcon key={'calendar-icon'} style={{ fontSize: 21, marginTop: 1 }} />,
    <RocketIcon key={'rocket-icon'} style={{ fontSize: 21, marginTop: 1 }} />,
  ];
  const lineTabTitles = [
    'xGDataTitle',
    'averageAgePerMinutePlayedTitle',
    'u21MinutesPerMinuteTitle'
  ];


  useEffect(() => {
    if (userConfig && clubSettings && competitions) {

      const tableNames: string[] = [];
      const tableIterationIds: number[] = [];
      const competitionIdsToCompareWith: Set<number> = new Set();

      if (clubSettings.iterations) {
        clubSettings.iterations.forEach((iteration: FlexibleJsonMapping) => {
          const competition = competitions[iteration.competitionId];
          if (competition && competition.type === 'League') {
            const tableName = competition.name + ' ' + iteration.season;
            tableNames.push(tableName);
            tableIterationIds.push(iteration.iterationId);
            competitionIdsToCompareWith.add(Number(iteration.competitionId));
          }
        });
      }

      const competitionIdToTierMap: FlexibleJsonMapping = {};
      competitionIdsToCompareWith.forEach((competitionId) => {
        competitionIdToTierMap[competitionId] = competitions[competitionId].tier;
      });

      getHomePageData(clubSettings.clubId, tableIterationIds, competitionIdsToCompareWith).then((homeViewData) => {
        setTableOptionsArray(tableNames);
        setTableDataArray(homeViewData.tableData);
        setLineData(homeViewData.lineData);
        setComparisonCompetitionIdToTierMap(competitionIdToTierMap);
        setClubName(clubSettings.clubName ?? staticLanguageMap['theClub'][userConfig.language]);
        setClubCountryCode(clubSettings.countryCode ?? 'NO');
      });
    }

  }, [userConfig, clubSettings, competitions]);


  const clubColor = getClubColor(userConfig?.club ?? '');


  return (

    <div className='home-view-container'>
      <div className='home-view-column home-view-left-column'>
        <div className='home-view-history-section-container'>
          <FixedTabLine
            tabOptions={lineTabOptions}
            tabTitles={['xGData', 'totalMarketValue', 'averageAgePerMinutePlayed', 'u21MinutesPerMinute']}
            activeTab={activeLineTab}
            setActiveTab={setActiveLineTab}
            withoutTabLine={true}
          />

          <div className='home-view-section-title home-view-history-section-title'>
            {userConfig && lineData
              ? (staticLanguageMap[lineTabTitles[activeLineTab]][userConfig.language] + (activeLineTab === 0 ? clubName : ''))
              : ''}
          </div>

          {activeLineTab === 0 && lineData && (
            <div className='home-view-history-section-labels'>
              <div className='home-view-history-section-label'>
                <div className='home-view-history-section-label-line' style={{ backgroundColor: '#6abe88' }}>&nbsp;</div>
                <div className='home-view-history-section-label-text'>
                  {userConfig ? staticLanguageMap['xG'][userConfig.language] : ''}
                </div>
              </div>

              <div className='home-view-history-section-label'>
                <div className='home-view-history-section-label-line' style={{ backgroundColor: '#c77070' }}>&nbsp;</div>
                <div className='home-view-history-section-label-text'>
                  {userConfig ? staticLanguageMap['xGC'][userConfig.language] : ''}
                </div>
              </div>
            </div>
          )}

          {activeLineTab > 0 && lineData && (
            <div className='home-view-history-section-labels home-view-history-section-labels-wide'>
              <div className='home-view-history-section-label'>
                <div className='home-view-history-section-label-line' style={{ backgroundColor: clubColor }}>&nbsp;</div>
                <div className='home-view-history-section-label-text'>
                  {clubName}
                </div>
              </div>

              {Object.entries(comparisonCompetitionIdToTierMap).map(([competitionId, tier]) => {
                return (
                  <div className='home-view-history-section-label' key={competitionId}>
                    <div className='home-view-history-section-label-line' style={{ backgroundColor: tierColors[tier] }}>&nbsp;</div>
                    <div className='home-view-history-section-label-text'>
                      {competitions[Number(competitionId)].name}
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          <div className='home-view-history-section-line-plot-container'>
            {lineData && (
              <HomeViewLinePlot
                data={lineData}
                activeTab={activeLineTab}
                clubColor={clubColor}
                competitionIdToTierMap={comparisonCompetitionIdToTierMap}
              />
            )}
          </div>
        </div>

        <div className='home-view-activity-section-container'>
          <div className='home-view-section-title home-view-activity-section-title'>
            {userConfig ? staticLanguageMap['activityLast14Days'][userConfig.language] : ''}
          </div>

          <div className='home-view-activity-section-log-container'>
            <PlatformHistory />
          </div>
        </div>
      </div>

      <div className='home-view-column home-view-right-column'>
        <FixedTabLine
          tabOptions={tableOptionsArray}
          activeTab={activeTableTab}
          setActiveTab={setActiveTableTab}
          withoutTabLine={true}
        />
        {tableDataArray.length > 0 && (
          <div className='home-view-table-toggle-section'>
            <div className='home-view-table-toggle-section-text'>
              {userConfig ? staticLanguageMap['advancedData'][userConfig.language] : ''}
            </div>
            <div className='home-view-table-toggle-section-toggle'>
              <Toggle isToggled={isAdvancedTeamDataToggled} setIsToggled={setIsAdvancedTeamDataToggled} boxShadow={'0px 0px 3px 1px #181a23aa'} />
            </div>
          </div>
        )}
        {userConfig && tableDataArray.length > 0 && clubCountryCode && (
          <div className='home-view-table-container'>
            <TeamTable
              data={tableDataArray[activeTableTab]}
              isAdvancedTeamDataToggled={isAdvancedTeamDataToggled}
            />
          </div>
        )}
      </div>
    </div>
  );
};
