import './login.css';

import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContextType, useAuthContext } from '../../../common/contexts/AuthContext';

import ErrorIcon from '@mui/icons-material/Error';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReplayIcon from '@mui/icons-material/Replay';
import EmailIcon from '@mui/icons-material/Email';

import NO from 'country-flag-icons/react/3x2/NO';
import GB from 'country-flag-icons/react/3x2/GB';

import { staticLanguageMap } from '../../../common/static/staticLanguageMap';
import { FokusLogo } from '../../svg/FokusLogo';
// import { FokusLogoWithBackground } from '../../svg/FokusLogoWithBackground';


export const Login = () => {

  const { currentUser, login, resetPassword } = useAuthContext() as AuthContextType;

  const navigate = useNavigate();

  const [selectedLanguage, setSelectedLanguage] = useState('no');
  const [selectedContactIcon, setSelectedContactIcon] = useState<string | undefined>(undefined);

  const [resetPasswordView, setResetPasswordView] = useState(false);

  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  function toggleViewChange() {
    setError('');
    setMessage('');
    setResetPasswordView(!resetPasswordView);
  }

  async function handleSignIn(event: React.FormEvent<HTMLFormElement>) {
    if (emailRef.current && passwordRef.current) {
      setError('');
      event.preventDefault();

      try {
        setError('');
        setLoading(true);
        await login(emailRef.current.value, passwordRef.current.value);

        navigate('/platform');
      } catch {
        setError('invalidInput');
      }

      setLoading(false);
    }
  }

  async function handleResetPassword(event: React.FormEvent<HTMLFormElement>) {
    if (emailRef.current) {
      setError('');
      setMessage('');
      event.preventDefault();

      try {
        setMessage('');
        setError('');
        setLoading(true);
        await resetPassword(emailRef.current.value);

        setMessage('emailSent');
      } catch {
        setError('invalidEmail');
      }

      setLoading(false);
    }
  }

  useEffect(() => {
    if (currentUser) {
      navigate('/platform');
    }
  }, [currentUser, navigate]);

  return (
    <div className='view-container-base login-container'>

      <div className='login-section'>

        <div className='login-logo-section'>
          <div className='login-logo'>
            <FokusLogo whiteLogo={true} />
          </div>
          <div className='login-logo-text' style={{ fontFamily: 'Montserrat' }}>
            FOKUS
          </div>
        </div>

        <div className='login-contact-section'>
          <div
            className={'login-contact-icon' + (selectedContactIcon === 'email' ? ' login-contact-icon-selected' : '')}
            onClick={() => setSelectedContactIcon(selectedContactIcon === 'email' ? undefined : 'email')}
          >
            <EmailIcon style={{ fontSize: 24 }} />
          </div>
        </div>

        {selectedContactIcon && (
          <div className='login-contact-text'>
            {selectedContactIcon === 'email' && 'lars.gundersen@fokus.ing'}
          </div>
        )}

        <div className='login-flag-section'>
          <div
            className={'login-flag' + (selectedLanguage === 'no' ? ' login-flag-selected' : ' login-flag-not-selected')}
            onClick={() => setSelectedLanguage('no')}
          >
            <NO />
            <div className={'login-flag-overlay' + (selectedLanguage === 'no' ? '' : ' login-flag-overlay-not-selected')}>&nbsp;</div>
          </div>
          <div
            className={'login-flag' + (selectedLanguage === 'en' ? ' login-flag-selected' : ' login-flag-not-selected')}
            onClick={() => setSelectedLanguage('en')}
          >
            <GB />
            <div className={'login-flag-overlay' + (selectedLanguage === 'en' ? '' : ' login-flag-overlay-not-selected')}>&nbsp;</div>
          </div>
        </div>

        {resetPasswordView && (
          <div className='login-box'>

            <div className='login-space'>&nbsp;</div>
            <div className='login-space'>&nbsp;</div>

            <form className='login-form' onSubmit={handleResetPassword}>

              {staticLanguageMap['email'][selectedLanguage]}
              <input id='email' className='login-input' type='email' ref={emailRef} required />

              {error && (
                <div className='login-message'>
                  <div className='login-error-icon'>
                    <ErrorIcon style={{ fontSize: 26, color: '#1d1f28' }} />
                  </div>
                  {staticLanguageMap[error][selectedLanguage]}
                </div>
              )}

              {message && (
                <div className='login-message'>
                  <div className='login-error-icon'>
                    <CheckCircleIcon style={{ fontSize: 26, color: '#1d1f28' }} />
                  </div>
                  {staticLanguageMap[message][selectedLanguage]}
                </div>
              )}

              {!error && !message && <div className='login-empty-box'>&nbsp;</div>}

              <div className='login-button'>
                <input disabled={loading} className='login-submit' type='submit' value={staticLanguageMap['resetPassword'][selectedLanguage]} />

                <div className='login-reset-icon'>
                  <ReplayIcon style={{ fontSize: 27, color: '#ffffff' }} />
                </div>
              </div>

            </form>

            <div className='login-space'>&nbsp;</div>
            <div className='login-space'>&nbsp;</div>
            <div className='login-space-small'>&nbsp;</div>

            <div className='login-link' onClick={() => toggleViewChange()}>
              {staticLanguageMap['login'][selectedLanguage]}
            </div>

          </div>
        )}

        {!resetPasswordView && (
          <div className='login-box'>

            <form className='login-form' onSubmit={handleSignIn}>

              {staticLanguageMap['email'][selectedLanguage]}
              <input id='email' className='login-input' type='email' ref={emailRef} required />

              {staticLanguageMap['password'][selectedLanguage]}
              <input id='password' className='login-input' type='password' ref={passwordRef} required />

              {error && (
                <div className='login-message'>
                  <div className='login-error-icon'>
                    <ErrorIcon style={{ fontSize: 26, color: '#1d1f28' }} />
                  </div>
                  {staticLanguageMap[error][selectedLanguage]}
                </div>
              )}

              {!error && <div className='login-empty-box'>&nbsp;</div>}

              <div className='login-button'>
                <input disabled={loading} className='login-submit' type='submit' value={staticLanguageMap['login'][selectedLanguage]} />
                <div className='login-icon'>
                  <ArrowRightAltIcon style={{ fontSize: 36, color: '#ffffff' }} />
                </div>
              </div>

            </form>

            <div className='login-link' onClick={() => toggleViewChange()}>
              {staticLanguageMap['forgotPassword'][selectedLanguage]}
            </div>

          </div>
        )}
      </div>

      {/* <div
        className='full-size-container'
        style={{ width: '86%', marginLeft: '7%' }}
      >
        <FokusLogoWithBackground />
      </div>

      <div
        className='full-size-container'
        style={{ position: 'absolute', background: 'linear-gradient(to bottom, #11161d 10%, #0e1318c8 20%, #0e1318d2 80%)' }}
      >
        &nbsp;
      </div>

      <div
        style={{ position: 'absolute', width: 250, left: 631.5, top: 176 }}
      >
        <FokusLogo whiteLogo={true} />
      </div> */}

    </div>
  );
};
