import axios from 'axios';

import { FirebaseUser } from '../../../firebase';
import { FlexibleJsonMapping, PlayerId, PlayerOverviews, PlayerOverviewsListQueryOptions, PlayerOverviewsQueryOptions } from '../../types';

import { BASE_URL } from './constants';
import { getBearerToken } from './token';
import { positionOptionsPlatform } from '../../static/propertyValues';


// Get players oveviews for all players on a team or squad
export const getPlayerOverviewsOnTeams = async (
  teams: FlexibleJsonMapping,
  squads: FlexibleJsonMapping,
  currentUser: FirebaseUser
): Promise<PlayerOverviews> => {

  // Get id of all players
  const playerIds = new Set<PlayerId>();

  positionOptionsPlatform.forEach(positionKey => {

    Object.keys(teams).forEach(teamKey => {
      const teamData = teams[teamKey];
      if (positionKey in teamData) {
        const positionArray = teamData[positionKey];
        positionArray.forEach((player: FlexibleJsonMapping) => {
          playerIds.add(player['id']);
        });
      }
    });

    Object.keys(squads).forEach(squadKey => {
      const squadData = squads[squadKey];
      if (positionKey in squadData) {
        const positionArray = squadData[positionKey];
        positionArray.forEach((player: FlexibleJsonMapping) => {
          playerIds.add(player['id']);
        });
      }
    });
  });

  if (playerIds.size === 0) {
    return {};
  }

  const playersOnTeams = await getPlayerOverviews(Array.from(playerIds), currentUser);

  return playersOnTeams ?? {};
};


// Get players oveviews for all players in the given archivedTeams that are not already in playerOverviews
export const getNewPlayerOverviews = async (
  archivedTeams: FlexibleJsonMapping[],
  currentPlayers: PlayerOverviews,
  currentUser: FirebaseUser
): Promise<PlayerOverviews> => {

  // Get id of all players
  const newPlayerIds = new Set<number>();

  archivedTeams.forEach(teamOrSquad => {
    positionOptionsPlatform.forEach(positionKey => {
      if (positionKey in teamOrSquad) {
        const positionArray = teamOrSquad[positionKey];
        positionArray.forEach((player: FlexibleJsonMapping) => {
          if (!(player['id'] in currentPlayers)) {
            newPlayerIds.add(player['id']);
          }
        });
      }
    });
  });

  if (newPlayerIds.size === 0) {
    return {};
  }

  const playersOnTeams = await getPlayerOverviews(Array.from(newPlayerIds), currentUser);

  return playersOnTeams ?? {};
};


// Get players_oveview for all given playerIds
export const getPlayerOverviews = async (
  playerIds: PlayerId[],
  user: FirebaseUser
): Promise<PlayerOverviews | undefined> => {

  playerIds = playerIds.filter((playerId) => !isNaN(Number(playerId))).map((playerId) => Number(playerId));

  if (playerIds.length === 0) return undefined;

  const token = await getBearerToken(user);
  const playerIdsString = playerIds.join(',');

  const result = await axios.get(`${BASE_URL}/players/${playerIdsString}`, {
    headers: { Authorization: token },
  });

  if (result === undefined) {
    return undefined;
  }

  return result.data;
};


// Get players_oveview for all given playerIds
export const getPlayerOverviewsList = async (
  user: FirebaseUser,
  options: PlayerOverviewsListQueryOptions
): Promise<FlexibleJsonMapping | undefined> => {

  // if no player ids are given, we return an empty result without calling the server
  if (!options.playerIds || options.playerIds.length === 0) {
    return {
      total_hits: 0,
      current_page: 1,
      players: [],
    };
  }

  const token = await getBearerToken(user);

  const result = await axios.get(`${BASE_URL}/players/list/`, {
    headers: { Authorization: token },
    params: {
      player_ids: options.playerIds.join(','),
      page: options.page,
      page_size: options.pageSize,
    },
  });

  if (!result) return undefined;

  return result.data;
};


// Search for player overviews
export const searchPlayerOverviews = async (
  user: FirebaseUser,
  options: PlayerOverviewsQueryOptions
): Promise<FlexibleJsonMapping | undefined> => {

  // if the array is undefined, we don't concern with any player ids, but if the array is empty, we attempt to search for an empty set of players
  if (options.playerIds && options.playerIds.length === 0) {
    return {
      total_hits: 0,
      current_page: 1,
      players: [],
    };
  }

  const token = await getBearerToken(user);

  const primaryPositionsString = options.primaryPositions ? options.primaryPositions.join(',') : undefined;

  const competitionsString = options.competitions ? options.competitions.join(',') : undefined;

  const clubsString = options.clubs ? options.clubs.join(',') : undefined;

  const countryCodesString = options.countryCodes ? options.countryCodes.join(',') : undefined;

  const playerIdsString = options.playerIds ? options.playerIds.join(',') : undefined;

  const result = await axios.get(`${BASE_URL}/search/players/`, {
    headers: { Authorization: token },
    params: {
      name: options.name,
      min_age: options.minAge,
      max_age: options.maxAge,
      max_contract_length: options.maxContractLength,
      include_unknown_contracts: options.includeUnknownContracts,
      primary_positions: primaryPositionsString,
      include_secondary_position: options.includeSecondaryPosition,
      competitions: competitionsString,
      clubs: clubsString,
      min_confidence: options.minConfidence,
      max_confidence: options.maxConfidence,
      min_club_index: options.minClubIndex,
      max_club_index: options.maxClubIndex,
      min_skill_index: options.minSkillIndex,
      max_skill_index: options.maxSkillIndex,
      country_codes: countryCodesString,
      player_ids: playerIdsString,
      // min_height: options.minHeight,
      // max_height: options.maxHeight,
      // min_index_diff: options.minIndexDifference,
      // max_index_diff: options.maxIndexDifference,
      sort_by: options.sortBy,
      page: options.page,
      page_size: options.pageSize,
    },
  });

  if (!result) return undefined;

  return result.data;
};
