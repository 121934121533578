import '../../../platform.css';
import './../scouting.css';

import { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';

import SearchIcon from '@mui/icons-material/Search';

import { searchPlayerOverviews } from '../../../services/fokusServer/playerOverviews';
import { Club, FlexibleJsonMapping, PlayerOverview, PlayerOverviewsQueryOptions } from '../../../types';
import { AuthContextType, useAuthContext } from '../../../../common/contexts/AuthContext';
import { staticLanguageMap } from '../../../../common/static/staticLanguageMap';
import { debounce, stringArraysContainSameElements } from '../../../utils/utils';
import { pagesPerChapter } from '../Scouting';
import { PlayerFindingFilters } from './PlayerFindingFilters';
import { PlayerTable } from '../../../components/tables/playerTable/PlayerTable';


interface PlayerFindingProps {
  isAnyFilterExpandedToggle: boolean;
  setIsAnyFilterExpandedToggle: (value: boolean) => void;
  handlePlayerClick: (player: FlexibleJsonMapping) => void;
}


export const PlayerFinding: React.FC<PlayerFindingProps> = ({
  isAnyFilterExpandedToggle,
  setIsAnyFilterExpandedToggle,
  handlePlayerClick
}) => {

  const { currentUser } = useAuthContext() as AuthContextType;

  const userConfig = useRecoilValue(userConfigState);

  const [tableData, setTableData] = useState<FlexibleJsonMapping[]>([]);

  // currentModuloPage equals page % pagesPerChapter and is 1-indexed, while chapters are 0-indexed
  const [currentModuloPage, setCurrentModuloPage] = useState(0);
  const [currentChapter, setCurrentChapter] = useState(0);
  const [totalHits, setTotalHits] = useState(0);
  const [hitsPerPage, setHitsPerPage] = useState(50);

  const [nameSearchString, setNameSearchString] = useState('');
  const [debouncedNameSearchString, setDebouncedNameSearchString] = useState('');
  const clearNameDebounceRef = useRef<() => void>(() => null);

  const [selectedMaxAge, setSelectedMaxAge] = useState<string[]>([]);
  const [selectedMinAge, setSelectedMinAge] = useState<string[]>([]);
  const [selectedLeagues, setSelectedLeagues] = useState<string[]>([]);
  const [selectedNationalities, setSelectedNationalities] = useState<string[]>([]);
  const [selectedClubs, setSelectedClubs] = useState<Club[]>([]);

  const [currentNameSearchString, setCurrentNameSearchString] = useState<string>('');
  const [currentMaxAge, setCurrentMaxAge] = useState<string[]>([]);
  const [currentMinAge, setCurrentMinAge] = useState<string[]>([]);
  const [currentLeagues, setCurrentLeagues] = useState<string[]>([]);
  const [currentNationalities, setCurrentNationalities] = useState<string[]>([]);
  const [currentClubs, setCurrentClubs] = useState<Club[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isEmptySearchResult, setIsEmptySearchResult] = useState(false);


  const anyFilterSelected = () => {
    return (
      nameSearchString.length !== 0
      || selectedMaxAge.length !== 0
      || selectedMinAge.length !== 0
      || selectedLeagues.length !== 0
      || selectedNationalities.length !== 0
      || selectedClubs.length !== 0
    );
  };


  const onKeyDownNameSearchField = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && nameSearchString !== currentNameSearchString) {
      handleSearchButtonPressed(true, true);
    }
  };


  const setCurrentFilters = (reset: boolean) => {
    setCurrentNameSearchString(reset ? '' : nameSearchString);
    setCurrentMaxAge(reset ? [] : selectedMaxAge);
    setCurrentMinAge(reset ? [] : selectedMinAge);
    setCurrentLeagues(reset ? [] : selectedLeagues);
    setCurrentNationalities(reset ? [] : selectedNationalities);
    setCurrentClubs(reset ? [] : selectedClubs);
  };


  const handleSearchButtonPressed = async (isUserInitiated: boolean, isNewSearch: boolean, nextChapter?: number) => {

    setIsLoading(true);

    if (isUserInitiated) {
      clearNameDebounceRef.current();
    }

    const nextPageToQuery = isNewSearch
      ? 1
      : (nextChapter !== undefined
        ? ((nextChapter * pagesPerChapter) + 1)
        : ((currentChapter * pagesPerChapter) + (currentModuloPage + 1)));

    const queryOptions: PlayerOverviewsQueryOptions = {
      name: nameSearchString.length > 0 ? nameSearchString.toLowerCase() : undefined,
      maxAge: selectedMaxAge.length > 0 ? parseInt(selectedMaxAge[0]) : undefined,
      minAge: selectedMinAge.length > 1 ? parseInt(selectedMinAge[1]) : undefined,
      countryCodes: selectedNationalities.length > 0 ? selectedNationalities : undefined,
      competitions: selectedLeagues.length > 0 ? selectedLeagues.map(league => parseInt(league)) : undefined,
      clubs: selectedClubs.length > 0 ? selectedClubs.map(club => club.id) : undefined,

      page: nextPageToQuery,
    };

    try {
      const result: FlexibleJsonMapping | undefined = await searchPlayerOverviews(currentUser, queryOptions);

      const totalHits = result?.total_hits;
      const hitsPerPage = result?.hits_per_page;
      const page = result?.current_page;
      const players: PlayerOverview[] = result?.players ?? [];

      if (!result || totalHits === undefined || !page) {
        handleSearchReset();
      }

      setCurrentFilters(false);

      if (totalHits === 0 || (players.length === 0 && isNewSearch)) {
        setEmptySearchData();
        setIsEmptySearchResult(true);
      }
      else {
        const isFirstPageOfChapter = isNewSearch || nextChapter !== undefined;

        setIsEmptySearchResult(false);
        setTableData(isFirstPageOfChapter ? players : [...tableData, ...players]);
        setTotalHits(totalHits);
        setHitsPerPage(hitsPerPage);
        setCurrentModuloPage(isFirstPageOfChapter ? 1 : (page - (currentChapter * 4)));
        setCurrentChapter(isNewSearch ? 0 : (nextChapter !== undefined ? nextChapter : currentChapter));
      }
    }
    catch (error) {
      console.log(error); // eslint-disable-line no-console
    }

    setIsLoading(false);
  };


  const handleChangeCurrentChapter = async (isIncrement: boolean) => {
    setTableData([]);
    const nextChapter = isIncrement ? currentChapter + 1 : currentChapter - 1;
    handleSearchButtonPressed(false, false, nextChapter);
  };


  const handleSearchReset = () => {
    setEmptySearchData();
    setCurrentFilters(true);
    setIsEmptySearchResult(false);
  };


  const setEmptySearchData = () => {
    setTableData([]);
    setCurrentModuloPage(0);
    setCurrentChapter(0);
    setTotalHits(0);
    setHitsPerPage(0);
  };


  const handleFilterUnexpansion = (filter: string) => {
    if (tableData.length === 0 && !isEmptySearchResult) return;

    let shouldTriggerSearch = false;

    switch (filter) {
      case 'maxAge':
        shouldTriggerSearch = !stringArraysContainSameElements(currentMaxAge, selectedMaxAge);
        break;
      case 'minAge':
        shouldTriggerSearch = !stringArraysContainSameElements(currentMinAge, selectedMinAge);
        break;
      case 'leagues':
        shouldTriggerSearch = !stringArraysContainSameElements(currentLeagues, selectedLeagues);
        break;
      case 'nationalities':
        shouldTriggerSearch = !stringArraysContainSameElements(currentNationalities, selectedNationalities);
        break;
      case 'clubs':
        shouldTriggerSearch = !stringArraysContainSameElements(currentClubs.map(club => String(club.id)), selectedClubs.map(club => String(club.id)));
        break;
    }

    if (shouldTriggerSearch) {
      handleSearchButtonPressed(false, true);
    }
  };


  useEffect(() => {
    if (nameSearchString.length === 0) {
      if (anyFilterSelected() && nameSearchString !== currentNameSearchString) {
        handleSearchButtonPressed(false, true);
      }
      else {
        handleSearchReset();
      }
    }

    const [debounceFilter, clearDebounce] = debounce(() => {
      if (nameSearchString !== debouncedNameSearchString) {
        setDebouncedNameSearchString(nameSearchString);
      }
    }, 500);

    debounceFilter();

    clearNameDebounceRef.current = clearDebounce;

    return () => {
      clearDebounce();
    };
  }, [nameSearchString]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (debouncedNameSearchString !== '' && debouncedNameSearchString !== currentNameSearchString) {
      handleSearchButtonPressed(false, true);
    }
  }, [debouncedNameSearchString]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div className='scouting-view-content-container player-finding-view-content-container'>

      <PlayerFindingFilters
        nameSearchString={nameSearchString}
        setNameSearchString={setNameSearchString}
        onKeyDownNameSearchField={onKeyDownNameSearchField}

        selectedMaxAge={selectedMaxAge}
        setSelectedMaxAge={setSelectedMaxAge}
        selectedMinAge={selectedMinAge}
        setSelectedMinAge={setSelectedMinAge}
        selectedLeagues={selectedLeagues}
        setSelectedLeagues={setSelectedLeagues}
        selectedNationalities={selectedNationalities}
        setSelectedNationalities={setSelectedNationalities}
        selectedClubs={selectedClubs}
        setSelectedClubs={setSelectedClubs}

        isAnyFilterExpandedToggle={isAnyFilterExpandedToggle}
        setIsAnyFilterExpandedToggle={setIsAnyFilterExpandedToggle}

        handleFilterUnexpansion={handleFilterUnexpansion}
        handleSearchReset={handleSearchReset}
      />

      <div
        className='scouting-view-result-container player-finding-view-result-container'
        style={{ top: 85 }}
      >
        {tableData.length > 0 && (
          <PlayerTable
            data={tableData}
            isLoading={isLoading}
            handleSearchButtonPressed={handleSearchButtonPressed}
            currentModuloPage={currentModuloPage}
            currentChapter={currentChapter}
            totalHits={totalHits}
            hitsPerPage={hitsPerPage}
            handleChangeCurrentChapter={handleChangeCurrentChapter}

            headerBackgroundColor={'#222530'}
            isFindPlayerView={true}
            handlePlayerClick={handlePlayerClick}
          />
        )}

        {tableData.length === 0 && isEmptySearchResult && !isLoading && (
          <div className='scouting-info-title fade-in'>
            {userConfig ? staticLanguageMap['noResult'][userConfig.language] : ''}
          </div>
        )}

        {tableData.length === 0 && !isEmptySearchResult && !isLoading && (
          <div className='scouting-info-button player-finding-info-button' onClick={() => handleSearchButtonPressed(true, true)}>
            <div className='scouting-info-button-text'>
              {userConfig ? staticLanguageMap['searchForPlayers'][userConfig.language] : ''}
            </div>
            <div className='scouting-info-button-icon'>
              <SearchIcon style={{ fontSize: 24, marginLeft: 20, marginTop: 8 }} />
            </div>
          </div>
        )}

      </div>
    </div>
  );
};
