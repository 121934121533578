import './platform.css';
import './mobile/mobilePlatform.css';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { userConfigState } from './recoil/atoms/userConfigState';
import { teamsState } from './recoil/atoms/teamsState';
import { squadsState } from './recoil/atoms/squadsState';
import { playersState } from './recoil/atoms/playersState';
import { clubScopesState } from './recoil/atoms/clubScopesState';
import { clubSettingsState } from './recoil/atoms/clubSettingsState';
import { userSettingsState } from './recoil/atoms/userSettingsState';
import { playerOverviewsState } from './recoil/atoms/playerOverviewsState';
import { navigationPropsState } from './recoil/atoms/navigationPropsState';
import { globalModalPropsState } from './recoil/atoms/globalModalPropsState';
import { competitionsState } from './recoil/atoms/competitionsState';
import { scoutingConfigState } from './recoil/atoms/scoutingConfigState';

import { getAllUsersWithSameClub, getUserConfig } from './services/firestore/users';

import { AuthContextType, useAuthContext } from '../common/contexts/AuthContext';
import { useWindowSize } from '../common/hooks/WindowSize';

import { Navigation, getNavigationWidth } from './components/navigation/Navigation';
import { SingleTeam } from './views/singleTeam/SingleTeam';
import { TeamOrSquadBuilder } from './views/teamOrSquadBuilder/TeamOrSquadBuilder';
import { OwnTeam } from './views/ownTeam/OwnTeam';
import { getTeamsOrSquads } from './services/firestore/teamsOrSquads';
import { Scouting } from './views/scouting/Scouting';
import { ComingSoon } from './views/comingSoon/ComingSoon';
import { Settings } from './views/settings/Settings';
import { allUserConfigsState } from './recoil/atoms/allUserConfigsState';
import { Archive } from './views/archive/Archive';
import { Home } from './views/home/Home';
import { getClubScopes } from './services/firestore/clubScopes';
import { Dialog } from '@mui/material';
import { getPlayers } from './services/firestore/players';
import { Economy } from './views/economy/Economy';
import { userHasMaintenanceAccess, userHasNoAccess } from './utils/userUtils';
import { Account } from './views/settings/Account';
import { ConfirmModal } from './components/modals/globalModals/ConfirmModal';
import { PdfModal } from './components/modals/globalModals/PdfModal';
import { TextModal } from './components/modals/globalModals/TextModal';
import { getGlobalSettings } from './services/firestore/global';
import { JsonMapping } from './types';
import { staticLanguageMap } from '../common/static/staticLanguageMap';
import { getCompetitions } from './services/firestore/competitions';
import { getPlayerOverviewsOnTeams } from './services/fokusServer/playerOverviews';
import { getClubSettings } from './services/firestore/clubSettings';
import { getUserSettings } from './services/firestore/userSettings';
import { getScoutingConfig } from './services/firestore/scouting';
import { PlayerViewModal } from './components/modals/globalModals/PlayerViewModal';
// import { Planner } from './views/planner/Planner';

// import { MobileNavigation } from './mobile/navigation/MobileNavigation';
// import { MobileScouting } from './mobile/views/MobileScouting';

export const Platform = () => {

  const { currentUser } = useAuthContext() as AuthContextType;

  const { width } = useWindowSize();

  const navigate = useNavigate();

  const [navigationProps, setNavigationProps] = useRecoilState(navigationPropsState);


  // Initialize firestore listener for userConfig
  const [userConfig, setUserConfig] = useRecoilState(userConfigState);
  useEffect(() => {
    if (!currentUser) {
      return;
    }

    const unsubscribe = getUserConfig(currentUser, setUserConfig);

    return () => unsubscribe();
  }, [currentUser]); // eslint-disable-line react-hooks/exhaustive-deps


  // Listener logic for user access - if access changes, we must reset the navigation to avoid unauthorized access
  // // devspeed: this could be commented out to speed up development
  useEffect(() => {
    setNavigationProps({ activeTab: 'home' });
  }, [userConfig?.access]); // eslint-disable-line react-hooks/exhaustive-deps


  // Initialize firestore listeners once userConfig is initialized
  const [teams, setTeams] = useRecoilState(teamsState);
  const [squads, setSquads] = useRecoilState(squadsState);
  const setPlayers = useSetRecoilState(playersState);
  const setClubScopes = useSetRecoilState(clubScopesState);
  const setClubSettings = useSetRecoilState(clubSettingsState);
  const setUserSettings = useSetRecoilState(userSettingsState);
  const setAllUsers = useSetRecoilState(allUserConfigsState);
  const setCompetitions = useSetRecoilState(competitionsState);
  const setScoutingConfig = useSetRecoilState(scoutingConfigState);
  useEffect(() => {
    if (!userConfig?.club || !userConfig?.email) return;

    // Firestore listener for teams
    const unsubscribeFromTeams = getTeamsOrSquads(setTeams, false, userConfig.club);

    // Firestore listener for squads
    const unsubscribeFromSquads = getTeamsOrSquads(setSquads, true, userConfig.club);

    // Firestore listener for players
    const unsubscribeFromPlayers = getPlayers(setPlayers, userConfig.club);

    // Firestore listener for clubScopes
    const unsubscribeFromClubScopes = getClubScopes(setClubScopes, userConfig.club);

    // Firestore listener for clubSettings
    const unsubscribeFromClubSettings = getClubSettings(setClubSettings, userConfig.club);

    // Firestore listener for userSettings
    const unsubscribeFromUserSettings = getUserSettings(setUserSettings, userConfig.email, userConfig.club);

    // Firestore listener for all users with the same club
    const unsubscribeFromAllUsers = getAllUsersWithSameClub(setAllUsers, userConfig.club);

    // Firestore listener for competitions
    const unsubscribeFromCompetitions = getCompetitions(setCompetitions);

    // Firestore listener for scoutingConfig
    const unsubscribeFromScoutingConfig = getScoutingConfig(setScoutingConfig, userConfig.email, userConfig.club);

    // Clean up subscriptions when component unmounts
    return () => {
      unsubscribeFromTeams();
      unsubscribeFromSquads();
      unsubscribeFromPlayers();
      unsubscribeFromClubScopes();
      unsubscribeFromClubSettings();
      unsubscribeFromUserSettings();
      unsubscribeFromAllUsers();
      unsubscribeFromCompetitions();
      unsubscribeFromScoutingConfig();
    };
  }, [userConfig?.club, userConfig?.email]); // eslint-disable-line react-hooks/exhaustive-deps


  // Initialize playerOverviews once teams and squads are initialized
  const setPlayerOverviewsState = useSetRecoilState(playerOverviewsState);
  const [isPlayerOverviewsInitialized, setIsPlayerOverviewsInitialized] = useState(false);
  useEffect(() => {
    if (teams && squads && !isPlayerOverviewsInitialized) {
      getPlayerOverviewsOnTeams(teams, squads, currentUser).then(playerOverviews => {
        setPlayerOverviewsState(playerOverviews);
      });
      setIsPlayerOverviewsInitialized(true);
    }
  }, [teams, squads, currentUser]); // eslint-disable-line react-hooks/exhaustive-deps


  // Firestore listener for react version updates
  const [currentReactVersion, setCurrentReactVersion] = useState<string | undefined>(undefined);
  const [latestReactVersion, setLatestReactVersion] = useState<string | undefined>(undefined);
  const [versionMessage, setVersionMessage] = useState<JsonMapping | undefined>(undefined);
  const [isMaintenance, setIsMaintenance] = useState(false);
  const [maintenanceMessage, setMaintenanceMessage] = useState<JsonMapping | undefined>(undefined);
  const [isUpdateAvailable, setIsUpdateAvailable] = useState(false);
  const [isUpdateAvailableModalOpen, setIsUpdateAvailableModalOpen] = useState(false);

  useEffect(() => {
    const unsubscribe = getGlobalSettings(setLatestReactVersion, setVersionMessage, setIsMaintenance, setMaintenanceMessage);
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (!latestReactVersion) return;

    if (!currentReactVersion) {
      setCurrentReactVersion(latestReactVersion);
    }

    else if (currentReactVersion !== latestReactVersion) {
      setIsUpdateAvailable(true);
    }
  }, [latestReactVersion]); // eslint-disable-line react-hooks/exhaustive-deps

  // Periodically prompt the user to refresh if an update is waiting
  useEffect(() => {
    if (isUpdateAvailable) {
      setIsUpdateAvailableModalOpen(true);
    }

    const intervalId = setInterval(() => {
      if (isUpdateAvailable) {
        setIsUpdateAvailableModalOpen(true);
      }
    }, 900000); // 15 minutes

    return () => clearInterval(intervalId);
  }, [isUpdateAvailable]);

  const applyUpdate = () => {
    setIsUpdateAvailableModalOpen(false);
    setIsUpdateAvailable(false);
    window.location.reload();
  };


  // Global modals
  const globalModalProps = useRecoilValue(globalModalPropsState);
  const [isGlobalConfirmModalOpen, setIsGlobalConfirmModalOpen] = useState(false);
  const [isGlobalTextModalOpen, setIsGlobalTextModalOpen] = useState(false);
  const [isGlobalPdfModalOpen, setIsGlobalPdfModalOpen] = useState(false);
  const [isGlobalPlayerViewModalOpen, setIsGlobalPlayerViewModalOpen] = useState(false);
  useEffect(() => {
    if (globalModalProps !== undefined) {
      if (globalModalProps.globalModalType === 'ConfirmModal') {
        if (globalModalProps.delay !== undefined) {
          const timer = setTimeout(() => {
            setIsGlobalConfirmModalOpen(true);
          }, globalModalProps.delay);

          return () => clearTimeout(timer);
        }

        else {
          setIsGlobalConfirmModalOpen(true);
        }
      }

      else if (globalModalProps.globalModalType === 'TextModal') {
        setIsGlobalTextModalOpen(true);
      }

      else if (globalModalProps.globalModalType === 'PdfModal') {
        setIsGlobalPdfModalOpen(true);
      }

      else if (globalModalProps.globalModalType === 'PlayerViewModal') {
        setIsGlobalPlayerViewModalOpen(true);
      }
    }
    else {
      setIsGlobalConfirmModalOpen(false);
      setIsGlobalPdfModalOpen(false);
      setIsGlobalTextModalOpen(false);
      setIsGlobalPlayerViewModalOpen(false);
    }
  }, [globalModalProps]);


  if (!currentUser) {
    navigate('/');
    return (<div></div>);
  }


  else if (userHasNoAccess(userConfig)) {
    return (<Account noAccessView={true} />);
  }


  else if (userConfig && isMaintenance && !userHasMaintenanceAccess(userConfig)) {
    return (
      <Account
        maintenanceMessage={maintenanceMessage && maintenanceMessage[userConfig.language]
          ? maintenanceMessage[userConfig.language]
          : staticLanguageMap['genericMaintenanceInfo'][userConfig.language]}
      />
    );
  }


  else if (userConfig && width < 875) {
    return (
      <div>
        <Dialog
          open={isUpdateAvailableModalOpen}
          onClose={() => setIsUpdateAvailableModalOpen(false)}
          PaperProps={{ sx: { borderRadius: '0px' } }}
        >
          <ConfirmModal
            confirm={applyUpdate}
            cancel={() => setIsUpdateAvailableModalOpen(false)}
            confirmTitleKey={'updateAvailable'}
            confirmInfo={
              versionMessage && versionMessage[userConfig.language]
                ? versionMessage[userConfig.language]
                : staticLanguageMap['updateAvailableInfo'][userConfig.language]
            }
            confirmKey={'refresh'}
            cancelKey={'postpone'}
          />
        </Dialog>

        {/* <MobileNavigation activeTab={activeTab} setActiveTab={setActiveTab} /> */}

        <div className='mobile-platform-view-container'>

          {/* {activeTab === 'home' && <MobileScouting />} */}

          <ComingSoon />

        </div>
      </div>
    );
  }

  else if (userConfig) {
    return (
      <div>

        <Dialog
          open={isUpdateAvailableModalOpen}
          onClose={() => setIsUpdateAvailableModalOpen(false)}
          PaperProps={{ sx: { borderRadius: '0px' } }}
        >
          <ConfirmModal
            confirm={applyUpdate}
            cancel={() => setIsUpdateAvailableModalOpen(false)}
            confirmTitleKey={'updateAvailable'}
            confirmInfo={
              versionMessage && versionMessage[userConfig.language]
                ? versionMessage[userConfig.language]
                : staticLanguageMap['updateAvailableInfo'][userConfig.language]
            }
            confirmKey={'refresh'}
            cancelKey={'postpone'}
          />
        </Dialog>

        {globalModalProps !== undefined && globalModalProps.confirm !== undefined && globalModalProps.confirmTitleKey !== undefined && (
          <Dialog
            open={isGlobalConfirmModalOpen}
            onClose={() => globalModalProps.closeGlobalModal()}
            PaperProps={{ sx: { borderRadius: '0px' } }}
          >
            <ConfirmModal
              confirm={globalModalProps.confirm}
              cancel={() => globalModalProps.closeGlobalModal()}
              confirmTitleKey={globalModalProps.confirmTitleKey}
              confirmInfoKey={globalModalProps.confirmInfoKey}
              confirmKey={globalModalProps.confirmKey}
              cancelKey={globalModalProps.cancelKey}
            />
          </Dialog>
        )}

        {globalModalProps !== undefined && globalModalProps.text !== undefined && (
          <Dialog
            open={isGlobalTextModalOpen}
            onClose={() => globalModalProps.closeGlobalModal()}
            PaperProps={{ sx: { borderRadius: '0px' } }}
          >
            <TextModal
              text={globalModalProps.text}
              closeModal={globalModalProps.closeGlobalModal}
              width={globalModalProps.width}
              fontSize={globalModalProps.fontSize}
            />
          </Dialog>
        )}

        {globalModalProps !== undefined && isGlobalPdfModalOpen && globalModalProps.fileUrl !== undefined && globalModalProps.fileName !== undefined && (
          <PdfModal
            fileUrl={globalModalProps.fileUrl}
            fileName={globalModalProps.fileName}
            closeModal={globalModalProps.closeGlobalModal}
          />
        )}

        {globalModalProps !== undefined && isGlobalPlayerViewModalOpen && globalModalProps.player && (
          <Dialog
            open={isGlobalPlayerViewModalOpen}
            onClose={() => globalModalProps.closeGlobalModal(false)}
            PaperProps={{ sx: { borderRadius: '0px' } }}>
            <PlayerViewModal
              player={globalModalProps.player}
              closeModal={globalModalProps.closeGlobalModal}
              squadId={globalModalProps.squadId}
              squadName={globalModalProps.squadName}
              showPlayerNameInTitle={globalModalProps.showPlayerNameInTitle}
            />
          </Dialog>
        )}

        <Navigation />

        <div className='platform-view-container' style={{ left: getNavigationWidth(userConfig) }}>

          {navigationProps.activeTab === 'home' && <Home />}

          {/* -------------------------- */}

          {navigationProps.activeTab === 'masterTeam' && <SingleTeam teamId={'masterTeam'} />}

          {navigationProps.activeTab === 'shadowTeam' && <SingleTeam teamId={'shadowTeam'} />}

          {navigationProps.activeTab === 'scoutTeams' && <TeamOrSquadBuilder isSquad={false} />}

          {/* -------------------------- */}

          {navigationProps.activeTab === 'scouting' && <Scouting />}

          {navigationProps.activeTab === 'archive' && <Archive />}

          {/* -------------------------- */}

          {navigationProps.activeTab === 'ownTeam' && <OwnTeam />}

          {navigationProps.activeTab === 'squadBuilder' && <TeamOrSquadBuilder isSquad={true} />}

          {/* {navigationProps.activeTab === 'planner' && <Planner />} */}

          {navigationProps.activeTab === 'economy' && <Economy />}

          {/* -------------------------- */}

          {navigationProps.activeTab === 'settings' && <Settings />}

        </div>

      </div>
    );
  }

  else {
    return (
      // loading screen?
      // <div className='platform-loading-screen'>
      //   <div className='platform-loading-spinner'>&nbsp;</div>
      // </div>
      <div></div>
    );
  }
};
