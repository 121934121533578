import '../tables.css';

import { useEffect, useRef, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { selectedPlayerState } from '../../../recoil/atoms/selectedPlayerState';
import { userSettingsState } from '../../../recoil/atoms/userSettingsState';

import { useMemo } from 'react';
import { useTable, useBlockLayout } from 'react-table';

import { getEmptyTableWidth, getPlayerSimpleColumns } from './playerSimpleColumns';
import { FlexibleJsonMapping, PlayerId } from '../../../types';
import { getClubColor, getClubLightColor } from '../../../utils/configUtils';
import { debounce } from '../../../utils/utils';


interface PlayerSimpleTableProps {
  data: FlexibleJsonMapping[];
  tableType: string;

  positionKey?: string;
  draggedPlayer?: FlexibleJsonMapping | undefined;
  setDraggedPlayer?: (player: FlexibleJsonMapping | undefined) => void;
  handlePlayerDrop?: (newPosition: string) => void; // if provided, this table is a valid drop zone

  maxHeight?: string; // if provided, scroll will be enabled
  scrollBarColor?: string;

  isLoading?: boolean;
  handleSearchForPlayers?: (isUserInitiated: boolean, isNewSearch: boolean) => Promise<void>;
  currentPage?: number;
  totalHits?: number;

  onPlayerClick?: (player: FlexibleJsonMapping) => void;
  disablePlayersWithoutEventData?: boolean;
  isGoalkeeper?: boolean; // if provided -> if true, players who are not goalkeepers will be disabled, and vice versa

  highlightedPlayerId?: PlayerId | undefined;
}

export const PlayerSimpleTable: React.FC<PlayerSimpleTableProps> = ({
  data,
  tableType, // team, ownTeam, academyTeam, bench, addPlayerView, addPlayerSimpleViewTeamIcon, addPlayerSimpleViewRoleIcon, archiveReportInfo

  positionKey,
  draggedPlayer,
  setDraggedPlayer,
  handlePlayerDrop,

  maxHeight,
  scrollBarColor,

  isLoading,
  handleSearchForPlayers,
  currentPage,
  totalHits,

  onPlayerClick,
  disablePlayersWithoutEventData,
  isGoalkeeper,

  highlightedPlayerId,
}) => {

  const userConfig = useRecoilValue(userConfigState);
  const setSelectedPlayer = useSetRecoilState(selectedPlayerState);

  const userSettings = useRecoilValue(userSettingsState);

  const [tableHasOverflow, setTableHasOverflow] = useState(false);
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const clubColor = getClubColor(userConfig?.club ?? '');
  const clubLightColor = getClubLightColor(userConfig?.club ?? '');


  const handleOnPlayerClick = (player: FlexibleJsonMapping) => {
    if (disablePlayersWithoutEventData && !player['event_data_available']) return;
    if (isGoalkeeper !== undefined && (isGoalkeeper === (player['primary_position'] !== 'GK'))) return;

    if (onPlayerClick) {
      onPlayerClick(player);
    }
    else {
      setSelectedPlayer({ id: player['id'], fullname: player['fullname'] });
    }
  };


  const teamMenu = useMemo(() => {
    return (
      tableType === 'ownTeam'
        ? userSettings?.ownTeamMenu
        : tableType === 'academyTeam'
          ? userSettings?.academyTeamMenu
          : userSettings?.teamMenu
    ) ?? {};
  }, [tableType, userSettings?.ownTeamMenu, userSettings?.academyTeamMenu, userSettings?.teamMenu]);


  const columns = useMemo(
    () => getPlayerSimpleColumns(
      clubLightColor,
      tableType,
      teamMenu,
      disablePlayersWithoutEventData,
      isGoalkeeper
    ),
    [clubLightColor, tableType, teamMenu, disablePlayersWithoutEventData, isGoalkeeper]
  );

  const {
    getTableProps,
    getTableBodyProps,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useBlockLayout,
  );

  const [isDraggedOver, setIsDraggedOver] = useState(false);

  const handleDragStart = (player: FlexibleJsonMapping) => {
    if (positionKey && setDraggedPlayer) {
      setDraggedPlayer({
        ...player,
        'positionKey': positionKey,
      });
    }
  };

  const handleDragEnd = () => {
    if (setDraggedPlayer) {
      setDraggedPlayer(undefined);
    }
    setIsDraggedOver(false);
  };

  const handleDragDrop = (event: React.DragEvent<HTMLDivElement>) => {
    if (handlePlayerDrop) {
      event.preventDefault();
      if (positionKey) {
        handlePlayerDrop(positionKey);
      }
      setIsDraggedOver(false);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    if (handlePlayerDrop) {
      event.preventDefault();
    }
  };

  const handleDragEnter = () => {
    setIsDraggedOver(true);
  };

  const handleDragLeave = () => {
    setIsDraggedOver(false);
  };


  const getBoxShadow = () => {
    if (draggedPlayer && draggedPlayer['positionKey'] !== positionKey && handlePlayerDrop) {
      if (isDraggedOver) {
        return '0px 0px 0px 3px ' + clubColor;
      }
      return '0px 0px 0px 3px #cdd0e773';
    }
    return 'none';
  };


  const checkScrollPosition = async () => {
    if (!tableContainerRef.current || !handleSearchForPlayers || currentPage === undefined || totalHits === undefined) return;

    const { scrollTop, scrollHeight, clientHeight } = tableContainerRef.current;
    const scrollPosition = scrollTop / (scrollHeight - clientHeight);
    const threshold = 0.4 + currentPage * 0.1;

    if (scrollPosition > threshold && !isLoading && currentPage < 4 && data.length < totalHits) {
      await handleSearchForPlayers(true, false);
    }
  };


  useEffect(() => {
    const [handleScroll, cancelHandleScroll] = debounce(() => checkScrollPosition(), 100);

    const tableContainer = tableContainerRef.current;
    if (tableContainer) {
      tableContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (tableContainer) {
        tableContainer.removeEventListener('scroll', handleScroll);
      }
      cancelHandleScroll();
    };
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    const checkOverflow = () => {
      const element = tableContainerRef.current;
      const isOverflowing = element !== null && element.scrollHeight > element.clientHeight;
      setTableHasOverflow(isOverflowing);
    };

    checkOverflow();

    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, [data]);


  document.documentElement.style.setProperty('--scroll-bar-color', scrollBarColor ?? '#919dcdc7');


  return (
    <div>
      {data && data.length > 0 && (
        <div className={'player-simple-table-container' + (!maxHeight ? ' player-simple-table-container-with-shadow' : '')}>

          {draggedPlayer && draggedPlayer['positionKey'] !== positionKey && (
            <div
              className='player-simple-table-overlay'
              onDragOver={handleDragOver}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDrop={handleDragDrop}>
              &nbsp;
            </div>
          )}

          <div
            {...getTableProps()}
            ref={tableContainerRef}
            className={'player-simple-table' + (tableHasOverflow ? ' player-simple-table-overflowed' : '')}
            style={{ maxHeight: maxHeight, boxShadow: getBoxShadow() }}>

            <div
              {...getTableBodyProps()}
              className='player-simple-table-body'>
              {rows.map((row) => {
                prepareRow(row);
                const { key, ...restRowProps } = row.getRowProps();
                return (
                  <div
                    key={key}
                    {...restRowProps}
                    className={
                      (
                        (disablePlayersWithoutEventData && !row.original['event_data_available']) ||
                        (isGoalkeeper !== undefined && (isGoalkeeper === (row.original['primary_position'] !== 'GK')))
                      )
                        ? 'player-simple-table-row-disabled'
                        : row.original.id === highlightedPlayerId
                          ? 'player-simple-table-row-highlighted'
                          : 'player-simple-table-row'
                    }
                    onClick={() => handleOnPlayerClick(row.original)}
                    draggable={positionKey !== undefined && setDraggedPlayer !== undefined}
                    onDragStart={() => handleDragStart(row.original)}
                    onDragEnd={() => handleDragEnd()}
                  >
                    {row.cells.map(cell => {
                      const { key: cellKey, ...restCellProps } = cell.getCellProps();
                      return (
                        <div
                          key={cellKey}
                          {...restCellProps}
                        >
                          {cell.render('Cell')}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>

        </div>
      )}

      {data && data.length === 0 && positionKey !== 'GK' && !draggedPlayer && (
        <div className='player-simple-table-empty-container'>
          &nbsp;
        </div>
      )}

      {data
        && data.length === 0
        && draggedPlayer
        && (
          positionKey !== 'GK'
          || (draggedPlayer['primary_position'] === 'GK'
            || draggedPlayer['primary_position'] === 'Goalkeeper'
            || (!draggedPlayer['primary_position'] && draggedPlayer['positionKey'].startsWith('addPlayerTable'))
          )
        )
        && (
          <div
            className={'player-simple-table-empty-container player-simple-table-empty-container-drag-active'}
            style={{
              boxShadow: isDraggedOver ? '0px 0px 0px 3px ' + clubColor : 'none',
              width: getEmptyTableWidth(tableType, teamMenu)
            }}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDragDrop}
          >
            &nbsp;
          </div>
        )}

    </div>
  );
};
