import { db } from '../../../firebase';

import {
  doc,
  setDoc,
  arrayUnion,
  getDoc,
  updateDoc,
  onSnapshot,
} from 'firebase/firestore';

import {
  Alert,
  FlexibleJsonMapping,
  NumberToJsonMapping,
  PlayerOverview,
  ScoutingConfig,
  UserConfig
} from '../../types';
// import { playerMatchesFilters } from '../../utils/playerUtils';


// Get user scouting data
export const getScoutingConfig = (
  setScoutingConfig: (scoutingConfig: ScoutingConfig) => void,
  userEmail: string,
  club: string
) => {

  const scoutingConfigDocRef = doc(db, 'configs', club, 'scouting', userEmail);

  const unsubscribe = onSnapshot(scoutingConfigDocRef, (doc) => {
    if (doc.exists()) {
      const clubSettingsObject = doc.data();

      const scoutingConfig: ScoutingConfig = {
        savedSearches: clubSettingsObject.savedSearches ?? [],
        alerts: clubSettingsObject.alerts ?? [],
        playerIdsToExcludeFromAllAlerts: clubSettingsObject.playerIdsToExcludeFromAllAlerts ?? {},
      };

      setScoutingConfig(scoutingConfig);
    }
  });

  return unsubscribe;
};


// Save a new search
export const saveUserSearch = async (
  search: FlexibleJsonMapping,
  userEmail: string,
  club: string
) => {

  const scoutingConfigDocRef = doc(db, 'configs', club, 'scouting', userEmail);

  try {
    await setDoc(scoutingConfigDocRef, {
      savedSearches: arrayUnion(search)
    }, { merge: true });
  }
  catch (error) {
    console.error(error); // eslint-disable-line no-console
  }
};


// Delete a saved search
export const deleteSearch = async (
  searchIndex: number,
  userEmail: string,
  club: string
) => {

  const scoutingConfigDocRef = doc(db, 'configs', club, 'scouting', userEmail);

  const docSnap = await getDoc(scoutingConfigDocRef);

  if (docSnap.exists()) {
    const data = docSnap.data();

    if (Array.isArray(data.savedSearches)) {
      data.savedSearches.splice(searchIndex, 1);

      try {
        await updateDoc(scoutingConfigDocRef, {
          savedSearches: data.savedSearches
        });
      }
      catch (error) {
        console.error(error); // eslint-disable-line no-console
      }
    }
  }
};


// this function may be called by frontend
// run a given alert and populate the playerIdsToAlert field
const getPlayerIdsToAlert = async (
  filters: FlexibleJsonMapping, // eslint-disable-line @typescript-eslint/no-unused-vars
  playerIdsToExclude: NumberToJsonMapping, // eslint-disable-line @typescript-eslint/no-unused-vars
): Promise<NumberToJsonMapping> => {

  // add a mocked delay to simulate a real-world scenario
  await new Promise(resolve => setTimeout(resolve, 3000));

  // todo: call the server with alert.filters and filter out players in allExcludedPlayerIds
  return {
    16437: { dateAdded: '2022-01-01T00:00:00.000Z' },
    16438: { dateAdded: '2022-01-01T00:00:00.000Z' },
    16439: { dateAdded: '2022-01-01T00:00:00.000Z' },
    8765: { dateAdded: '2022-01-01T00:00:00.000Z' },
    8766: { dateAdded: '2022-01-01T00:00:00.000Z' },
    8767: { dateAdded: '2022-01-01T00:00:00.000Z' },
    11500: { dateAdded: '2022-01-01T00:00:00.000Z' },
    11501: { dateAdded: '2022-01-01T00:00:00.000Z' },
    11502: { dateAdded: '2022-01-01T00:00:00.000Z' },
    37612: { dateAdded: '2022-01-01T00:00:00.000Z' },
    37613: { dateAdded: '2022-01-01T00:00:00.000Z' },
    37614: { dateAdded: '2022-01-01T00:00:00.000Z' },
    37615: { dateAdded: '2022-01-01T00:00:00.000Z' },
    37616: { dateAdded: '2022-01-01T00:00:00.000Z' },
    37617: { dateAdded: '2022-01-01T00:00:00.000Z' },
    37618: { dateAdded: '2022-01-01T00:00:00.000Z' },
    37619: { dateAdded: '2022-01-01T00:00:00.000Z' },
    50000: { dateAdded: '2022-01-01T00:00:00.000Z' },
    50001: { dateAdded: '2022-01-01T00:00:00.000Z' },
    50002: { dateAdded: '2022-01-01T00:00:00.000Z' },
    50003: { dateAdded: '2022-01-01T00:00:00.000Z' },
    50004: { dateAdded: '2022-01-01T00:00:00.000Z' },
    50005: { dateAdded: '2022-01-01T00:00:00.000Z' },
    50006: { dateAdded: '2022-01-01T00:00:00.000Z' },
    50007: { dateAdded: '2022-01-01T00:00:00.000Z' },
    50008: { dateAdded: '2022-01-01T00:00:00.000Z' },
    50009: { dateAdded: '2022-01-01T00:00:00.000Z' },
    50010: { dateAdded: '2022-01-01T00:00:00.000Z' },
    60000: { dateAdded: '2022-01-01T00:00:00.000Z' },
    60001: { dateAdded: '2022-01-01T00:00:00.000Z' },
    60002: { dateAdded: '2022-01-01T00:00:00.000Z' },
    60003: { dateAdded: '2022-01-01T00:00:00.000Z' },
    60004: { dateAdded: '2022-01-01T00:00:00.000Z' },
    60005: { dateAdded: '2022-01-01T00:00:00.000Z' },
    60006: { dateAdded: '2022-01-01T00:00:00.000Z' },
    60007: { dateAdded: '2022-01-01T00:00:00.000Z' },
    60008: { dateAdded: '2022-01-01T00:00:00.000Z' },
    60009: { dateAdded: '2022-01-01T00:00:00.000Z' },
    12356: { dateAdded: '2022-01-01T00:00:00.000Z' },
    65231: { dateAdded: '2022-01-01T00:00:00.000Z' },
    10987: { dateAdded: '2022-01-01T00:00:00.000Z' },
    12345: { dateAdded: '2022-01-01T00:00:00.000Z' },
    12346: { dateAdded: '2022-01-01T00:00:00.000Z' },
    12347: { dateAdded: '2022-01-01T00:00:00.000Z' },
    96356: { dateAdded: '2022-01-01T00:00:00.000Z' },
    88563: { dateAdded: '2022-01-01T00:00:00.000Z' },
    25678: { dateAdded: '2022-01-01T00:00:00.000Z' },
    22345: { dateAdded: '2022-01-01T00:00:00.000Z' },
    120: { dateAdded: '2022-01-01T00:00:00.000Z' },
    121: { dateAdded: '2022-01-01T00:00:00.000Z' },
    122: { dateAdded: '2022-01-01T00:00:00.000Z' },
    123: { dateAdded: '2022-01-01T00:00:00.000Z' },
    886: { dateAdded: '2022-01-01T00:00:00.000Z' },
    887: { dateAdded: '2022-01-01T00:00:00.000Z' },
    888: { dateAdded: '2022-01-01T00:00:00.000Z' },
    889: { dateAdded: '2022-01-01T00:00:00.000Z' },
    890: { dateAdded: '2022-01-01T00:00:00.000Z' },
    891: { dateAdded: '2022-01-01T00:00:00.000Z' },
    892: { dateAdded: '2022-01-01T00:00:00.000Z' },
    893: { dateAdded: '2022-01-01T00:00:00.000Z' },
    894: { dateAdded: '2022-01-01T00:00:00.000Z' },
    895: { dateAdded: '2022-01-01T00:00:00.000Z' },
    85: { dateAdded: '2022-01-01T00:00:00.000Z' },
    86: { dateAdded: '2022-01-01T00:00:00.000Z' },
    87: { dateAdded: '2022-01-01T00:00:00.000Z' },
    88: { dateAdded: '2022-01-01T00:00:00.000Z' },
    15: { dateAdded: '2022-01-01T00:00:00.000Z' },
    16: { dateAdded: '2022-01-01T00:00:00.000Z' },
    17: { dateAdded: '2022-01-01T00:00:00.000Z' },
    18: { dateAdded: '2022-01-01T00:00:00.000Z' },
    19: { dateAdded: '2022-01-01T00:00:00.000Z' },
    20: { dateAdded: '2022-01-01T00:00:00.000Z' },
    21: { dateAdded: '2022-01-01T00:00:00.000Z' },
    47876: { dateAdded: '2023-03-21T14:15:00.000Z' },
    12083: { dateAdded: '2023-03-22T09:30:00.000Z' },
    665: { dateAdded: '2023-03-23T16:45:00.000Z' },
    19872: { dateAdded: '2023-03-24T11:20:00.000Z' },
    34980: { dateAdded: '2023-03-25T10:05:00.000Z' },
    44276: { dateAdded: '2023-03-26T12:35:00.000Z' },
    8790: { dateAdded: '2023-03-27T08:50:00.000Z' },
    37648: { dateAdded: '2023-03-28T14:10:00.000Z' },
    20788: { dateAdded: '2023-03-29T09:55:00.000Z' },
    13678: { dateAdded: '2023-03-30T13:25:00.000Z' },
    49372: { dateAdded: '2023-04-01T10:00:00.000Z' },
    15964: { dateAdded: '2023-04-02T17:30:00.000Z' },
    28476: { dateAdded: '2023-04-03T08:15:00.000Z' },
    4444: { dateAdded: '2023-04-04T14:45:00.000Z' },
    39872: { dateAdded: '2023-04-05T11:05:00.000Z' },
    10423: { dateAdded: '2023-04-06T09:30:00.000Z' },
    23987: { dateAdded: '2023-04-07T15:50:00.000Z' },
    48123: { dateAdded: '2023-04-08T07:40:00.000Z' },
    17234: { dateAdded: '2023-04-09T16:20:00.000Z' },
    31567: { dateAdded: '2023-04-10T11:45:00.000Z' },
    876: { dateAdded: '2023-04-11T13:35:00.000Z' },
    48654: { dateAdded: '2023-04-12T09:10:00.000Z' },
    21478: { dateAdded: '2023-04-13T14:25:00.000Z' },
    33789: { dateAdded: '2023-04-14T08:50:00.000Z' },
    49712: { dateAdded: '2023-04-15T12:15:00.000Z' },
    24567: { dateAdded: '2023-04-16T07:30:00.000Z' },
    18972: { dateAdded: '2023-04-17T14:55:00.000Z' },
    31674: { dateAdded: '2023-04-18T11:40:00.000Z' },
    47654: { dateAdded: '2023-04-19T16:10:00.000Z' },
    12398: { dateAdded: '2023-04-20T10:05:00.000Z' },
    38974: { dateAdded: '2023-04-21T09:20:00.000Z' },
    45987: { dateAdded: '2023-04-22T15:45:00.000Z' },
    12765: { dateAdded: '2023-04-23T13:25:00.000Z' },
    32789: { dateAdded: '2023-04-24T11:10:00.000Z' },
    48267: { dateAdded: '2023-04-25T07:15:00.000Z' },
    19567: { dateAdded: '2023-04-26T16:40:00.000Z' },
    31789: { dateAdded: '2023-04-27T08:55:00.000Z' },
    48712: { dateAdded: '2023-04-28T14:30:00.000Z' },
    12876: { dateAdded: '2023-04-29T10:45:00.000Z' },
    39812: { dateAdded: '2023-04-30T07:05:00.000Z' },
    20123: { dateAdded: '2023-05-01T13:55:00.000Z' },
    48976: { dateAdded: '2023-05-02T09:20:00.000Z' },
    17892: { dateAdded: '2023-05-03T11:30:00.000Z' },
    36218: { dateAdded: '2023-05-04T15:10:00.000Z' },
    21167: { dateAdded: '2023-05-05T08:45:00.000Z' },
    49238: { dateAdded: '2023-05-06T13:05:00.000Z' },
    18764: { dateAdded: '2023-05-07T10:30:00.000Z' },
    32567: { dateAdded: '2023-05-08T07:50:00.000Z' },
    46578: { dateAdded: '2023-05-09T16:20:00.000Z' },
    13897: { dateAdded: '2023-05-10T09:10:00.000Z' },
    38765: { dateAdded: '2023-05-11T12:35:00.000Z' },
    48923: { dateAdded: '2023-05-12T11:20:00.000Z' },
    21789: { dateAdded: '2023-05-13T08:55:00.000Z' },
    39782: { dateAdded: '2023-05-14T14:05:00.000Z' },
    48912: { dateAdded: '2023-05-15T10:50:00.000Z' },
    29387: { dateAdded: '2023-05-16T09:15:00.000Z' },
    47281: { dateAdded: '2023-05-17T07:30:00.000Z' },
    15789: { dateAdded: '2023-05-18T16:35:00.000Z' },
    21456: { dateAdded: '2023-05-19T10:40:00.000Z' },
    47891: { dateAdded: '2023-05-20T11:55:00.000Z' },
    14678: { dateAdded: '2023-05-21T08:20:00.000Z' },
    37894: { dateAdded: '2023-05-22T15:30:00.000Z' },
    48679: { dateAdded: '2023-05-23T09:45:00.000Z' },
    21876: { dateAdded: '2023-05-24T14:25:00.000Z' },
    49217: { dateAdded: '2023-05-25T07:55:00.000Z' },
    32678: { dateAdded: '2023-05-26T11:15:00.000Z' },
    47389: { dateAdded: '2023-05-27T12:40:00.000Z' },
    18379: { dateAdded: '2023-05-28T08:10:00.000Z' },
    46879: { dateAdded: '2023-05-29T13:35:00.000Z' },
    14890: { dateAdded: '2023-05-30T07:50:00.000Z' },
    38792: { dateAdded: '2023-05-31T16:45:00.000Z' },
    49812: { dateAdded: '2023-06-01T12:20:00.000Z' },
    20198: { dateAdded: '2023-06-02T08:35:00.000Z' },
    46987: { dateAdded: '2023-06-03T14:05:00.000Z' },
    18795: { dateAdded: '2023-06-04T10:25:00.000Z' },
    39781: { dateAdded: '2023-06-05T09:30:00.000Z' },
    41976: { dateAdded: '2023-06-06T11:15:00.000Z' },
    26387: { dateAdded: '2023-06-07T16:20:00.000Z' },
    41654: { dateAdded: '2023-06-08T10:50:00.000Z' },
    14789: { dateAdded: '2023-06-09T07:30:00.000Z' },
    31872: { dateAdded: '2023-06-10T12:55:00.000Z' },
    19873: { dateAdded: '2023-06-11T11:45:00.000Z' },
    41389: { dateAdded: '2023-06-12T08:20:00.000Z' },
    32765: { dateAdded: '2023-06-13T15:15:00.000Z' },
    48236: { dateAdded: '2023-06-14T10:35:00.000Z' },
    17654: { dateAdded: '2023-06-15T13:10:00.000Z' },
    49123: { dateAdded: '2023-06-16T08:55:00.000Z' },
    21987: { dateAdded: '2023-06-17T14:20:00.000Z' },
    38976: { dateAdded: '2023-06-18T09:50:00.000Z' },
    49781: { dateAdded: '2023-06-19T16:15:00.000Z' },
    14239: { dateAdded: '2023-06-20T10:10:00.000Z' },
    37689: { dateAdded: '2023-06-21T12:00:00.000Z' },
    22987: { dateAdded: '2023-06-22T09:25:00.000Z' },
    47123: { dateAdded: '2023-06-23T14:05:00.000Z' },
    14897: { dateAdded: '2023-06-24T11:40:00.000Z' },
    39871: { dateAdded: '2023-06-25T16:35:00.000Z' },
    11872: { dateAdded: '2023-06-26T10:15:00.000Z' },
    47678: { dateAdded: '2023-06-27T09:10:00.000Z' },
    27890: { dateAdded: '2023-06-28T07:50:00.000Z' },
    48971: { dateAdded: '2023-06-29T15:20:00.000Z' },
    14238: { dateAdded: '2023-06-30T11:30:00.000Z' },
    37678: { dateAdded: '2023-07-01T09:40:00.000Z' },
    21567: { dateAdded: '2023-07-02T08:55:00.000Z' },
    41891: { dateAdded: '2023-07-03T14:30:00.000Z' },
    15987: { dateAdded: '2023-07-04T13:05:00.000Z' },
    38123: { dateAdded: '2023-07-05T10:15:00.000Z' },
    42712: { dateAdded: '2023-07-06T07:20:00.000Z' },
    26123: { dateAdded: '2023-07-07T11:50:00.000Z' },
    46981: { dateAdded: '2023-07-08T16:40:00.000Z' },
    19876: { dateAdded: '2023-07-09T09:25:00.000Z' },
    39821: { dateAdded: '2023-07-10T12:35:00.000Z' },
    48791: { dateAdded: '2023-07-11T08:15:00.000Z' },
    27123: { dateAdded: '2023-07-12T14:50:00.000Z' },
    47689: { dateAdded: '2023-07-13T07:55:00.000Z' },
    14297: { dateAdded: '2023-07-14T11:10:00.000Z' },
    31612: { dateAdded: '2023-07-15T09:30:00.000Z' },
    21978: { dateAdded: '2023-07-16T14:05:00.000Z' },
    47239: { dateAdded: '2023-07-17T10:45:00.000Z' },
    14876: { dateAdded: '2023-07-18T07:50:00.000Z' },
    32689: { dateAdded: '2023-07-19T12:30:00.000Z' },
    21345: { dateAdded: '2023-07-20T09:40:00.000Z' },
    47612: { dateAdded: '2023-07-21T13:05:00.000Z' },
    29781: { dateAdded: '2023-07-22T08:20:00.000Z' },
    43976: { dateAdded: '2023-07-23T10:10:00.000Z' },
    13872: { dateAdded: '2023-07-24T14:55:00.000Z' },
    36781: { dateAdded: '2023-07-25T16:30:00.000Z' },
    12478: { dateAdded: '2023-07-26T11:20:00.000Z' },
    34976: { dateAdded: '2023-07-27T07:15:00.000Z' },
    48721: { dateAdded: '2023-07-28T09:50:00.000Z' },
    25987: { dateAdded: '2023-07-29T15:05:00.000Z' },
    36789: { dateAdded: '2023-07-30T12:25:00.000Z' },
    12456: { dateAdded: '2023-07-31T08:35:00.000Z' },
    46612: { dateAdded: '2023-08-01T11:45:00.000Z' },
    38971: { dateAdded: '2023-08-02T10:20:00.000Z' },
    18876: { dateAdded: '2023-08-03T14:30:00.000Z' },
    30976: { dateAdded: '2023-08-04T12:35:00.000Z' },
    28456: { dateAdded: '2023-08-05T08:40:00.000Z' },
    47231: { dateAdded: '2023-08-06T10:05:00.000Z' },
    10789: { dateAdded: '2023-08-07T16:10:00.000Z' },
    3976: { dateAdded: '2023-08-08T09:25:00.000Z' },
    20567: { dateAdded: '2023-08-09T12:40:00.000Z' },
    37123: { dateAdded: '2023-08-10T14:15:00.000Z' },
    40712: { dateAdded: '2023-08-11T08:55:00.000Z' },
    20987: { dateAdded: '2023-08-12T13:50:00.000Z' },
    33976: { dateAdded: '2023-08-13T07:40:00.000Z' },
    40976: { dateAdded: '2023-08-14T16:55:00.000Z' },
    24123: { dateAdded: '2023-08-15T10:45:00.000Z' },
    31894: { dateAdded: '2023-08-16T08:15:00.000Z' },
    14892: { dateAdded: '2023-08-17T12:05:00.000Z' },
    41971: { dateAdded: '2023-08-18T09:35:00.000Z' },
    142338: { dateAdded: '2023-08-19T11:10:00.000Z' },
    49876: { dateAdded: '2023-08-20T07:55:00.000Z' },
    18976: { dateAdded: '2023-08-21T16:40:00.000Z' },
    47812: { dateAdded: '2023-08-22T08:30:00.000Z' },
    21678: { dateAdded: '2023-08-23T11:55:00.000Z' },
    392812: { dateAdded: '2023-08-24T14:05:00.000Z' },
    12789: { dateAdded: '2023-08-25T09:50:00.000Z' },
    376: { dateAdded: '2023-08-26T12:35:00.000Z' },
    47489: { dateAdded: '2023-08-27T10:20:00.000Z' },
    21578: { dateAdded: '2023-08-28T15:30:00.000Z' },
    49638: { dateAdded: '2023-08-29T07:10:00.000Z' },
    1489: { dateAdded: '2023-08-30T09:25:00.000Z' },
    39671: { dateAdded: '2023-08-31T11:45:00.000Z' }
  };
};


// this funciton should run once every night and will not be called by frontend
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const runAllAlerts = async () => {

  // get all userConfigs from the users-collection
  const allUserConfigs: UserConfig[] = [];

  // iterate over all userConfigs
  for (const userConfig of allUserConfigs) {
    const scoutingConfigDocRef = doc(db, 'configs', userConfig.club, 'scouting', userConfig.email);

    const docSnap = await getDoc(scoutingConfigDocRef);

    if (docSnap.exists()) {
      const data = docSnap.data();

      if (Array.isArray(data.alerts)) {

        const today = new Date();
        const onMonthAgo = new Date();
        onMonthAgo.setMonth(onMonthAgo.getMonth() - 1);

        // remove player that have expired
        const playerIdsToExcludeFromAllAlerts = data.playerIdsToExcludeFromAllAlerts;
        const newPlayerIdsToExcludeFromAllAlerts = Object.keys(playerIdsToExcludeFromAllAlerts)
          .filter(playerId => new Date(playerIdsToExcludeFromAllAlerts[Number(playerId)].excludedUntil) > today)
          .reduce((result, playerId) => {
            result[Number(playerId)] = playerIdsToExcludeFromAllAlerts[Number(playerId)];
            return result;
          }, {} as NumberToJsonMapping);

        // iterate over all alerts
        const updatedAlerts = data.alerts.map(async (alert: Alert) => {

          // remove player that have expired
          const playerIdsToExclude = alert.playerIdsToExclude;
          const newPlayerIdsToExclude = Object.keys(playerIdsToExclude)
            .filter(playerId => new Date(playerIdsToExclude[Number(playerId)].excludedUntil) > today)
            .reduce((result, playerId) => {
              result[Number(playerId)] = playerIdsToExclude[Number(playerId)];
              return result;
            }, {} as NumberToJsonMapping);

          // remove player that have expired
          const currentPlayerIdsToAlert = alert.playerIdsToAlert;
          const newCurrentPlayerIdsToAlert = Object.keys(currentPlayerIdsToAlert)
            .filter(playerId => new Date(currentPlayerIdsToAlert[Number(playerId)].dateAdded) > onMonthAgo)
            .reduce((result, playerId) => {
              result[Number(playerId)] = currentPlayerIdsToAlert[Number(playerId)];
              return result;
            }, {} as NumberToJsonMapping);

          // get all playerIds that should be excluded from this alerts
          const allExcludedPlayerIds = { ...newPlayerIdsToExcludeFromAllAlerts, ...newPlayerIdsToExclude };

          // get all playerIds that match the current filters and filter out excluded players
          const playerIdsToAlert = await getPlayerIdsToAlert(alert.filters, allExcludedPlayerIds);

          // merge the new playerIdsToAlert with the new current ones
          const newPlayerIdsToAlert = { ...newCurrentPlayerIdsToAlert, ...playerIdsToAlert };

          // return the updated alert
          // todo?: could check all playerIds in the resulting alert to ensure the player is still in the database

          const newNumberOfNewPlayersSinceLastViewed = Object.values(newPlayerIdsToAlert)
            .filter(playerMap => playerMap.dateAdded > alert.lastViewedDate)
            .length;

          return {
            ...alert,
            playerIdsToExclude: newPlayerIdsToExclude,
            playerIdsToAlert: newPlayerIdsToAlert,
            numberOfNewPlayersSinceLastViewed: newNumberOfNewPlayersSinceLastViewed
          };
        });

        try {
          await updateDoc(scoutingConfigDocRef, {
            // todo?: sort the alerts by the number of playerIdsToAlert (element 0 has the most players, etc.)
            alerts: updatedAlerts,
            playerIdsToExcludeFromAllAlerts: newPlayerIdsToExcludeFromAllAlerts
          });
        }
        catch (error) {
          console.error(error); // eslint-disable-line no-console
        }

      }
    }
  }
};


// Create a new alert
export const createAlert = async (
  alertName: string,
  alertType: string | null,
  filters: FlexibleJsonMapping,
  playerIdsToExcludeFromAllAlerts: NumberToJsonMapping,
  userEmail: string,
  club: string
): Promise<Alert | null> => {

  const scoutingConfigDocRef = doc(db, 'configs', club, 'scouting', userEmail);
  const playerIdsToAlert = await getPlayerIdsToAlert(filters, playerIdsToExcludeFromAllAlerts);

  const newAlert: Alert = {
    name: alertName,
    type: alertType,
    filters: filters,
    playerIdsToExclude: {},
    playerIdsToAlert: playerIdsToAlert,
    lastViewedDate: new Date().toISOString(),
    numberOfNewPlayersSinceLastViewed: 0,
  };

  try {
    await setDoc(scoutingConfigDocRef, {
      alerts: arrayUnion(newAlert)
    }, { merge: true });

    return newAlert;
  }
  catch (error) {
    console.error(error); // eslint-disable-line no-console
    return null;
  }
};


// Update alert
export const updateAlert = async (
  currentAlertName: string,
  newAlertName: string,
  newAlertType: string | null,
  filters: FlexibleJsonMapping,
  resetExcludedPlayers: boolean,
  userEmail: string,
  club: string
) => {

  const scoutingConfigDocRef = doc(db, 'configs', club, 'scouting', userEmail);
  const docSnap = await getDoc(scoutingConfigDocRef);

  if (docSnap.exists()) {
    const data = docSnap.data();
    if (Array.isArray(data.alerts)) {
      const updatedAlerts = await Promise.all(data.alerts.map(async (alert: Alert) => {

        if (alert.name === currentAlertName) {
          const newPlayerIdsToExclude = resetExcludedPlayers ? {} : alert.playerIdsToExclude;
          const allExcludedPlayerIds = { ...data.playerIdsToExcludeFromAllAlerts, ...newPlayerIdsToExclude };
          const playerIdsToAlert = await getPlayerIdsToAlert(filters, allExcludedPlayerIds);

          return {
            ...alert,
            name: newAlertName || alert.name,
            type: newAlertType || alert.type,
            filters: filters,
            playerIdsToExclude: newPlayerIdsToExclude,
            playerIdsToAlert: playerIdsToAlert,
          };
        }

        return alert;
      }));

      try {
        await updateDoc(scoutingConfigDocRef, { alerts: updatedAlerts });
      }
      catch (error) {
        console.error(error); // eslint-disable-line no-console
      }
    }
  }
};


// Update name of an alert
export const updateAlertName = async (
  currentAlertName: string,
  newAlertName: string,
  userEmail: string,
  club: string
) => {

  const scoutingConfigDocRef = doc(db, 'configs', club, 'scouting', userEmail);
  const docSnap = await getDoc(scoutingConfigDocRef);

  if (docSnap.exists()) {
    const data = docSnap.data();
    if (Array.isArray(data.alerts)) {
      const updatedAlerts = data.alerts.map((alert: Alert) =>
        alert.name === currentAlertName ? { ...alert, name: newAlertName } : alert
      );

      try {
        await updateDoc(scoutingConfigDocRef, { alerts: updatedAlerts });
      }
      catch (error) {
        console.error(error); // eslint-disable-line no-console
      }
    }
  }
};


// Update alert view data
export const updateAlertViewedData = async (
  alertName: string,
  userEmail: string,
  club: string
) => {

  const scoutingConfigDocRef = doc(db, 'configs', club, 'scouting', userEmail);
  const docSnap = await getDoc(scoutingConfigDocRef);

  if (docSnap.exists()) {
    const data = docSnap.data();
    if (Array.isArray(data.alerts)) {
      const updatedAlerts = data.alerts.map((alert: Alert) => {

        if (alert.name === alertName) {
          return {
            ...alert,
            lastViewedDate: new Date().toISOString(),
            numberOfNewPlayersSinceLastViewed: 0
          };
        }

        return alert;
      });

      try {
        await updateDoc(scoutingConfigDocRef, { alerts: updatedAlerts });
      }
      catch (error) {
        console.error(error); // eslint-disable-line no-console
      }
    }
  }
};


// Delete an alert
export const deleteAlert = async (
  alertName: string,
  userEmail: string,
  club: string
) => {

  const scoutingConfigDocRef = doc(db, 'configs', club, 'scouting', userEmail);
  const docSnap = await getDoc(scoutingConfigDocRef);

  if (docSnap.exists()) {
    const data = docSnap.data();
    if (Array.isArray(data.alerts)) {
      const updatedAlerts = data.alerts.filter((alert: Alert) => alert.name !== alertName);

      try {
        await updateDoc(scoutingConfigDocRef, { alerts: updatedAlerts });
      }
      catch (error) {
        console.error(error); // eslint-disable-line no-console
      }
    }
  }
};

// Exclude the given player from either the alert with the given alertName or from all alerts
export const excludePlayerFromAlert = async (
  playerId: number,
  alertName: string | undefined,
  monthsExlcuded: number,
  userEmail: string,
  club: string
) => {
  const scoutingConfigDocRef = doc(db, 'configs', club, 'scouting', userEmail);
  const docSnap = await getDoc(scoutingConfigDocRef);
  const todayString = new Date().toISOString();
  const excludedUntilString = new Date(new Date().setMonth(new Date().getMonth() + monthsExlcuded)).toISOString();

  if (docSnap.exists()) {
    const data = docSnap.data();
    if (Array.isArray(data.alerts)) {

      const newPlayerIdsToExcludeFromAllAlerts = { ...data.playerIdsToExcludeFromAllAlerts };

      if (!alertName) {
        newPlayerIdsToExcludeFromAllAlerts[playerId] = { dateAdded: todayString, excludedUntil: excludedUntilString };
      }

      const updatedAlerts = data.alerts.map((alert: Alert) => {

        if (!alertName) {
          delete alert.playerIdsToAlert[playerId];
        }

        else if (alert.name === alertName) {
          delete alert.playerIdsToAlert[playerId];
          alert.playerIdsToExclude[playerId] = { dateAdded: todayString, excludedUntil: excludedUntilString };
        }

        return alert;
      });

      try {
        await updateDoc(scoutingConfigDocRef, {
          alerts: updatedAlerts,
          playerIdsToExcludeFromAllAlerts: newPlayerIdsToExcludeFromAllAlerts
        });
      }
      catch (error) {
        console.error(error); // eslint-disable-line no-console
      }
    }
  }
};


// Undo the exclusion of the given player from either the alert with the given alertName or from all alerts
export const undoExcludePlayerFromAlert = async (
  player: PlayerOverview,
  alertName: string | undefined,
  userEmail: string,
  club: string
) => {

  const scoutingConfigDocRef = doc(db, 'configs', club, 'scouting', userEmail);
  const docSnap = await getDoc(scoutingConfigDocRef);
  const playerMatchesFiltersMocked = () => true;

  if (docSnap.exists()) {
    const data = docSnap.data();
    if (Array.isArray(data.alerts)) {

      const newPlayerIdsToExcludeFromAllAlerts = { ...data.playerIdsToExcludeFromAllAlerts };

      if (!alertName) {
        delete newPlayerIdsToExcludeFromAllAlerts[player.id];
      }

      const updatedAlerts = data.alerts.map((alert: Alert) => {

        if (!alertName) {
          if (playerMatchesFiltersMocked()) {
            alert.playerIdsToAlert[player.id] = { dateAdded: new Date().toISOString() };
          }
        }

        else if (alert.name === alertName) {
          delete alert.playerIdsToExclude[player.id];
          if (playerMatchesFiltersMocked()) {
            alert.playerIdsToAlert[player.id] = { dateAdded: new Date().toISOString() };
          }
        }

        return alert;
      });

      try {
        await updateDoc(scoutingConfigDocRef, {
          alerts: updatedAlerts,
          playerIdsToExcludeFromAllAlerts: newPlayerIdsToExcludeFromAllAlerts
        });
      }
      catch (error) {
        console.error(error); // eslint-disable-line no-console
      }
    }
  }
};
