import '../../platform.css';
import './searchPlayers.css';

import { AuthContextType, useAuthContext } from '../../../common/contexts/AuthContext';
import { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { playerTeamDataState } from '../../recoil/selectors/playerTeamDataState';
import { FlexibleJsonMapping, JsonMapping, PlayerOverview, PlayerOverviews, PlayerOverviewsQueryOptions } from '../../types';

import { PlayerSimpleTable } from '../tables/playerSimpleTable/PlayerSimpleTable';
import { InputField } from '../input/InputField';
import { staticLanguageMap } from '../../../common/static/staticLanguageMap';
import { debounce } from '../../utils/utils';
import { searchPlayerOverviews } from '../../services/fokusServer/playerOverviews';


interface SelectPlayerViewProps {
  onPlayerClick: (player: FlexibleJsonMapping) => void;
  titleKey: string;
  maxTableHeight: string;
  isGoalkeeper?: boolean;
  disablePlayersWithoutEventData?: boolean;

  setIsPlayerSearchActive?: (value: boolean) => void;

  marginTop?: string;
  marginBottom?: string;
}

export const SelectPlayerView: React.FC<SelectPlayerViewProps> = ({
  onPlayerClick,
  titleKey,
  maxTableHeight,
  isGoalkeeper,
  disablePlayersWithoutEventData,
  setIsPlayerSearchActive,
  marginTop,
  marginBottom,
}) => {

  const { currentUser } = useAuthContext() as AuthContextType;
  const userConfig = useRecoilValue(userConfigState);
  const playerTeamData = useRecoilValue(playerTeamDataState);

  const [tableData, setTableData] = useState<JsonMapping[]>([]);
  const [playerData, setPlayerData] = useState<PlayerOverviews | undefined>(undefined);

  const [searchString, setSearchString] = useState<string>('');
  const [debouncedSearchString, setDebouncedSearchString] = useState<string>('');
  const clearDebounceRef = useRef<() => void>(() => null);

  const [searchWasReset, setSearchWasReset] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalHits, setTotalHits] = useState(0);

  const [isSearchWithoutResult, setIsSearchWithoutResult] = useState(false);


  const onChangeSearchField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value);
    if (event.target.value === '') {
      setTableData([]);
      setDebouncedSearchString('');
      setIsSearchWithoutResult(false);
    }
    if (setIsPlayerSearchActive) {
      setIsPlayerSearchActive(event.target.value !== '');
    }
  };


  const onKeyDownSearchField = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearchForPlayers(true, true);
    }
  };


  const handleSearchForPlayers = async (isUserInitiated: boolean, isNewSearch: boolean) => {

    setIsLoading(true);
    setSearchWasReset(false);

    if (isUserInitiated) {
      clearDebounceRef.current();
    }

    if (searchString !== '') {

      const nextPageToQuery = isNewSearch ? 1 : currentPage + 1;

      const queryOptions: PlayerOverviewsQueryOptions = {
        name: searchString.toLowerCase(),
        page: nextPageToQuery,
      };

      try {
        const result: FlexibleJsonMapping | undefined = await searchPlayerOverviews(currentUser, queryOptions);

        const totalHits = result?.total_hits;
        const page = result?.current_page;
        const players: PlayerOverview[] = result?.players ?? [];

        if (!result || totalHits === undefined || !page) {
          setTableData([]);
          setPlayerData(undefined);
        }
        else if (totalHits === 0 || (isNewSearch && players.length === 0)) {
          setIsSearchWithoutResult(true);
          setTableData([]);
          setPlayerData(undefined);
          setCurrentPage(0);
          setTotalHits(0);
        }
        else {
          const playersObject = players.reduce((acc: PlayerOverviews, player: PlayerOverview) => {
            acc[player['id']] = player;
            return acc;
          }, {});

          const newTableData: FlexibleJsonMapping[] = [];

          players.forEach((player: PlayerOverview) => {
            const playerCopy: FlexibleJsonMapping = {
              id: player['id'],
              image_url: player['image_url'],
              fullname: player['fullname'],
              club: player['club'],
              country_code: player['country_code'],
              competition_name: player['competition_name'],
              primary_position: player['primary_position'],
              event_data_available: player['event_data_available'],
              event_data_exists: player['event_data_exists'],
            };
            if (playerTeamData && playerCopy['id'] in playerTeamData) {
              playerCopy['currentTeam'] = playerTeamData[playerCopy['id']].currentTeam;
            }
            newTableData.push(playerCopy);
          });

          setIsSearchWithoutResult(false);
          setTableData(isNewSearch ? newTableData : [...tableData, ...newTableData]);
          setPlayerData(isNewSearch ? playersObject : { ...playerData, ...playersObject });
          setCurrentPage(page);
          setTotalHits(totalHits);
        }
      } catch (error) {
        console.log(error); // eslint-disable-line no-console
      }
    }

    setIsLoading(false);
  };


  const onPlayerClickInternal = (player: FlexibleJsonMapping) => {
    if (playerData && player.id in playerData) {
      onPlayerClick(playerData[player.id]);
    }
    if (setIsPlayerSearchActive) {
      setIsPlayerSearchActive(false);
    }
  };


  useEffect(() => {
    if (searchString.length === 0) {
      setTableData([]);
    }

    const [debounceFilter, clearDebounce] = debounce(() => {
      if (searchString !== debouncedSearchString) {
        setDebouncedSearchString(searchString);
      }
    }, 500);

    debounceFilter();

    clearDebounceRef.current = clearDebounce;

    return () => {
      clearDebounce();
    };
  }, [searchString]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (debouncedSearchString !== '') {
      handleSearchForPlayers(false, true);
    }
  }, [debouncedSearchString]); // eslint-disable-line react-hooks/exhaustive-deps


  const resetSearch = () => {
    setSearchString('');
    setTableData([]);
    setIsSearchWithoutResult(false);
    setSearchWasReset(true);

    if (setIsPlayerSearchActive) {
      setIsPlayerSearchActive(false);
    }
  };


  useEffect(() => {
    if (searchWasReset) {
      const timer = setTimeout(() => {
        setTableData([]);
      }, 250);

      return () => clearTimeout(timer);
    }
  }, [searchWasReset]);


  return (
    <div className='select-player-view-container'>

      <div
        className='select-player-view-title'
        style={{ marginTop: marginTop ?? '2vh', marginBottom: marginBottom ?? '2vh' }}>
        {userConfig ? staticLanguageMap[titleKey][userConfig.language] : ''}
      </div>

      <div className='select-player-view-input-field-container'>
        <InputField
          searchString={searchString}
          onChangeInputField={onChangeSearchField}
          onKeyDownInputField={onKeyDownSearchField}
          resetSearchString={resetSearch}
          defaultInput={userConfig ? staticLanguageMap['searchForName'][userConfig.language] : ''}
          showDefaultInput={true}
          style={{ boxShadow: '0px 0px 4px 2px #0e101324' }}
        />
      </div>

      <div className='select-player-view-result-section'>

        {isSearchWithoutResult && userConfig && (
          <div className=''>
            {staticLanguageMap['noResult'][userConfig.language]}
          </div>
        )}

        {tableData.length > 0 && !searchWasReset && (
          <div className='select-player-view-table'>
            <PlayerSimpleTable
              data={tableData}
              tableType={'addPlayerView'}
              maxHeight={maxTableHeight}

              isLoading={isLoading}
              handleSearchForPlayers={handleSearchForPlayers}
              currentPage={currentPage}
              totalHits={totalHits}

              onPlayerClick={onPlayerClickInternal}
              disablePlayersWithoutEventData={disablePlayersWithoutEventData}
              isGoalkeeper={isGoalkeeper}
            />
          </div>
        )}

      </div>

    </div>
  );
};
