import './input.css';

import { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { teamsState } from '../../recoil/atoms/teamsState';
import { clubScopesState } from '../../recoil/atoms/clubScopesState';
import { userSettingsState } from '../../recoil/atoms/userSettingsState';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import BlockIcon from '@mui/icons-material/Block';

import { staticLanguageMap } from '../../../common/static/staticLanguageMap';
import { goalkeeperSeasonTableMetricGroups, metricToDisplayName, outfieldSeasonTableMetricGroups, skillcornerStats } from '../../static/playerMetrics';
import { updateSeasonStatsToggles } from '../../services/firestore/userSettings';
import { Toggle } from './Toggle';


interface MetricDropDownProps {
  id: string;

  selectedOptions: string[];
  setSelectedOptions: (value: string[]) => void;
  isDropDownExpanded: boolean;
  setIsDropDownExpanded: (value: boolean) => void;

  defaultDropDownText: string; // static language key
  defaultDropDownTextColor: string;
  marginBetweenOptions: number;

  emptyBackgroundId?: string;
  maxHeight?: string; // controls scrolling
  multiSelect?: boolean;

  forceSelection?: boolean; // if true, at least one option must be selected
  isGoalkeeperSelected?: boolean;

  anyIterationWithoutSkillcornerSelected: boolean;
  setAnySkillcornerFiltersSelected: (value: boolean) => void;
}

export const MetricDropDown: React.FC<MetricDropDownProps> = ({
  id,
  selectedOptions,
  setSelectedOptions,
  isDropDownExpanded,
  setIsDropDownExpanded,
  defaultDropDownText,
  defaultDropDownTextColor,
  marginBetweenOptions,
  emptyBackgroundId,
  maxHeight,
  multiSelect,
  forceSelection,
  isGoalkeeperSelected,

  anyIterationWithoutSkillcornerSelected,
  setAnySkillcornerFiltersSelected,
}) => {

  const userConfig = useRecoilValue(userConfigState);
  const clubScopes = useRecoilValue(clubScopesState);
  const userSettings = useRecoilValue(userSettingsState);

  const teams = useRecoilValue(teamsState);

  const dropDownRef = useRef<HTMLDivElement>(null);


  const handleDropDownClick = () => {
    if (isDropDownExpanded) {
      removeDropDownExpansion();
    }

    else {
      expandDropDown();
    }
  };


  const handleOptionSelect = (option: string, isOptionAlreadySelected: boolean, isSkillcornerMetric?: boolean) => {

    let newSelectedOptions: string[] = [];

    // single-select
    if (!multiSelect) {
      if (!isOptionAlreadySelected) {
        removeDropDownExpansion();
      }
      newSelectedOptions = (isOptionAlreadySelected && !forceSelection) ? [] : [option];
    }

    // multi-select
    else {
      newSelectedOptions = selectedOptions.slice();
      if (isOptionAlreadySelected) {
        const i = newSelectedOptions.indexOf(option);
        newSelectedOptions.splice(i, 1);
      }
      else {
        newSelectedOptions.push(option);
      }
    }

    setSelectedOptions(newSelectedOptions);

    if (!isOptionAlreadySelected) {
      setAnySkillcornerFiltersSelected(isSkillcornerMetric ?? false);
    }
  };


  const expandDropDown = () => {
    // zIndex handling is not ideal, but must be handlded carefully due to interactions between multiple drop downs
    let element = document.getElementById(id);
    if (element) {
      element.style.transition = '150ms';
      element.style.height = 'auto';
      element.style.zIndex = '110';
    }

    if (emptyBackgroundId) {
      element = document.getElementById(emptyBackgroundId);
      if (element) {
        element.style.transition = '150ms';
        element.style.zIndex = '100';
        element.style.backgroundImage = 'linear-gradient(to bottom, #00000000, #00000012)';
      }
    }

    setIsDropDownExpanded(true);
  };


  const removeDropDownExpansion = () => {
    let element = document.getElementById(id);
    if (element) {
      element.style.transition = '75ms';
      element.style.height = '28px';
      element.style.zIndex = '1';
    }

    if (emptyBackgroundId) {
      element = document.getElementById(emptyBackgroundId);
      if (element) {
        element.style.transition = '75ms';
        element.style.zIndex = '-1';
        element.style.backgroundImage = 'linear-gradient(to bottom, #00000000, #00000000)';
      }
    }

    setIsDropDownExpanded(false);
    if (dropDownRef.current) {
      dropDownRef.current.scrollTop = 0;
    }
  };


  const getDisplayOption = (option: string) => {
    if (!userConfig) return '';

    // we want to map every option that is present in the language map
    if (option in staticLanguageMap) return staticLanguageMap[option][userConfig.language];

    if (teams && option in teams) return teams[option].name;

    if (option in metricToDisplayName) return metricToDisplayName[option][userConfig.language];

    return option;
  };


  const showSelectedOptions = () => {

    // single-select
    let firstSelection = getDisplayOption(selectedOptions[0]);

    if (!multiSelect) {
      return firstSelection;
    }

    // multi-select
    for (let i = 1; i < selectedOptions.length; i++) {
      firstSelection += ', ' + getDisplayOption(selectedOptions[i]);
    }
    return firstSelection;
  };


  useEffect(() => {
    if (!isDropDownExpanded) {
      removeDropDownExpansion();
    }
  }, [isDropDownExpanded]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div
      className={'drop-down-select-container' + ((isDropDownExpanded || selectedOptions.length > 0) ? ' drop-down-select-container-focus' : '')}
      id={id}
      style={{ maxHeight: maxHeight }}
    >

      <div className='drop-down-select-button' onClick={() => handleDropDownClick()}>

        {selectedOptions.length > 0 && <div className='drop-down-select-button-overlay'>&nbsp;</div>}

        <div className='drop-down-select-button-text'>
          {selectedOptions.length === 0 ? (
            <div style={{ color: defaultDropDownTextColor }}>
              {userConfig ? staticLanguageMap[defaultDropDownText][userConfig.language] : ''}
            </div>
          ) : (
            <div style={{ color: '#000000' }}>
              {showSelectedOptions()}
            </div>
          )}
        </div>

        {!isDropDownExpanded && (
          <div className='drop-down-select-icon'>
            <ArrowDropDownIcon style={{ fontSize: 24 }} />
          </div>
        )}

        {isDropDownExpanded && (
          <div className='drop-down-select-icon'>
            <ArrowDropUpIcon style={{ fontSize: 24 }} />
          </div>
        )}
      </div>

      <div className='drop-down-select-space'>&nbsp;</div>

      <div className='drop-down-select-toggle-section'>
        <div className='drop-down-select-toggle-text'>
          {userConfig ? staticLanguageMap['orderByActualValues'][userConfig.language] : ''}
        </div>
        {userConfig && (
          <div className='drop-down-select-toggle' title={userConfig ? staticLanguageMap['orderByActualValuesTitle'][userConfig.language] : undefined}>
            <Toggle
              isToggled={userSettings?.seasonStatsToggles?.orderBy ?? false}
              setIsToggled={(value: boolean) => updateSeasonStatsToggles(
                { ...userSettings?.seasonStatsToggles ?? {}, 'orderBy': value },
                userConfig.email,
                userConfig.club)}
              isWhiteBackground={true} />
          </div>
        )}
      </div>

      <div
        ref={dropDownRef}
        className={'drop-down-select-options' + (maxHeight ? ' drop-down-select-options-scroll' : '')}
        style={{ maxHeight: maxHeight }}>
        {Object.entries(isGoalkeeperSelected ? goalkeeperSeasonTableMetricGroups : outfieldSeasonTableMetricGroups).map(([metricGroupKey, metrics], index) => {
          return (
            <div key={metricGroupKey + '-' + index}>
              <div className='metric-drop-down-select-divider' style={{ marginTop: index === 0 ? 20 : undefined }}>&nbsp;</div>

              <div className='metric-drop-down-select-group-title-container'>
                <div className='metric-drop-down-select-group-title'>
                  {metricGroupKey}
                </div>
              </div>

              {metrics.map(metric => {
                const isSelected = selectedOptions.includes(metric);
                return (
                  <div
                    className={'drop-down-select-option' + (isSelected ? ' drop-down-select-option-selected' : '')}
                    style={{ marginTop: marginBetweenOptions }}
                    key={metric}
                    onClick={() => handleOptionSelect(metric, isSelected)}
                  >
                    {getDisplayOption(metric)}
                  </div>
                );
              })}
            </div>
          );
        })}

        {clubScopes && clubScopes.hasSkillcorner && !isGoalkeeperSelected && (
          <div>
            <div className='metric-drop-down-select-divider'>&nbsp;</div>

            <div className='metric-drop-down-select-group-title-container'>
              <div className='metric-drop-down-select-group-title' style={{ paddingRight: anyIterationWithoutSkillcornerSelected ? 24 : undefined }}>
                SkillCorner
                {anyIterationWithoutSkillcornerSelected &&
                  <BlockIcon className='fast-fade-in' style={{ fontSize: 17, color: '#00000088', position: 'absolute', marginTop: -2, marginLeft: 3 }} />
                }
              </div>

            </div>

            {skillcornerStats.map(metric => {
              const isSelected = selectedOptions.includes(metric);
              const isDisabled = anyIterationWithoutSkillcornerSelected;
              return (
                <div
                  className={
                    'drop-down-select-option'
                    + (isSelected ? ' drop-down-select-option-selected' : '')
                    + (isDisabled ? ' drop-down-select-option-disabled' : '')
                  }
                  style={{ marginTop: marginBetweenOptions }}
                  key={metric}
                  onClick={() => !isDisabled ? handleOptionSelect(metric, isSelected, true) : isDisabled}
                >
                  {getDisplayOption(metric)}
                </div>
              );
            })}
          </div>
        )}
      </div>

    </div>
  );
};
