import { db } from '../../../firebase';

import {
  doc,
  setDoc,
  onSnapshot,
} from 'firebase/firestore';

import { ClubSettings, JsonMapping } from '../../types';
import { updateTeamsWithNewFormation } from './teamsOrSquads';


// get and set settings for club and
export const getClubSettings = (setClubSettings: (clubSettings: ClubSettings) => void, club: string) => {

  const clubSettingsDocRef = doc(db, 'configs', club, 'settings', 'clubSettings');

  const unsubscribe = onSnapshot(clubSettingsDocRef, (doc) => {
    if (doc.exists()) {
      const clubSettingsObject = doc.data();

      const clubSettings: ClubSettings = {
        clubId: clubSettingsObject.clubId,
        clubName: clubSettingsObject.clubName,
        formation: clubSettingsObject.formation ?? '433',
        countryCode: clubSettingsObject.countryCode ?? 'NO',
        iterations: clubSettingsObject.iterations ?? [],
      };

      setClubSettings(clubSettings);
    }
  });

  return unsubscribe;
};


// update club settings (formation)
export const updateClubSettings = async (newClubSettings: JsonMapping, currentFormation: string, userEmail: string, club: string) => {

  // Update all team configs with new formation if a new formation is set
  if (newClubSettings['formation'] !== currentFormation) {
    await updateTeamsWithNewFormation(newClubSettings['formation'], currentFormation, userEmail, club);
  }

  // Update settings
  const clubSettingsDocRef = doc(db, 'configs', club, 'settings', 'clubSettings');

  try {
    await setDoc(clubSettingsDocRef, {
      formation: newClubSettings['formation']
    }, { merge: true });
  }
  catch (error) {
    console.error(error); // eslint-disable-line no-console
  }
};
