import './input.css';

import { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { clubScopesState } from '../../recoil/atoms/clubScopesState';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import BlockIcon from '@mui/icons-material/Block';

import { staticLanguageMap } from '../../../common/static/staticLanguageMap';
import { goalkeeperSeasonTableMetricGroups, metricToDisplayName, outfieldSeasonTableMetricGroups, skillcornerStats } from '../../static/playerMetrics';
import { indexKeys } from '../../static/propertyValues';
import { RatingRequirement } from '../../types';


interface RatingDropDownProps {
  id: string;
  selectedRatings: RatingRequirement[];
  setSelectedRatings: (value: RatingRequirement[]) => void;
  isDropDownExpanded: boolean;
  setIsDropDownExpanded: (value: boolean) => void;
  defaultDropDownText: string; // static language key
  defaultDropDownTextColor: string;
  marginBetweenOptions: number;
  emptyBackgroundId?: string;
  maxHeight?: string; // controls scrolling
  isGoalkeeperSelected?: boolean;

  anyIterationWithoutSkillcornerSelected: boolean;
  setAnySkillcornerFiltersSelected: (value: boolean) => void;
}

export const RatingDropDown: React.FC<RatingDropDownProps> = ({
  id,
  selectedRatings,
  setSelectedRatings,
  isDropDownExpanded,
  setIsDropDownExpanded,
  defaultDropDownText,
  defaultDropDownTextColor,
  marginBetweenOptions,
  emptyBackgroundId,
  maxHeight,
  isGoalkeeperSelected,

  anyIterationWithoutSkillcornerSelected,
  setAnySkillcornerFiltersSelected,
}) => {

  const userConfig = useRecoilValue(userConfigState);
  const clubScopes = useRecoilValue(clubScopesState);

  const dropDownRef = useRef<HTMLDivElement>(null);


  const handleDropDownClick = () => {
    if (isDropDownExpanded) {
      removeDropDownExpansion();
    }

    else {
      expandDropDown();
    }
  };


  const handleOptionSelect = (metric: string, value: string, valueIsSelected: boolean, metricIsSelected: boolean) => {
    let newRatings: RatingRequirement[] = [];
    if (valueIsSelected) {
      newRatings = selectedRatings.filter(rating => rating.metric !== metric);
      setSelectedRatings(newRatings);
    }
    else if (metricIsSelected) {
      newRatings = selectedRatings.map(rating => rating.metric === metric ? { metric: metric, value: value } : rating);
      setSelectedRatings(newRatings);
    }
    else {
      newRatings = [...selectedRatings, { metric: metric, value: value }];
      setSelectedRatings(newRatings);
    }

    const anySkillcornerFiltersSelected = newRatings.some(rating => skillcornerStats.includes(rating.metric));
    setAnySkillcornerFiltersSelected(anySkillcornerFiltersSelected);
  };


  const expandDropDown = () => {
    // zIndex handling is not ideal, but must be handlded carefully due to interactions between multiple drop downs
    let element = document.getElementById(id);
    if (element) {
      element.style.transition = '150ms';
      element.style.height = 'auto';
      element.style.zIndex = '110';
    }

    if (emptyBackgroundId) {
      element = document.getElementById(emptyBackgroundId);
      if (element) {
        element.style.transition = '150ms';
        element.style.zIndex = '100';
        element.style.backgroundImage = 'linear-gradient(to bottom, #00000000, #00000012)';
      }
    }

    setIsDropDownExpanded(true);
  };


  const removeDropDownExpansion = () => {
    let element = document.getElementById(id);
    if (element) {
      element.style.transition = '75ms';
      element.style.height = '28px';
      element.style.zIndex = '1';
    }

    if (emptyBackgroundId) {
      element = document.getElementById(emptyBackgroundId);
      if (element) {
        element.style.transition = '75ms';
        element.style.zIndex = '-1';
        element.style.backgroundImage = 'linear-gradient(to bottom, #00000000, #00000000)';
      }
    }

    setIsDropDownExpanded(false);
    if (dropDownRef.current) {
      dropDownRef.current.scrollTop = 0;
    }
  };


  const showSelectedOptions = () => {
    if (!userConfig || selectedRatings.length === 0) return '';

    let firstSelection = metricToDisplayName[selectedRatings[0].metric][userConfig.language] + ': ' + selectedRatings[0].value;

    selectedRatings.slice(1).forEach(rating => {
      firstSelection += ', ' + metricToDisplayName[rating.metric][userConfig.language] + ': ' + rating.value;
    });

    return firstSelection;
  };


  useEffect(() => {
    if (!isDropDownExpanded) {
      removeDropDownExpansion();
    }
  }, [isDropDownExpanded]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div
      className={'drop-down-select-container' + ((isDropDownExpanded || selectedRatings.length > 0) ? ' drop-down-select-container-focus' : '')}
      id={id}
      style={{ maxHeight: maxHeight }}
    >

      <div className='drop-down-select-button' onClick={() => handleDropDownClick()}>

        {selectedRatings.length > 0 && <div className='drop-down-select-button-overlay'>&nbsp;</div>}

        <div className='drop-down-select-button-text'>
          {selectedRatings.length === 0 ? (
            <div style={{ color: defaultDropDownTextColor }}>
              {userConfig ? staticLanguageMap[defaultDropDownText][userConfig.language] : ''}
            </div>
          ) : (
            <div style={{ color: '#000000' }}>
              {showSelectedOptions()}
            </div>
          )}
        </div>

        {!isDropDownExpanded && (
          <div className='drop-down-select-icon'>
            <ArrowDropDownIcon style={{ fontSize: 24 }} />
          </div>
        )}

        {isDropDownExpanded && (
          <div className='drop-down-select-icon'>
            <ArrowDropUpIcon style={{ fontSize: 24 }} />
          </div>
        )}
      </div>

      <div className='drop-down-select-space'>&nbsp;</div>

      <div
        ref={dropDownRef}
        className={'drop-down-select-options' + (maxHeight ? ' drop-down-select-options-scroll' : '')}
        style={{ maxHeight: maxHeight }}>
        {Object.entries(isGoalkeeperSelected ? goalkeeperSeasonTableMetricGroups : outfieldSeasonTableMetricGroups).map(([metricGroupKey, metrics], index) => {
          return (
            <div key={metricGroupKey}>
              <div className='rating-drop-down-select-divider' style={{ marginTop: index === 0 ? 20 : undefined }}>&nbsp;</div>

              <div className='metric-drop-down-select-group-title-container'>
                <div className='rating-drop-down-select-group-title'>
                  {metricGroupKey}
                </div>
              </div>

              {metrics.map((metric, index) => {
                if (!userConfig) return null;

                const requirement = selectedRatings.find(rating => rating.metric === metric)?.value;
                return (
                  <div
                    className={'rating-drop-down-select-option' + (index > 0 ? ' rating-drop-down-select-option-border-top' : '')}
                    style={{ marginTop: marginBetweenOptions }}
                    key={metric}
                  >
                    <div className='rating-drop-down-metric'>
                      {metricToDisplayName[metric][userConfig.language]}
                    </div>
                    <div className='rating-drop-down-metric-values'>
                      {indexKeys.map(value => {
                        const metricIsSelected: boolean = requirement !== undefined;
                        const valueIsSelected: boolean = metricIsSelected && value === requirement;
                        return (
                          <div
                            key={value}
                            className={'rating-drop-down-metric-value' + (valueIsSelected ? ' rating-drop-down-metric-value-selected' : '')}
                            onClick={() => handleOptionSelect(metric, value, valueIsSelected, metricIsSelected)}>
                            {value}
                          </div>
                        );
                      })
                      }
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}

        {clubScopes && clubScopes.hasSkillcorner && !isGoalkeeperSelected && (
          <div>
            <div className='rating-drop-down-select-divider'>&nbsp;</div>

            <div className='metric-drop-down-select-group-title-container'>
              <div className='rating-drop-down-select-group-title' style={{ paddingRight: anyIterationWithoutSkillcornerSelected ? 24 : undefined }}>
                SkillCorner
                {anyIterationWithoutSkillcornerSelected &&
                  <BlockIcon className='fast-fade-in' style={{ fontSize: 17, color: '#00000088', position: 'absolute', marginTop: -2, marginLeft: 3 }} />
                }
              </div>
            </div>

            {skillcornerStats.map((metric, index) => {
              if (!userConfig) return null;

              const isDisabled = anyIterationWithoutSkillcornerSelected;

              const requirement = selectedRatings.find(rating => rating.metric === metric)?.value;
              return (
                <div
                  className={'rating-drop-down-select-option' + (index > 0 ? ' rating-drop-down-select-option-border-top' : '')}
                  style={{ marginTop: marginBetweenOptions, color: isDisabled ? '#00000088' : '#000000' }}
                  key={metric}
                >
                  <div className='rating-drop-down-metric'>
                    {metricToDisplayName[metric][userConfig.language]}
                  </div>
                  <div className='rating-drop-down-metric-values'>
                    {indexKeys.map(value => {
                      const metricIsSelected: boolean = requirement !== undefined;
                      const valueIsSelected: boolean = metricIsSelected && value === requirement;
                      return (
                        <div
                          key={value}
                          style={{ color: isDisabled ? '#00000088' : '#000000' }}
                          className={
                            'rating-drop-down-metric-value'
                            + (valueIsSelected ? ' rating-drop-down-metric-value-selected' : '')
                            + (isDisabled ? ' rating-drop-down-metric-value-disabled' : '')
                          }
                          onClick={() => !isDisabled ? handleOptionSelect(metric, value, valueIsSelected, metricIsSelected) : null}
                        >
                          {value}
                        </div>
                      );
                    })
                    }
                  </div>
                </div>
              );
            })}
          </div>
        )}

      </div>

    </div>
  );
};
