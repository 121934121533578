import './modals.css';

import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { teamsState } from '../../recoil/atoms/teamsState';
import { clubSettingsState } from '../../recoil/atoms/clubSettingsState';
import { useGlobalModal } from '../../recoil/hooks/openGlobalModal';

import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import UpdateIcon from '@mui/icons-material/Update';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { FlexibleJsonMapping } from '../../types';
import { DropDownSelect } from '../input/DropDownSelect';
import { InputField } from '../input/InputField';
import { addTeamOrSquad, deleteTeamOrSquad, editTeamOrSquad } from '../../services/firestore/teamsOrSquads';
import { staticLanguageMap } from '../../../common/static/staticLanguageMap';
import { formationOptions } from '../../static/propertyValues';


interface TeamOrSquadModalProps {
  teamsOrSquads: FlexibleJsonMapping | null;
  isSquad: boolean;
  setIsTeamOrSquadModalOpen: (value: boolean) => void;
  activeTabIndex: number | undefined,
  setActiveTabIndex: (tab: number) => void;
  tabIndexToEdit: number | undefined,
  setTabIndexToEdit: (tab: number | undefined) => void;
}


export const TeamOrSquadModal: React.FC<TeamOrSquadModalProps> = ({
  teamsOrSquads,
  isSquad,
  setIsTeamOrSquadModalOpen,
  activeTabIndex,
  setActiveTabIndex,
  tabIndexToEdit,
  setTabIndexToEdit
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const teams = useRecoilValue(teamsState);

  const { handleOpenConfirmModal } = useGlobalModal();

  const clubSettings = useRecoilValue(clubSettingsState);
  const clubFormation = clubSettings?.formation;

  const [searchString, setSearchString] = useState(tabIndexToEdit !== undefined && teamsOrSquads ? Object.values(teamsOrSquads)[tabIndexToEdit]['name'] : '');

  const [selectedFormation, setSelectedFormation] = useState<string[]>(
    tabIndexToEdit !== undefined && teamsOrSquads ? [Object.values(teamsOrSquads)[tabIndexToEdit]['formation']] : [clubFormation]
  );
  const [selectedTeam, setSelectedTeam] = useState<string[]>([]);

  const [isFormationDropDownExpanded, setIsFormationDropDownExpanded] = useState(false);
  const [isTeamDropDownExpanded, setIsTeamDropDownExpanded] = useState(false);

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(false);


  const onChangeSearchField = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length < 26) {
      setSearchString(event.target.value);
    }
  };

  const handleAddOrEditClick = async () => {
    if (!userConfig || submitButtonDisabled || !teamsOrSquads || !clubFormation) return;

    setSubmitButtonDisabled(true);

    if (tabIndexToEdit !== undefined) {
      await editTeamOrSquad(
        Object.keys(teamsOrSquads)[tabIndexToEdit],
        searchString,
        selectedFormation[0],
        Object.values(teamsOrSquads)[tabIndexToEdit],
        isSquad,
        userConfig.email,
        userConfig.club,
      );

      setActiveTabIndex(tabIndexToEdit);
      setIsTeamOrSquadModalOpen(false);
      setTimeout(() => {
        setTabIndexToEdit(undefined);
      }, 150);
    }
    else {
      let newTeamOrSquadId: string | undefined = undefined;
      const selectedTeamId = selectedTeam.length > 0 ? selectedTeam[0] : undefined;

      newTeamOrSquadId = await addTeamOrSquad(
        searchString,
        selectedFormation[0],
        Object.keys(teamsOrSquads).length,
        clubFormation,
        isSquad,
        userConfig.email,
        userConfig.club,
        selectedTeamId,
      );

      if (newTeamOrSquadId !== undefined) {
        setIsTeamOrSquadModalOpen(false);
      }
    }

    setTimeout(() => {
      setSubmitButtonDisabled(false);
    }, 500);
  };

  const confirmDelete = async () => {
    if (!userConfig || tabIndexToEdit === undefined || activeTabIndex === undefined || !teamsOrSquads || deleteButtonDisabled) return;

    setDeleteButtonDisabled(true);

    setIsTeamOrSquadModalOpen(false);

    const orderIndex = Object.values(teamsOrSquads)[tabIndexToEdit]['orderIndex'];
    const name = Object.keys(teamsOrSquads)[tabIndexToEdit];

    setTabIndexToEdit(undefined);

    await deleteTeamOrSquad(name, isSquad, orderIndex, userConfig.club);

    setTimeout(() => {
      setDeleteButtonDisabled(false);
    }, 500);
  };


  const removeDropDownExpansions = () => {
    setIsFormationDropDownExpanded(false);
    setIsTeamDropDownExpanded(false);
  };


  const isButtonDisabled = () => {
    if (submitButtonDisabled) return true;

    if (searchString.length === 0) {
      return true;
    }

    if (tabIndexToEdit === undefined) {
      return searchString.length === 0 || selectedFormation.length === 0;
    }

    const isNewName = teamsOrSquads && searchString.length > 0 && searchString !== Object.values(teamsOrSquads)[tabIndexToEdit]['name'];
    const isNewFormation = teamsOrSquads && selectedFormation.length > 0 && selectedFormation[0] !== Object.values(teamsOrSquads)[tabIndexToEdit]['formation'];

    return !isNewName && !isNewFormation;
  };


  const getButtonText = () => {
    if (!userConfig) return '';

    if (tabIndexToEdit === undefined) {
      return isSquad ? staticLanguageMap['createSquad'][userConfig.language] : staticLanguageMap['createTeam'][userConfig.language];
    }

    return staticLanguageMap['confirmEdits'][userConfig.language];
  };


  return (
    <div className='modal-root-container team-or-squad-modal-root' style={{ height: isSquad && tabIndexToEdit === undefined ? 700 : 650 }}>

      {(isFormationDropDownExpanded || isTeamDropDownExpanded) && (
        <div className='modal-empty-background' onClick={() => removeDropDownExpansions()}>
          &nbsp;
        </div>
      )}

      {!deleteButtonDisabled && (
        <div>
          <CloseIcon
            className='modal-icon modal-exit-icon'
            style={{ fontSize: 24 }}
            onClick={() => setIsTeamOrSquadModalOpen(false)}
          />

          <div className='modal-root-title'>
            {tabIndexToEdit !== undefined && teamsOrSquads
              ? Object.values(teamsOrSquads)[tabIndexToEdit]['name']
              : userConfig ? (isSquad ? staticLanguageMap['addNewSquad'][userConfig.language] : staticLanguageMap['addNewTeam'][userConfig.language]) : ''
            }
          </div>

          <div className='modal-divider modal-root-title-divider'>&nbsp;</div>
        </div>
      )}

      {!deleteButtonDisabled && (
        <div className='modal-content-section'>

          <div className='modal-section-title' style={{ marginTop: 18 }}>
            {userConfig && tabIndexToEdit !== undefined
              ? (isSquad ? staticLanguageMap['editSquadName'][userConfig.language] : staticLanguageMap['editTeamName'][userConfig.language])
              : ''
            }
            {userConfig && tabIndexToEdit === undefined
              ? (isSquad ? staticLanguageMap['provideSquadName'][userConfig.language] : staticLanguageMap['provideTeamName'][userConfig.language])
              : ''
            }
          </div>

          <div className='team-or-squad-modal-input-field'>
            <InputField
              searchString={searchString}
              onChangeInputField={onChangeSearchField}
              resetSearchString={() => setSearchString('')}
              onKeyDownInputField={undefined}
              defaultInput={userConfig ? staticLanguageMap['provideName'][userConfig.language] : ''}
              showDefaultInput={true}
              style={{ boxShadow: '0px 0px 2px 1px #15171e69' }}
            />
          </div>

          <div>
            <div className='modal-divider' style={{ marginTop: 14 }}>&nbsp;</div>

            <div className='modal-section-title'>
              {userConfig
                ? (tabIndexToEdit !== undefined
                  ? staticLanguageMap['editFormation'][userConfig.language]
                  : staticLanguageMap['selectFormation'][userConfig.language])
                : ''
              }
            </div>

            <div className='team-or-squad-modal-drop-down'>
              <DropDownSelect
                id={'team-or-squad-modal-formation-select'}
                dropDownOptions={formationOptions}
                selectedOptions={selectedFormation}
                setSelectedOptions={setSelectedFormation}
                isDropDownExpanded={isFormationDropDownExpanded}
                setIsDropDownExpanded={setIsFormationDropDownExpanded}
                defaultDropDownText={'selectFormation'}
                defaultDropDownTextColor={'#000000'}
                marginBetweenOptions={0}
                maxHeight={'50vh'}
                height={26}
              />
            </div>
          </div>

          {isSquad && tabIndexToEdit === undefined && (
            <div>
              <div className='modal-divider' style={{ marginTop: 40 }}>&nbsp;</div>

              <div className='modal-section-title'>
                {userConfig ? staticLanguageMap['loadPlayersFromTeam'][userConfig.language] : ''}
              </div>

              <div className='team-or-squad-modal-drop-down'>
                <DropDownSelect
                  id={'team-or-squad-modal-team-select'}
                  dropDownOptions={teams ? Object.keys(teams) : []}
                  selectedOptions={selectedTeam}
                  setSelectedOptions={setSelectedTeam}
                  isDropDownExpanded={isTeamDropDownExpanded}
                  setIsDropDownExpanded={setIsTeamDropDownExpanded}
                  defaultDropDownText={'selectTeam'}
                  defaultDropDownTextColor={'#000000'}
                  marginBetweenOptions={0}
                  maxHeight={'33vh'}
                  height={26}
                />
              </div>
            </div>
          )}

          <div
            className={
              'modal-button'
              + (tabIndexToEdit === undefined ? ' modal-button-middle' : ' modal-button-top')
              + (isButtonDisabled() ? ' modal-button-disabled' : '')
            }
            style={{ marginLeft: 150 }}
            onClick={async () => !isButtonDisabled() ? await handleAddOrEditClick() : null}
          >
            <div className='modal-button-text'>
              {getButtonText()}
            </div>

            {tabIndexToEdit === undefined && (
              <AddIcon
                className='modal-button-icon'
                style={{ fontSize: 24 }}
              />
            )}

            {tabIndexToEdit !== undefined && (
              <UpdateIcon
                className='modal-button-icon'
                style={{ fontSize: 24 }}
              />
            )}
          </div>

          {tabIndexToEdit !== undefined && (
            <div>
              <div className='modal-divider modal-bottom-divider'>&nbsp;</div>
              <div
                className='modal-button modal-button-bottom'
                style={{ marginLeft: 150 }}
                onClick={() => handleOpenConfirmModal(confirmDelete, isSquad ? 'deleteSquad' : 'deleteTeam', 'noRegretInfo')}
              >
                <div className='modal-button-text'>
                  {userConfig ? (isSquad ? staticLanguageMap['deleteSquad'][userConfig.language] : staticLanguageMap['deleteTeam'][userConfig.language]) : ''}
                </div>

                <DeleteOutlineIcon
                  className='modal-button-icon'
                  style={{ fontSize: 24 }}
                />
              </div>
            </div>
          )}

        </div>
      )}

    </div>
  );
};
