import '../../../platform.css';
import './../scouting.css';

import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { userSettingsState } from '../../../recoil/atoms/userSettingsState';
import { teamsState } from '../../../recoil/atoms/teamsState';

import SearchIcon from '@mui/icons-material/Search';

import {
  ClubIteration,
  FlexibleJsonMapping,
  Iteration,
  PlayerClubIterationsQueryOptions,
  PlayerOverviews,
  RatingRequirement
} from '../../../types';

import { AuthContextType, useAuthContext } from '../../../../common/contexts/AuthContext';
import { pagesPerChapter } from '../Scouting';
import { searchPlayerClubIterations } from '../../../services/fokusServer/playerClubIterations';
import { SeasonScoutingFilters } from './SeasonScoutingFilters';
import { staticLanguageMap } from '../../../../common/static/staticLanguageMap';
import { stringArraysContainSameElements } from '../../../utils/utils';
import { PlayerSeasonTable } from '../../../components/tables/playerSeasonTable/PlayerSeasonTable';
import { getPlayerOverviews } from '../../../services/fokusServer/playerOverviews';
import { getPlayerNumberIdsOnTeams } from '../../../utils/teamUtils';
import { commonSeasonTableMetrics, getNormalizedMetricName } from '../../../static/playerMetrics';
import { contractKeysToMonths } from '../../../static/propertyValues';


interface SeasonScoutingProps {
  playerData: PlayerOverviews | undefined;
  setPlayerData: (playerData: PlayerOverviews | undefined) => void;
}


export const SeasonScouting: React.FC<SeasonScoutingProps> = ({ playerData, setPlayerData }) => {

  const { currentUser } = useAuthContext() as AuthContextType;

  const userConfig = useRecoilValue(userConfigState);
  const userSettings = useRecoilValue(userSettingsState);
  const teams = useRecoilValue(teamsState);

  const [playerIdsOnCurrentPage, setPlayerIdsOnCurrentPage] = useState<number[]>([]);
  const [tableData, setTableData] = useState<FlexibleJsonMapping[]>([]);

  // currentModuloPage equals page % pagesPerChapter and is 1-indexed, while chapters are 0-indexed
  const [currentModuloPage, setCurrentModuloPage] = useState(0);
  const [currentChapter, setCurrentChapter] = useState(0);
  const [totalHits, setTotalHits] = useState(0);
  const [hitsPerPage, setHitsPerPage] = useState(50);

  const [selectedMinutes, setSelectedMinutes] = useState<string[]>([]);
  const [selectedMaxAge, setSelectedMaxAge] = useState<string[]>([]);
  const [selectedContractStatus, setSelectedContractStatus] = useState<string[]>([]);
  const [selectedPositions, setSelectedPositions] = useState<string[]>([]);
  const [selectedIterations, setSelectedIterations] = useState<Iteration[]>([]);
  const [selectedOrderBy, setSelectedOrderBy] = useState<string[]>(['club_rating']);

  const [selectedRatings, setSelectedRatings] = useState<RatingRequirement[]>([]);
  const [selectedNationalities, setSelectedNationalities] = useState<string[]>([]);
  const [selectedLogisticTeams, setSelectedLogisticTeams] = useState<string[]>([]);

  const [currentMinutes, setCurrentMinutes] = useState<string[]>([]);
  const [currentMaxAge, setCurrentMaxAge] = useState<string[]>([]);
  const [currentContractStatus, setCurrentContractStatus] = useState<string[]>([]);
  const [currentPositions, setCurrentPositions] = useState<string[]>([]);
  const [currentIterations, setCurrentIterations] = useState<Iteration[]>([]);
  const [currentOrderBy, setCurrentOrderBy] = useState<string[]>([]);

  const [currentRatings, setCurrentRatings] = useState<RatingRequirement[]>([]);
  const [currentNationalities, setCurrentNationalities] = useState<string[]>([]);
  const [currentLogisticTeams, setCurrentLogisticTeams] = useState<string[]>([]);

  const [isContractStatusToggled, setIsContractStatusToggled] = useState(false);
  const [isPositionsToggled, setIsPositionsToggled] = useState(false);

  const [currentIsContractStatusToggled, setCurrentIsContractStatusToggled] = useState(false);
  const [currentIsPositionsToggled, setCurrentIsPositionsToggled] = useState(false);
  const [currentOrderByToggle, setCurrentOrderByToggle] = useState(userSettings?.seasonStatsToggles?.orderBy ?? false);

  const [isLoading, setIsLoading] = useState(false);
  const [isEmptySearchResult, setIsEmptySearchResult] = useState(false);

  const [isAnyFilterExpandedToggle, setIsAnyFilterExpandedToggle] = useState(false);
  const [isAdvancedFiltersExpanded, setIsAdvancedFiltersExpanded] = useState(false);
  const [scoutingViewResultContainerTop, setScoutingViewResultContainerTop] = useState(85);

  const [searchWasLoaded, setSearchWasLoaded] = useState(false);


  const setCurrentFilters = (reset: boolean) => {
    setCurrentMinutes(reset ? [] : selectedMinutes);
    setCurrentMaxAge(reset ? [] : selectedMaxAge);
    setCurrentContractStatus(reset ? [] : selectedContractStatus);
    setCurrentPositions(reset ? [] : selectedPositions);
    setCurrentIterations(reset ? [] : selectedIterations);
    setCurrentOrderBy(reset ? [] : selectedOrderBy);

    setCurrentRatings(reset ? [] : selectedRatings);
    setCurrentNationalities(reset ? [] : selectedNationalities);
    setCurrentLogisticTeams(reset ? [] : selectedLogisticTeams);

    setCurrentIsContractStatusToggled(reset ? false : isContractStatusToggled);
    setCurrentIsPositionsToggled(reset ? false : isPositionsToggled);

    setCurrentOrderByToggle(userSettings?.seasonStatsToggles?.orderBy ?? currentOrderByToggle);
  };


  const handleSearchButtonPressed = async (isNewSearch: boolean, nextChapter?: number) => {

    setIsLoading(true);

    const nextPageToQuery = isNewSearch
      ? 1
      : (nextChapter !== undefined
        ? ((nextChapter * pagesPerChapter) + 1)
        : ((currentChapter * pagesPerChapter) + (currentModuloPage + 1)));

    const playerIds = teams && selectedLogisticTeams.length > 0 ? getPlayerNumberIdsOnTeams(teams, selectedLogisticTeams) : undefined;

    const query: PlayerClubIterationsQueryOptions = {
      maxAge: selectedMaxAge.length > 0 ? parseInt(selectedMaxAge[0]) : undefined,
      maxContractLength: selectedContractStatus.length > 0 ? contractKeysToMonths[selectedContractStatus[0]] : undefined,
      includeUnknownContracts: isContractStatusToggled,
      iterations: selectedIterations.length > 0 ? selectedIterations.map(iteration => iteration.iterationId) : undefined,
      primaryPositions: selectedPositions.length > 0 ? selectedPositions : undefined,
      includeSecondaryPosition: isPositionsToggled,

      minMinutesPlayed: selectedMinutes.length > 0
        ? String(selectedMinutes[0]).includes('%') ? undefined : parseInt(selectedMinutes[0])
        : undefined,

      minMinutesPlayedPercentage: selectedMinutes.length > 0
        ? String(selectedMinutes[0]).includes('%') ? (parseInt(selectedMinutes[0].replace('%', '')) / 100) : undefined
        : undefined,

      ratingRequirements: selectedRatings.length > 0 ? selectedRatings : undefined,
      countryCodes: selectedNationalities.length > 0 ? selectedNationalities : undefined,
      playerIds: playerIds,

      sortBy: selectedOrderBy.length > 0
        ? getNormalizedMetricName(selectedOrderBy[0])
        : undefined,
      sortByActualValues: userSettings?.seasonStatsToggles?.orderBy ?? false,

      page: nextPageToQuery,
    };

    try {
      const result: FlexibleJsonMapping | undefined = await searchPlayerClubIterations(currentUser, query);

      const totalHits = result?.total_hits;
      const hitsPerPage = result?.hits_per_page;
      const page = result?.current_page;
      const players: ClubIteration[] = result?.players ?? [];

      if (!result || totalHits === undefined || !page) {
        handleSearchReset();
        return;
      }

      setCurrentFilters(false);

      if (totalHits === 0 || (players.length === 0 && isNewSearch)) {
        setEmptySearchData();
        setIsEmptySearchResult(true);
      }
      else {
        const isFirstPageOfChapter = isNewSearch || nextChapter !== undefined;

        setPlayerIdsOnCurrentPage(players.map(player => player.player_id));

        setIsEmptySearchResult(false);
        setTableData(isFirstPageOfChapter ? players : [...tableData, ...players]);
        setTotalHits(totalHits);
        setHitsPerPage(hitsPerPage);
        setCurrentModuloPage(isFirstPageOfChapter ? 1 : (page - (currentChapter * 4)));
        setCurrentChapter(isNewSearch ? 0 : (nextChapter !== undefined ? nextChapter : currentChapter));
      }
    }
    catch (error) {
      console.log(error); // eslint-disable-line no-console
    }

    setIsLoading(false);
  };


  useEffect(() => {
    if (playerIdsOnCurrentPage.length > 0) {
      const playerIdsToQuery = playerIdsOnCurrentPage
        .filter(playerId => ((!playerData || !playerData[playerId]) && !isNaN(Number(playerId))))
        .map(playerId => Number(playerId));

      if (playerIdsToQuery.length > 0) {
        getPlayerOverviews(playerIdsToQuery, currentUser).then((result) => {
          if (result) {
            setPlayerData(playerData ? { ...playerData, ...result } : result);
          }
        });
      }
    }
    setSearchWasLoaded(false);
  }, [currentUser, playerIdsOnCurrentPage, setPlayerData]); // eslint-disable-line react-hooks/exhaustive-deps


  const handleChangeCurrentChapter = async (isIncrement: boolean) => {
    setTableData([]);
    const nextChapter = isIncrement ? currentChapter + 1 : currentChapter - 1;
    handleSearchButtonPressed(false, nextChapter);
  };


  const handleSearchReset = () => {
    setEmptySearchData();
    setCurrentFilters(true);
    setIsEmptySearchResult(false);
  };


  const setEmptySearchData = () => {
    setPlayerData(undefined);
    setTableData([]);
    setCurrentModuloPage(0);
    setCurrentChapter(0);
    setTotalHits(0);
    setHitsPerPage(0);
  };


  const handleAdvancedFiltersExpansion = () => {
    if (isAdvancedFiltersExpanded) {
      setScoutingViewResultContainerTop(85);
      setIsAdvancedFiltersExpanded(false);
    }
    else {
      setScoutingViewResultContainerTop(155);
      setIsAdvancedFiltersExpanded(true);
    }
  };


  const handleFilterUnexpansion = (filter: string) => {

    // if keeper was selected or deselected, the relevant filters must be reset if not compatible with the new position selection
    if (filter === 'positions') {
      if (selectedPositions.includes('GK') !== currentPositions.includes('GK')) {
        if (selectedOrderBy.length > 0 && !commonSeasonTableMetrics.includes(selectedOrderBy[0])) {
          setSelectedOrderBy(['club_rating']);
        }
        setSelectedRatings(selectedRatings.filter(rating => commonSeasonTableMetrics.includes(rating.metric)));
      }
    }

    if (tableData.length === 0 && !isEmptySearchResult) return;

    let shouldTriggerSearch = false;

    switch (filter) {
      case 'minutes':
        shouldTriggerSearch = !stringArraysContainSameElements(currentMinutes, selectedMinutes);
        break;

      case 'maxAge':
        shouldTriggerSearch = !stringArraysContainSameElements(currentMaxAge, selectedMaxAge);
        break;

      case 'contractStatus':
        shouldTriggerSearch = !stringArraysContainSameElements(currentContractStatus, selectedContractStatus)
          || currentIsContractStatusToggled !== isContractStatusToggled;
        break;

      case 'positions':
        shouldTriggerSearch = !stringArraysContainSameElements(currentPositions, selectedPositions)
          || currentIsPositionsToggled !== isPositionsToggled;
        break;

      case 'iterations':
        shouldTriggerSearch = !stringArraysContainSameElements(
          currentIterations.map(iteration => String(iteration.iterationId)), selectedIterations.map(iteration => String(iteration.iterationId))
        );
        break;

      case 'orderBy':
        shouldTriggerSearch = !stringArraysContainSameElements(currentOrderBy, selectedOrderBy)
          || currentOrderByToggle !== (userSettings?.seasonStatsToggles?.orderBy ?? false);
        break;

      case 'ratings':
        shouldTriggerSearch = !stringArraysContainSameElements(
          currentRatings.map(rating => rating.metric + '_' + rating.value), selectedRatings.map(rating => rating.metric + '_' + rating.value)
        );
        break;

      case 'nationalities':
        shouldTriggerSearch = !stringArraysContainSameElements(currentNationalities, selectedNationalities);
        break;

      case 'logisticTeams':
        shouldTriggerSearch = !stringArraysContainSameElements(currentLogisticTeams, selectedLogisticTeams);
        break;
    }

    if (shouldTriggerSearch) {
      handleSearchButtonPressed(true);
    }
  };


  useEffect(() => {
    if (searchWasLoaded) {
      handleSearchButtonPressed(true);
    }
    setSearchWasLoaded(false);
  }, [searchWasLoaded]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div className='scouting-view-content-container'>

      <div className='full-size-container absolute-container' id='season-scouting-empty-background' onClick={() => setIsAnyFilterExpandedToggle(false)}>
        &nbsp;
      </div>

      <SeasonScoutingFilters
        selectedMinutes={selectedMinutes}
        setSelectedMinutes={setSelectedMinutes}
        selectedMaxAge={selectedMaxAge}
        setSelectedMaxAge={setSelectedMaxAge}
        selectedContractStatus={selectedContractStatus}
        setSelectedContractStatus={setSelectedContractStatus}
        selectedPositions={selectedPositions}
        setSelectedPositions={setSelectedPositions}
        selectedIterations={selectedIterations}
        setSelectedIterations={setSelectedIterations}
        selectedOrderBy={selectedOrderBy}
        setSelectedOrderBy={setSelectedOrderBy}

        selectedRatings={selectedRatings}
        setSelectedRatings={setSelectedRatings}
        selectedNationalities={selectedNationalities}
        setSelectedNationalities={setSelectedNationalities}
        selectedLogisticTeams={selectedLogisticTeams}
        setSelectedLogisticTeams={setSelectedLogisticTeams}

        isContractStatusToggled={isContractStatusToggled}
        setIsContractStatusToggled={setIsContractStatusToggled}
        isPositionsToggled={isPositionsToggled}
        setIsPositionsToggled={setIsPositionsToggled}

        isAnyFilterExpandedToggle={isAnyFilterExpandedToggle}
        setIsAnyFilterExpandedToggle={setIsAnyFilterExpandedToggle}
        isAdvancedFiltersExpanded={isAdvancedFiltersExpanded}
        handleAdvancedFiltersExpansion={handleAdvancedFiltersExpansion}

        handleFilterUnexpansion={handleFilterUnexpansion}
        handleSearchReset={handleSearchReset}
        setSearchWasLoaded={setSearchWasLoaded}
      />

      <div
        className='scouting-view-result-container'
        style={{ top: scoutingViewResultContainerTop }}>
        {tableData.length > 0 && (
          <PlayerSeasonTable
            data={tableData}
            isLoading={isLoading}
            handleSearchButtonPressed={handleSearchButtonPressed}
            currentModuloPage={currentModuloPage}
            currentChapter={currentChapter}
            totalHits={totalHits}
            hitsPerPage={hitsPerPage}
            handleChangeCurrentChapter={handleChangeCurrentChapter}
            selectedOrderBy={selectedOrderBy.length > 0 ? selectedOrderBy[0] : undefined}
            isGoalkeeperSelected={selectedPositions.includes('GK')}
          />
        )}

        {tableData.length === 0 && isEmptySearchResult && !isLoading && (
          <div className='scouting-info-title fade-in'>
            {userConfig ? staticLanguageMap['noResult'][userConfig.language] : ''}
          </div>
        )}

        {tableData.length === 0 && !isEmptySearchResult && !isLoading && (
          <div className='scouting-info-button' onClick={() => handleSearchButtonPressed(true)}>
            <div className='scouting-info-button-text'>
              {userConfig ? staticLanguageMap['searchForSeasons'][userConfig.language] : ''}
            </div>
            <div className='scouting-info-button-icon'>
              <SearchIcon style={{ fontSize: 24, marginLeft: 20, marginTop: 8 }} />
            </div>
          </div>
        )}

      </div>
    </div>
  );
};
