import { atom } from 'recoil';
import { ScoutingConfig } from '../../types';


export const scoutingConfigState = atom<ScoutingConfig | null>({
  key: 'scoutingConfig',
  default: {
    savedSearches: [],
    alerts: [],
    playerIdsToExcludeFromAllAlerts: {},
  },
});
