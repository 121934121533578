/* eslint-disable max-len */
import './home.css';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, Element } from 'react-scroll';
import { AuthContextType, useAuthContext } from '../../../common/contexts/AuthContext';
import { useWindowSize } from '../../../common/hooks/WindowSize';

import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LanguageIcon from '@mui/icons-material/Language';
import EmailIcon from '@mui/icons-material/Email';
import CopyrightIcon from '@mui/icons-material/Copyright';

import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import InsightsIcon from '@mui/icons-material/Insights';

import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

import MergeIcon from '@mui/icons-material/Merge';
import EqualizerIcon from '@mui/icons-material/Equalizer';

import NO from 'country-flag-icons/react/3x2/NO';
import GB from 'country-flag-icons/react/3x2/GB';

import { NestedJsonMapping } from '../../../platform/types';
import { FokusLogo } from '../../svg/FokusLogo';

import platform_search from '../../../assets/images/platform_search.png';
import platform_own_team from '../../../assets/images/platform_own_team.png';
import platform_compare from '../../../assets/images/platform_compare.png';
import platform_report from '../../../assets/images/platform_report.png';
import sakariassen_image from '../../../assets/images/sakariassen_image.jpg';
import albert_goal_vs_ajax from '../../../assets/images/albert_goal_vs_ajax.jpeg';
import kjetil_image_cropped from '../../../assets/images/kjetil_image_cropped.jpg';

import glimt_logo from '../../../assets/clubLogos/glimt_logo.png';
import rbk_logo from '../../../assets/clubLogos/rbk_logo.png';
import viking_logo from '../../../assets/clubLogos/viking_logo.png';
import godset_logo from '../../../assets/clubLogos/godset_logo.png';
import til_logo from '../../../assets/clubLogos/til_logo.png';


const homeStaticLanguageMap: NestedJsonMapping = {
  'ourSolution': {
    'en': 'Our solution',
    'no': 'Vår løsning'
  },
  'OUR_SOLUTION': {
    'en': 'OUR SOLUTION',
    'no': 'VÅR LØSNING'
  },
  'caseStudy': {
    'en': 'Case study',
    'no': 'Case-studie'
  },
  'CASE_STUDY': {
    'en': 'CASE STUDY',
    'no': 'CASE-STUDIE'
  },
  'about': {
    'en': 'About us',
    'no': 'Om oss'
  },
  'ABOUT_US': {
    'en': 'ABOUT US',
    'no': 'OM OSS'
  },
  'login': {
    'en': 'Log in',
    'no': 'Logg inn'
  },
  'getStarted': {
    'en': 'Get started',
    'no': 'Kom i gang'
  },
  'oneClub': {
    'en': 'One club.',
    'no': 'En klubb.'
  },
  'onePlatform': {
    'en': 'One platform.',
    'no': 'En plattform.'
  },
  'bringClub': {
    'en': 'Bring club management, sporting director and scouts together.',
    'no': 'Samle klubbledelse, sportssjef og speidere i ett og samme system.'
  },
  'aHolistic': {
    'en': 'The first holistic club management system powered by world-class data.',
    'no': 'Det første helhetlige systemet for klubbdrift og spillerlogistikk.'
  },
  'trustedBy': {
    'en': 'TRUSTED BY TOP NORWEGIAN CLUBS',
    'no': 'BRUKT AV TOPPKLUBBER I NORGE'
  },
  'whatTheBiggest': {
    'en': 'What the biggest clubs have.',
    'no': 'Det de største klubbene har.'
  },
  'nowForYou': {
    'en': 'Now for you.',
    'no': 'Nå for dere.'
  },
  'fokusIsMore1': {
    'en': 'Clubs no longer need to break the bank on data expertise to gain an advantage.',
    'no': 'Klubber er ikke lenger avhengige av dyre ansettelser for å få en fordel.'
  },
  'fokusIsMore2': {
    'en': 'We help clubs of all sizes work smarter with player recruitment.',
    'no': 'Vi hjelper klubber i alle størrelser med spillerlogistikk.'
  },

  'strategy': {
    'en': 'Strategy',
    'no': 'Strategi'
  },
  'strategyTitle': {
    'en': 'Implement a robust strategy',
    'no': 'Implementer en robusst strategi'
  },
  'strategySubTitle': {
    'en': 'Clear leadership and continuity is key to success in the ever-changing football landscape.',
    'no': 'Tydelig lederskap og kontinuitet er nøkkelen til suksess i et stadig skiftende fotballandskap.'
  },
  'strategyBullet1': {
    'en': 'Get instant insights into squad composition and development.',
    'no': 'Få umiddelbare innsikter i troppssammensetning og utvikling.'
  },
  'strategyBullet2': {
    'en': 'Establish club ownership of scouting activities and transfer plans, ensuring robustness in the face of staff changes.',
    'no': 'Etablér klubbeierskap til speideraktiviteter og overgangsplaner for å sikre robusthet ved endringer i personell.'
  },
  'scouting': {
    'en': 'Scouting',
    'no': 'Scouting'
  },
  'scoutingTitle': {
    'en': 'Discover new talent',
    'no': 'Finn nye spillere'
  },
  'scoutingSubTitle': {
    'en': 'Gain an edge in the transfer market by identifying the ideal players for your club before anyone else.',
    'no': 'Få en fordel i overgangsmarkedet ved å identifisere ideelle spillere for klubben din før noen andre.'
  },
  'scoutingBullet1': {
    'en': 'Leverage advanced data models to quickly find the best statistical fits.',
    'no': 'Bruk avanserte datamodeller for å finne de beste statistiske matchene raskt.'
  },
  'scoutingBullet2': {
    'en': 'Track and compare player development and performance.',
    'no': 'Følg med på utvikling og prestasjoner over tid.'
  },
  'decisions': {
    'en': 'Decisions',
    'no': 'Beslutninger'
  },
  'decisionsTitle': {
    'en': 'Make well-informed decisions',
    'no': 'Ta informerte beslutninger'
  },
  'decisionsSubTitle': {
    'en': 'Combine data with your scouting expertise for a powerful, integrated approach to signings and squad management.',
    'no': 'Bruk data sammen klubbens speiderkompetanse til å ta bedre avgjørelser.'
  },
  'decisionsBullet1': {
    'en': 'Streamline your scouting workflow.',
    'no': 'Organiser speiderarbeidet for å frigjøre mer tid til å se spillere live.'
  },
  'decisionsBullet2': {
    'en': 'Reduce the risk of unsuccessful signings with data insights.',
    'no': 'Reduser risikoen for mislykkede signeringer med datainnsikt.'
  },
  'glimtUsesFokus': {
    'en': 'FK Bodø/Glimt uses Fokus to organize their recruitment operations.',
    'no': 'FK Bodø/Glimt bruker Fokus til å organisere spillerlogistikken.'
  },
  'sakariassenQuote1': {
    'en': '"Fokus is our ',
    'no': '"Fokus er vårt '
  },
  'sakariassenQuote2': {
    'en': 'most important tool for scouting and squad management',
    'no': 'viktigste verktøy for spillerlogistikk og klubbdrift'
  },
  'sakariassenQuote3': {
    'en': '. We are now better informed when making a signing."',
    'no': '. Vi er nå bedre informert når vi gjør en signering."'
  },
  'sportingDirector': {
    'en': 'Sporting Director',
    'no': 'Sportssjef'
  },
  'albertDescription': {
    'en': 'Albert Grønbæk celebrates scoring against Ajax in February 2024. Months later he became Bodø/Glimt´s most successful sale in history when he joined Rennes for a reported fee of 15 million Euros.',
    'no': 'Albert Grønbæk feirer scoring mot Ajax i februar 2024. Måneder senere ble han Bodø/Glimts mest vellykkede salg i historien da han ble solgt til Rennes for en rapportert sum på 15 millioner euro. '
  },
  'weHelpClubs': {
    'en': 'We help clubs work smarter.',
    'no': 'Vi hjelper klubber med å jobbe smartere.'
  },
  'howWeStarted': {
    'en': 'How we started',
    'no': 'Hvordan vi startet'
  },
  'howWeStartedParagraph1': {
    'en': 'For years, clubs like Liverpool and Midtjylland have been renowned for outsmarting their competition in the transfer market through data-driven strategies.',
    'no': 'I årevis har klubber som Liverpool og Midtjylland vært kjent for å overliste konkurrentene sine på overgangsmarkedet gjennom datadrevne strategier.'
  },
  'howWeStartedParagraph2': {
    'en': 'Inspired by these success stories, our journey began with three students pursuing a master’s degree in Artificial Intelligence. In their thesis they developed a state of the art machine learning model to identify top replacement players.',
    'no': 'Inspirert av disse suksesshistoriene, startet vår reise med tre studenter som tok en mastergrad i kunstig intelligens. I masteroppgaven utviklet de en avansert maskinlæringsmodell for å identifisere topp erstatningsspillere.'
  },
  'whatWeHaveLearned': {
    'en': 'What we have learned',
    'no': 'Hva vi har lært'
  },
  'whatWeHaveLearnedParagraph1': {
    'en': 'To fully harness the power of advanced data models, clubs first need an organized approach to player logistics.',
    'no': 'For å utnytte kraften i avanserte datamodeller fullt ut, trenger klubber først en organisert tilnærming til spillerlogistikk.'
  },
  'whatWeHaveLearnedParagraph2': {
    'en': 'That’s why we’ve created a tool that’s more than just a player database - it’s a new way of working.',
    'no': 'Derfor har vi utviklet et verktøy som er mer enn bare en spillerdatabase – det er en ny måte å jobbe på'
  },
  'whereWeAreGoing': {
    'en': 'Where we are going',
    'no': 'Hvor vi skal'
  },
  'whereWeAreGoingParagraph1': {
    'en': 'Data in football has come to stay. Together with our clients, we continuously improve our service, empowering clubs of all sizes to maximize their potential by working smarter.',
    'no': 'Data i fotball har kommet for å bli. Sammen med våre kunder forbedrer vi kontinuerlig tjenesten vår, slik at klubber i alle størrelser kan maksimere potensialet sitt ved å jobbe smartere.'
  },
  'finalQuote': {
    'en': 'As data becomes essential, we’ll give you the tools to stay ahead.',
    'no': 'Data i fotball har kommet for å bli - vi gir deg verktøyene du trenger for å lykkes.'
  },
  'interested': {
    'en': 'Interested?',
    'no': 'Interessert?'
  },
  'weAreHappy': {
    'en': 'We´re happy to demonstrate how we can support your club.',
    'no': 'Vi viser deg gjerne hvordan vi kan hjelpe klubben deres.'
  },
  'reachOut': {
    'en': 'Reach out and we´ll get you started on a trial.',
    'no': 'Ta kontakt med oss for å komme i gang med en prøveperiode.'
  },
  'privacyPolicy': {
    'en': 'Privacy Policy',
    'no': 'Personvernerklæring'
  },
  'privacyPolicyText': {
    'en': 'This site does not use cookies and does not process, collect, or store any personal information or usage data. If this changes in the future, we will update this privacy policy in advance.',
    'no': 'Denne nettsiden bruker ikke informasjonskapsler og samler ikke inn noe personlig informasjon eller annen data. Hvis dette endres i fremtiden, vil vi oppdatere denne personvernerklæringen på forhånd.'
  },
  'privacyPolicyPlatform': {
    'en': 'If you are a user of our platform, or are considering becoming one, we have a comprehensive Privacy Policy specific to the platform. We are committed to transparency and data protection, and we are happy to provide this policy upon request.',
    'no': 'Hvis du er bruker av plattformen vår, eller vurderer å bli det, har vi en omfattende personvernerklæring spesifikk for plattformen. Vi er opptatt av åpenhet og databeskyttelse, og vi sender deg gjerne denne erklæringen om ønskelig.'
  },
  'contact': {
    'en': 'Contact',
    'no': 'Kontakt'
  },
  'photoCredit': {
    'en': 'Photo: Kent Even Grundstad',
    'no': 'Foto: Kent Even Grundstad'
  },
};


export const Home = () => {

  const { currentUser } = useAuthContext() as AuthContextType;

  const { width } = useWindowSize();
  const isMobile = width < 900;
  const isTablet = width < 1050;

  const navigate = useNavigate();

  const [isTransparentHeader, setIsTransparentHeader] = useState(true);
  const [isHeaderMenuActive, setIsHeaderMenuActive] = useState(false);

  const [isLanguageMenuToggleDisabled, setIsLanguageMenuToggleDisabled] = useState(false);
  const [isLanguageMenuActive, setIsLanguageMenuActive] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('en');

  const [activeSolutionsTab, setActiveSolutionsTab] = useState('strategy');


  const [isPrivacyPolicyModalOpen, setIsPrivacyPolicyModalOpen] = useState(false);


  const toggleLanguageMenu = () => {
    if (isLanguageMenuToggleDisabled) return;

    setIsLanguageMenuToggleDisabled(true);
    setIsLanguageMenuActive(!isLanguageMenuActive);

    setTimeout(() => {
      setIsLanguageMenuToggleDisabled(false);
    }, 200);
  };

  const handleLanguageSelect = (language: string) => {
    setSelectedLanguage(language);
    setIsLanguageMenuActive(false);
  };


  const listenScrollEvent = () => {
    if (window.scrollY > 20) {
      setIsTransparentHeader(false);
    } else {
      setIsTransparentHeader(true);
    }
  };


  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);
    return () => window.removeEventListener('scroll', listenScrollEvent);
  }, []);


  useEffect(() => {
    setIsHeaderMenuActive(false);
  }, [isMobile]);


  useEffect(() => {
    if (currentUser) {
      navigate('/platform');
    }
  }, [currentUser, navigate]);


  useEffect(() => {
    const imageList = [platform_own_team, platform_compare, platform_report];
    imageList.forEach((src) => {
      const img = new Image();
      img.src = src;
    });
  }, []);


  const isWhiteLogo = isTransparentHeader && !isHeaderMenuActive;


  return (
    <div className='view-container-base' style={{ fontFamily: 'Sora', userSelect: 'text' }}>

      {isPrivacyPolicyModalOpen && (
        <div className='privacy-policy-modal-container fade-in' onClick={() => setIsPrivacyPolicyModalOpen(false)}>
          <div
            className='privacy-policy-modal'
            style={{ padding: isMobile ? '25px 35px' : 35 }}
            onClick={(e) => e.stopPropagation()}
          >

            <div className='privacy-policy-modal-close-icon'>
              <CloseIcon style={{ fontSize: 26 }} onClick={() => setIsPrivacyPolicyModalOpen(false)} />
            </div>

            <div className='privacy-policy-modal-title'>
              {homeStaticLanguageMap['privacyPolicy'][selectedLanguage]}
            </div>
            <div className='privacy-policy-modal-text' style={{ marginTop: isMobile ? 20 : 24 }}>
              {homeStaticLanguageMap['privacyPolicyText'][selectedLanguage]}
            </div>
            <div className='privacy-policy-modal-text' style={{ marginTop: isMobile ? 20 : 24 }}>
              {homeStaticLanguageMap['privacyPolicyPlatform'][selectedLanguage]}
            </div>
          </div>
        </div>
      )}

      {(isHeaderMenuActive || isLanguageMenuActive) && (
        <div className='empty-mobile-header-background' onClick={() => {
          setIsHeaderMenuActive(false);
          setIsLanguageMenuActive(false);
        }}>
          &nbsp;
        </div>
      )}

      <div
        className={
          'header-container' + (isTransparentHeader ? ' header-container-transparent' : '') + (isHeaderMenuActive ? ' header-container-menu-active' : '')
        }
      >

        <div className='header-content-container' style={{ userSelect: 'none' }}>
          <div className='header-section'>

            <Link
              to='home-image'
              smooth={true}
              duration={750}
              className='header-logo-section'
              onClick={() => setIsHeaderMenuActive(false)}
            >
              <div className={'header-logo' + (isWhiteLogo ? '' : ' header-logo-transparent')}>
                <FokusLogo whiteLogo={true} />
              </div>
              <div className='header-logo-text' style={{ fontFamily: 'Montserrat', color: isWhiteLogo ? '#ffffff' : '#000000' }}>
                FOKUS
              </div>
            </Link>

            {!isMobile && (
              <div className='header-tabs'>

                <Link to='section1' smooth={true} duration={750} offset={-80} className={'header-tab' + (isTransparentHeader ? ' header-tab-transparent' : '')}>
                  {homeStaticLanguageMap['ourSolution'][selectedLanguage]}
                </Link>

                <Link to='section2' smooth={true} duration={750} offset={-80} className={'header-tab' + (isTransparentHeader ? ' header-tab-transparent' : '')}>
                  {homeStaticLanguageMap['caseStudy'][selectedLanguage]}
                </Link>

                <Link to='section3' smooth={true} duration={750} offset={-80} className={'header-tab' + (isTransparentHeader ? ' header-tab-transparent' : '')}>
                  {homeStaticLanguageMap['about'][selectedLanguage]}
                </Link>

              </div>
            )}

            {!isMobile && (
              <div className='header-corner'>

                <div
                  onMouseEnter={() => setIsLanguageMenuActive(true)}
                  onMouseLeave={() => setIsLanguageMenuActive(false)}
                  className='header-language-icon-container'
                >
                  <div className='header-language-icon'>
                    <LanguageIcon style={{ fontSize: 20, color: isWhiteLogo ? '#ffffffcc' : '#141d25cc' }} onClick={toggleLanguageMenu} />
                  </div>

                  {isLanguageMenuActive && (
                    <div className='header-language-menu-path'>
                      &nbsp;
                    </div>
                  )}

                  {isLanguageMenuActive && (
                    <div className='header-language-menu fast-fade-in' style={{ padding: 16 }}>
                      <div className='header-language-menu-flag' onClick={() => handleLanguageSelect('no')}>
                        <NO style={{ borderRadius: 2 }} />
                        {selectedLanguage === 'en' && <div className='header-language-menu-flag-overlay' />}
                        {selectedLanguage === 'no' && <div className='header-language-menu-flag-overlay-selected' />}
                      </div>
                      <div className='header-language-menu-flag' onClick={() => handleLanguageSelect('en')}>
                        <GB style={{ borderRadius: 2 }} />
                        {selectedLanguage === 'no' && <div className='header-language-menu-flag-overlay' />}
                        {selectedLanguage === 'en' && <div className='header-language-menu-flag-overlay-selected' />}
                      </div>
                    </div>
                  )}
                </div>

                <div className='header-login-tab'>
                  <div className={'header-tab' + (isTransparentHeader ? ' header-tab-transparent' : '')} onClick={() => navigate('login')}>
                    {homeStaticLanguageMap['login'][selectedLanguage]}
                  </div>
                </div>

                <Link to='section4' smooth={true} duration={750} offset={-80}>
                  <div className={'header-trial-button' + (isTransparentHeader ? ' header-trial-button-transparent' : '')}>
                    {homeStaticLanguageMap['getStarted'][selectedLanguage]}

                    <div className='header-trial-button-icon'>
                      <KeyboardArrowRightIcon style={{ fontSize: 16 }} />
                    </div>
                  </div>
                </Link>

              </div>
            )}

            {isMobile && (
              <div className='header-corner'>

                <div
                  onMouseEnter={() => !isMobile ? setIsLanguageMenuActive(true) : null}
                  onMouseLeave={() => !isMobile ? setIsLanguageMenuActive(false) : null}
                  className='header-language-icon-container'
                >
                  <div className='header-language-icon'>
                    <LanguageIcon style={{ fontSize: 20, color: isWhiteLogo ? '#ffffffcc' : '#141d25cc' }} onClick={toggleLanguageMenu} />
                  </div>

                  {isLanguageMenuActive && (
                    <div className='header-language-menu-path'>
                      &nbsp;
                    </div>
                  )}

                  {isLanguageMenuActive && (
                    <div className='header-language-menu fast-fade-in' style={{ padding: '16px 26px' }}>
                      <div
                        className='header-language-menu-flag'
                        style={{ color: selectedLanguage === 'no' ? '#000000' : '#00000099' }}
                        onClick={() => handleLanguageSelect('no')}
                      >
                        Norsk
                      </div>
                      <div
                        className='header-language-menu-flag'
                        style={{ color: selectedLanguage === 'en' ? '#000000' : '#00000099' }}
                        onClick={() => handleLanguageSelect('en')}
                      >
                        English
                      </div>
                    </div>
                  )}
                </div>

                <div
                  className={'header-menu-icon' + (isTransparentHeader && !isHeaderMenuActive ? ' header-menu-icon-transparent' : '')}
                  onClick={() => setIsHeaderMenuActive(!isHeaderMenuActive)}>
                  {!isHeaderMenuActive && <MenuIcon style={{ fontSize: 26 }} />}
                  {isHeaderMenuActive && <CloseIcon style={{ fontSize: 26 }} />}
                </div>
              </div>
            )}

          </div>
        </div>

        {isHeaderMenuActive && (
          <div className='mobile-header-tabs'>
            <Link to='section1' smooth={true} duration={750} offset={-80} className='header-tab mobile-header-tab' onClick={() => setIsHeaderMenuActive(false)}>
              {homeStaticLanguageMap['ourSolution'][selectedLanguage]}
            </Link>

            <Link to='section2' smooth={true} duration={750} offset={-80} className='header-tab mobile-header-tab' onClick={() => setIsHeaderMenuActive(false)}>
              {homeStaticLanguageMap['caseStudy'][selectedLanguage]}
            </Link>

            <Link to='section3' smooth={true} duration={750} offset={-80} className='header-tab mobile-header-tab' onClick={() => setIsHeaderMenuActive(false)}>
              {homeStaticLanguageMap['about'][selectedLanguage]}
            </Link>

            <Link to='section4' smooth={true} duration={750} offset={-80} className='header-tab mobile-header-tab' onClick={() => setIsHeaderMenuActive(false)}>
              {homeStaticLanguageMap['contact'][selectedLanguage]}
            </Link>
          </div>
        )}

      </div>

      <Element name='home-image'>
        <div className={'home-image' + (isMobile ? ' home-image-mobile' : '')}>
          {!isMobile && (
            <div className='home-image-titles'>
              <div className='home-image-title'>
                {homeStaticLanguageMap['oneClub'][selectedLanguage]}
                &nbsp;
                {homeStaticLanguageMap['onePlatform'][selectedLanguage]}
              </div>
              <div className='home-image-sub-title'>
                {homeStaticLanguageMap['aHolistic'][selectedLanguage]}
              </div>
            </div>
          )}

          {isMobile && (
            <div className='home-image-titles'>
              <div className='home-image-title' style={{ fontSize: 40, marginTop: '1vh' }}>
                {homeStaticLanguageMap['oneClub'][selectedLanguage]}
                <br />
                {homeStaticLanguageMap['onePlatform'][selectedLanguage]}
              </div>
            </div>
          )}
        </div>

        <div className='home-main-section-container' style={{ marginTop: isMobile ? '-19vh' : '-18vh' }}>
          <div className={'home-main-section' + (isMobile ? ' home-main-section-mobile' : '')}>
            {!isMobile && (
              <div className='home-main-section-title'>
                {homeStaticLanguageMap['bringClub'][selectedLanguage]}
              </div>
            )}
            {isMobile && (
              <div className='home-main-section-title' style={{ fontSize: 20, width: '90%', marginTop: 15 }}>
                {homeStaticLanguageMap['aHolistic'][selectedLanguage]}
              </div>
            )}
            <div className='home-main-section-image-container'>
              <img src={platform_search} style={{ boxShadow: '0px 0px 10px 1px #10151f20' }} />
            </div>

            <div className='home-main-section-partners-container' style={{ marginTop: isMobile ? 60 : 80 }}>
              <div className='home-main-section-partners-section'>
                <div className='home-section-small-title'>
                  {homeStaticLanguageMap['trustedBy'][selectedLanguage]}
                </div>
                <div className='home-main-section-partners' style={{ marginTop: isMobile ? 2 : 16 }}>
                  <div className='home-main-section-partner-container' style={{ height: isMobile ? '50%' : '100%' }}>
                    <img src={godset_logo} style={{ height: '69%', maxHeight: 100 }} />
                  </div>
                  <div className='home-main-section-partner-container' style={{ height: isMobile ? '50%' : '100%' }}>
                    <img src={viking_logo} style={{ height: '59%', maxHeight: 87 }} />
                  </div>
                  <div className='home-main-section-partner-container' style={{ height: isMobile ? '50%' : '100%' }}>
                    <img src={glimt_logo} style={{ height: '72%', maxHeight: 107 }} />
                  </div>
                  <div className='home-main-section-partner-container' style={{ height: isMobile ? '50%' : '100%' }}>
                    <img src={rbk_logo} style={{ height: '55%', maxHeight: 80 }} />
                  </div>
                  <div className='home-main-section-partner-container' style={{ height: isMobile ? '50%' : '100%' }}>
                    <img src={til_logo} style={{ height: '69%', maxHeight: 100 }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Element>

      <Element name='section1'>
        <div className='home-section-container home-section-solution-container'>
          <div className='home-section'>
            <div className='home-section-small-title'>
              {homeStaticLanguageMap['OUR_SOLUTION'][selectedLanguage]}
            </div>
            <div className='home-section-title' style={{ fontSize: isMobile ? 22 : undefined }}>
              {homeStaticLanguageMap['whatTheBiggest'][selectedLanguage]}
              <br />
              {homeStaticLanguageMap['nowForYou'][selectedLanguage]}
            </div>
            <div className='home-section-sub-title' style={{ fontSize: isMobile ? 16 : undefined }}>
              {homeStaticLanguageMap['fokusIsMore1'][selectedLanguage]}
              {!isMobile && <br />}
              {isMobile && ' '}
              {homeStaticLanguageMap['fokusIsMore2'][selectedLanguage]}
            </div>

            <div
              className='home-section-solutions-tabs'
              style={{ userSelect: 'none', fontSize: isMobile ? 14 : 16, height: isMobile ? 70 : 100, paddingTop: isMobile ? 10 : 0 }}
            >
              <div
                className={'home-section-solutions-tab' + (activeSolutionsTab === 'strategy' ? ' home-section-solutions-tab-active' : '')}
                style={{ width: isMobile ? 110 : 130 }}
                onClick={() => setActiveSolutionsTab('strategy')}
              >
                {homeStaticLanguageMap['strategy'][selectedLanguage]}
              </div>
              <div
                className={'home-section-solutions-tab' + (activeSolutionsTab === 'scouting' ? ' home-section-solutions-tab-active' : '')}
                style={{ width: isMobile ? 110 : 130 }}
                onClick={() => setActiveSolutionsTab('scouting')}
              >
                {homeStaticLanguageMap['scouting'][selectedLanguage]}
              </div>
              <div
                className={'home-section-solutions-tab' + (activeSolutionsTab === 'decisions' ? ' home-section-solutions-tab-active' : '')}
                style={{ width: isMobile ? 110 : 130 }}
                onClick={() => setActiveSolutionsTab('decisions')}
              >
                {homeStaticLanguageMap['decisions'][selectedLanguage]}
              </div>
            </div>

            <div className='home-section-box-view' style={{ flexDirection: isMobile ? 'column' : 'row' }}>
              {activeSolutionsTab === 'strategy' && (
                <div
                  className='home-section-box-view-section fade-in'
                  style={{ paddingLeft: 20, paddingRight: 20, width: isMobile ? '100%' : undefined, textAlign: isMobile ? 'center' : undefined }}
                >
                  <div
                    className='home-section-box-view-title'
                    style={{ fontSize: isMobile ? 20 : undefined }}
                  >
                    {homeStaticLanguageMap['strategyTitle'][selectedLanguage]}
                  </div>
                  <div
                    className='home-section-box-view-text'
                    style={{ fontSize: isMobile ? 15 : undefined }}
                  >
                    {homeStaticLanguageMap['strategySubTitle'][selectedLanguage]}
                  </div>
                  <div
                    className='home-section-box-view-bullet-row'
                    style={{ marginTop: isMobile ? 6 : 10, fontSize: isMobile ? 14 : undefined }}
                  >
                    <DonutLargeIcon style={{ fontSize: 28, marginRight: 16, color: '#0066ff' }} />
                    {homeStaticLanguageMap['strategyBullet1'][selectedLanguage]}
                  </div>
                  <div
                    className='home-section-box-view-bullet-row'
                    style={{ marginTop: isMobile ? 6 : 10, fontSize: isMobile ? 14 : undefined }}
                  >
                    <InsightsIcon style={{ fontSize: 28, marginRight: 16, color: '#0066ff' }} />
                    {homeStaticLanguageMap['strategyBullet2'][selectedLanguage]}
                  </div>
                </div>
              )}
              {activeSolutionsTab === 'scouting' && (
                <div
                  className='home-section-box-view-section fade-in'
                  style={{ paddingLeft: 20, paddingRight: 20, width: isMobile ? '100%' : undefined, textAlign: isMobile ? 'center' : undefined }}
                >
                  <div
                    className='home-section-box-view-title'
                    style={{ fontSize: isMobile ? 20 : undefined }}
                  >
                    {homeStaticLanguageMap['scoutingTitle'][selectedLanguage]}
                  </div>
                  <div
                    className='home-section-box-view-text'
                    style={{ fontSize: isMobile ? 15 : undefined }}
                  >
                    {homeStaticLanguageMap['scoutingSubTitle'][selectedLanguage]}
                  </div>
                  <div
                    className='home-section-box-view-bullet-row'
                    style={{ marginTop: isMobile ? 6 : 10, fontSize: isMobile ? 14 : undefined }}
                  >
                    <PersonSearchIcon style={{ fontSize: 28, marginRight: 16, color: '#0066ff' }} />
                    {homeStaticLanguageMap['scoutingBullet1'][selectedLanguage]}
                  </div>
                  <div
                    className='home-section-box-view-bullet-row'
                    style={{ marginTop: isMobile ? 6 : 10, fontSize: isMobile ? 14 : undefined }}
                  >
                    <QueryStatsIcon style={{ fontSize: 28, marginRight: 16, color: '#0066ff' }} />
                    {homeStaticLanguageMap['scoutingBullet2'][selectedLanguage]}
                  </div>
                </div>
              )}
              {activeSolutionsTab === 'decisions' && (
                <div
                  className='home-section-box-view-section fade-in'
                  style={{ paddingLeft: 20, paddingRight: 20, width: isMobile ? '100%' : undefined, textAlign: isMobile ? 'center' : undefined }}
                >
                  <div
                    className='home-section-box-view-title'
                    style={{ fontSize: isMobile ? 20 : undefined }}
                  >
                    {homeStaticLanguageMap['decisionsTitle'][selectedLanguage]}
                  </div>
                  <div
                    className='home-section-box-view-text'
                    style={{ fontSize: isMobile ? 15 : undefined }}
                  >
                    {homeStaticLanguageMap['decisionsSubTitle'][selectedLanguage]}
                  </div>
                  <div
                    className='home-section-box-view-bullet-row'
                    style={{ marginTop: isMobile ? 6 : 10, fontSize: isMobile ? 14 : undefined }}
                  >
                    <MergeIcon style={{ fontSize: 28, marginRight: 16, color: '#0066ff' }} />
                    {homeStaticLanguageMap['decisionsBullet1'][selectedLanguage]}
                  </div>
                  <div
                    className='home-section-box-view-bullet-row'
                    style={{ marginTop: isMobile ? 6 : 10, fontSize: isMobile ? 14 : undefined }}
                  >
                    <EqualizerIcon style={{ fontSize: 28, marginRight: 16, color: '#0066ff' }} />
                    {homeStaticLanguageMap['decisionsBullet2'][selectedLanguage]}
                  </div>
                </div>
              )}
              <div className='home-section-box-view-section' style={{ width: isMobile ? '90%' : undefined }}>
                <img
                  src={
                    activeSolutionsTab === 'strategy'
                      ? platform_own_team
                      : activeSolutionsTab === 'scouting'
                        ? platform_compare
                        : platform_report
                  }
                  style={{ borderRadius: 6, boxShadow: '0px 0px 10px 1px #10151f12', marginTop: isMobile ? 14 : 0 }}
                />
              </div>
            </div>
          </div>
        </div>
      </Element>

      <Element name='section2'>
        <div className='home-section-container home-section-case-study-container'>
          <div className='home-section'>
            <div className='home-section-small-title'>
              {homeStaticLanguageMap['CASE_STUDY'][selectedLanguage]}
            </div>
            <div className='home-section-title' style={{ fontSize: isMobile ? 22 : undefined }}>
              {homeStaticLanguageMap['glimtUsesFokus'][selectedLanguage]}
            </div>

            <div
              className='home-section-box-view'
              style={{ marginTop: 25, paddingTop: 30, paddingBottom: 30, flexDirection: isMobile ? 'column' : 'row' }}
            >
              <div className={isMobile ? 'home-section-box-view-quote-section-mobile' : 'home-section-box-view-quote-section'}>
                <div
                  className='home-section-box-view-sub-title'
                  style={{ fontSize: isMobile ? 16 : undefined }}
                >
                  {homeStaticLanguageMap['sakariassenQuote1'][selectedLanguage]}
                  <b>{homeStaticLanguageMap['sakariassenQuote2'][selectedLanguage]}</b>
                  {homeStaticLanguageMap['sakariassenQuote3'][selectedLanguage]}
                </div>
              </div>
              <img
                src={sakariassen_image}
                style={{
                  width: 100,
                  marginTop: isMobile ? 25 : 0,
                  borderRadius: 50,
                  border: 'solid 1px #00000010',
                  boxShadow: '0px 0px 6px 1px #00000010'
                }}
              />
              <div style={{ textAlign: isMobile ? 'center' : 'left', marginLeft: isMobile ? undefined : 12, marginTop: isMobile ? 8 : 0 }}>
                <div style={{ marginTop: 2, fontSize: 14, fontWeight: 500 }}>
                  Håvard Sakariassen
                </div>

                <div style={{ marginTop: 2, fontSize: 12 }}>
                  {homeStaticLanguageMap['sportingDirector'][selectedLanguage]}, FK Bodø/Glimt
                </div>
              </div>
            </div>

            <img
              src={albert_goal_vs_ajax}
              style={{ width: isMobile ? '100%' : '90%', borderRadius: 50, marginTop: 50, boxShadow: '0px 0px 10px 1px #10151f20' }}
            />

            <div
              style={{
                marginTop: isMobile ? 15 : 25,
                fontSize: isMobile ? 13 : 15,
                textAlign: 'left',
                padding: '0px 75px',
                width: isMobile ? '92%' : undefined
              }}
            >
              {homeStaticLanguageMap['albertDescription'][selectedLanguage]}
            </div>
          </div>
        </div>
      </Element>

      <Element name='section3'>
        <div className='home-section-container home-section-about-container'>
          <div className='home-section'>
            <div className='home-section-small-title'>
              {homeStaticLanguageMap['ABOUT_US'][selectedLanguage]}
            </div>
            <div className='home-section-title' style={{ fontSize: isMobile ? 22 : undefined }}>
              {homeStaticLanguageMap['weHelpClubs'][selectedLanguage]}
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: isTablet ? 'center' : 'space-between', marginTop: 50 }}>
              {!isTablet && (
                <img
                  src={kjetil_image_cropped}
                  style={{ width: '42%', borderRadius: 50, boxShadow: '0px 0px 10px 1px #10151f12' }}
                />
              )}

              <div
                className='home-section-small-box-view-column'
                style={{ width: isTablet ? '98%' : '53%', gap: isTablet ? 26 : 16 }}
              >
                <div
                  className='home-section-small-box-view'
                  style={{ padding: !isTablet && width < 1250 ? '10px 15px' : isMobile ? 20 : 25 }}
                >
                  <div
                    className='home-section-box-view-title'
                    style={{ fontSize: isMobile ? 18 : undefined }}
                  >
                    {homeStaticLanguageMap['howWeStarted'][selectedLanguage]}
                  </div>
                  <div style={{ marginTop: !isTablet && width < 1150 ? 10 : 16, fontSize: isMobile ? 15 : undefined }}>
                    {homeStaticLanguageMap['howWeStartedParagraph1'][selectedLanguage]}
                  </div>
                  <div style={{ marginTop: !isTablet && width < 1150 ? 10 : 16, fontSize: isMobile ? 15 : undefined }}>
                    {homeStaticLanguageMap['howWeStartedParagraph2'][selectedLanguage]}
                  </div>
                </div>

                <div
                  className='home-section-small-box-view'
                  style={{ padding: !isTablet && width < 1250 ? '10px 15px' : isMobile ? 20 : 25 }}
                >
                  <div
                    className='home-section-box-view-title'
                    style={{ fontSize: isMobile ? 18 : undefined }}
                  >
                    {homeStaticLanguageMap['whatWeHaveLearned'][selectedLanguage]}
                  </div>
                  <div style={{ marginTop: !isTablet && width < 1150 ? 10 : 16, fontSize: isMobile ? 15 : undefined }}>
                    {homeStaticLanguageMap['whatWeHaveLearnedParagraph1'][selectedLanguage]}
                  </div>
                  <div style={{ marginTop: !isTablet && width < 1150 ? 10 : 16, fontSize: isMobile ? 15 : undefined }}>
                    {homeStaticLanguageMap['whatWeHaveLearnedParagraph2'][selectedLanguage]}
                  </div>
                </div>

                <div
                  className='home-section-small-box-view'
                  style={{ padding: !isTablet && width < 1250 ? '10px 15px' : isMobile ? 20 : 25 }}
                >
                  <div
                    className='home-section-box-view-title'
                    style={{ fontSize: isMobile ? 18 : undefined }}
                  >
                    {homeStaticLanguageMap['whereWeAreGoing'][selectedLanguage]}
                  </div>
                  <div style={{ marginTop: !isTablet && width < 1150 ? 10 : 16, fontSize: isMobile ? 15 : undefined }}>
                    {homeStaticLanguageMap['whereWeAreGoingParagraph1'][selectedLanguage]}
                  </div>
                </div>
              </div>
            </div>

            {isTablet && (
              <img
                src={kjetil_image_cropped}
                style={{ width: '92%', maxWidth: 500, marginTop: 35, borderRadius: 50, boxShadow: '0px 0px 10px 1px #10151f12' }}
              />
            )}
          </div>
        </div>
      </Element>

      <Element name='section4'>
        <div className='home-section-container home-section-contact-container'>
          <div className='home-section'>
            <div className='home-section-title-quote' style={{ fontSize: isMobile ? 24 : undefined }}>
              {homeStaticLanguageMap['finalQuote'][selectedLanguage]}
            </div>
            <div style={{ fontSize: 24, fontWeight: 500, marginTop: 90 }}>
              {homeStaticLanguageMap['interested'][selectedLanguage]}
            </div>
            <div style={{ fontSize: isMobile ? 16 : 20, marginTop: 40 }}>
              {homeStaticLanguageMap['weAreHappy'][selectedLanguage]}

              {isMobile && ' '}
              {isMobile && homeStaticLanguageMap['reachOut'][selectedLanguage]}
            </div>

            {!isMobile && (
              <div style={{ fontSize: isMobile ? 16 : 20, marginTop: 25 }}>
                {homeStaticLanguageMap['reachOut'][selectedLanguage]}
              </div>
            )}

            <div className='home-section-contact-row' style={{ marginTop: 70, marginBottom: 40, fontSize: isMobile ? 16 : undefined }}>
              <EmailIcon style={{ fontSize: isMobile ? 24 : 28, color: '#1f222cdd', marginRight: 10, marginLeft: -10, marginTop: -1 }} />
              lars.gundersen@fokus.ing
            </div>
          </div>
        </div>
      </Element>

      <div className='footer-container'>
        {!isMobile && (
          <div className='footer'>
            <div className='footer-text-container flex-column'>
              <div className='flex-row' style={{ marginBottom: 6 }}>
                <CopyrightIcon style={{ fontSize: 15, marginRight: 3 }} />
                2024 Fokus. All rights reserved.
              </div>
              {homeStaticLanguageMap['photoCredit'][selectedLanguage]}
            </div>
            <div style={{ width: 100, height: 100 }}>
              <FokusLogo whiteLogo={true} />
            </div>
            <div className='footer-text-container' style={{ justifyContent: 'right' }}>
              <div className='footer-link' onClick={() => setIsPrivacyPolicyModalOpen(true)}>
                Privacy policy
              </div>
            </div>
          </div>
        )}
        {isMobile && (
          <div className='footer' style={{ flexDirection: 'column' }}>
            <div style={{ width: 100, height: 100 }}>
              <FokusLogo whiteLogo={true} />
            </div>
            <div className='footer-text-container' style={{ justifyContent: 'center', marginTop: 15 }}>
              <div className='footer-link' onClick={() => setIsPrivacyPolicyModalOpen(true)}>
                Privacy policy
              </div>
            </div>
            <div className='footer-text-container' style={{ justifyContent: 'center', marginTop: 20, width: '90%' }}>
              <CopyrightIcon style={{ fontSize: 15, marginRight: 3 }} />
              2024 Fokus. All rights reserved.
            </div>
            <div className='footer-text-container' style={{ justifyContent: 'center', marginTop: 20, width: '90%' }}>
              {homeStaticLanguageMap['photoCredit'][selectedLanguage]}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
