import axios from 'axios';

import { FirebaseUser } from '../../../firebase';
import { FlexibleJsonMapping, PlayerClubIterationsQueryOptions } from '../../types';

import { BASE_URL } from './constants';
import { getBearerToken } from './token';

import { getNormalizedMetricName } from '../../static/playerMetrics';


// Search for player club iterations
export const searchPlayerClubIterations = async (
  user: FirebaseUser,
  queryOptions: PlayerClubIterationsQueryOptions
): Promise<FlexibleJsonMapping | undefined> => {

  // if the array is undefined, we don't concern with any player ids, but if the array is empty, we attempt to search for an empty set of players
  if (queryOptions.playerIds && queryOptions.playerIds.length === 0) {
    return {
      total_hits: 0,
      current_page: 1,
      players: [],
    };
  }

  const token = await getBearerToken(user);

  const primaryPositionsString = queryOptions.primaryPositions ? queryOptions.primaryPositions.join(',') : undefined;

  const iterationsString = queryOptions.iterations ? queryOptions.iterations.join(',') : undefined;

  const countryCodesString = queryOptions.countryCodes ? queryOptions.countryCodes.join(',') : undefined;

  const ratingRequirementsString = queryOptions.ratingRequirements
    ? queryOptions.ratingRequirements.map(({ metric, value }) => `${getNormalizedMetricName(metric)}:${value}`).join(',')
    : undefined;

  const correctSortBy = queryOptions.sortBy
    ? (queryOptions.sortByActualValues ? queryOptions.sortBy.replace(/_normalized$/, '') : queryOptions.sortBy)
    : undefined;

  const playerIdsString = queryOptions.playerIds ? queryOptions.playerIds.join(',') : undefined;


  const result = await axios.get(`${BASE_URL}/search/playerClubIterations/`, {
    headers: { Authorization: token },
    params: {
      min_age: queryOptions.minAge,
      max_age: queryOptions.maxAge,
      max_contract_length: queryOptions.maxContractLength,
      include_unknown_contracts: queryOptions.includeUnknownContracts,
      primary_positions: primaryPositionsString,
      include_secondary_position: queryOptions.includeSecondaryPosition,
      iterations: iterationsString,
      min_minutes_played: queryOptions.minMinutesPlayed,
      max_minutes_played: queryOptions.maxMinutesPlayed,
      min_minutes_played_percentage: queryOptions.minMinutesPlayedPercentage,
      max_minutes_played_percentage: queryOptions.maxMinutesPlayedPercentage,
      country_codes: countryCodesString,
      player_ids: playerIdsString,
      rating_requirements: ratingRequirementsString,
      sort_by: correctSortBy,
      page: queryOptions.page,
    },
  });

  if (!result) return undefined;

  return result.data;
};
