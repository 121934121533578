import '../../platform.css';
import './scouting.css';

import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { selectedPlayerState } from '../../recoil/atoms/selectedPlayerState';
// import { scoutingConfigState } from '../../recoil/atoms/scoutingConfigState';

import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import NotificationsIcon from '@mui/icons-material/Notifications';

import { PlayerOverviews } from '../../types';
import { PlayerView } from '../../components/playerView/PlayerView';
import { FixedTabLine } from '../../components/tabLines/FixedTabLine';
import { PlayerScouting } from './playerScouting/PlayerScouting';
import { SeasonScouting } from './seasonScouting/SeasonScouting';
import { staticLanguageMap } from '../../../common/static/staticLanguageMap';
// import { Alerts } from './alerts/Alerts';


export const pagesPerChapter = 4;


export const alertPageSize = 100;


export const Scouting = () => {

  const userConfig = useRecoilValue(userConfigState);
  const selectedPlayer = useRecoilValue(selectedPlayerState);

  // const scoutingConfig = useRecoilValue(scoutingConfigState);

  const [activeTab, setActiveTab] = useState(0);
  const tabOptions = [
    'playerSearch',
    'seasonSearch',
    'alerts'
  ];
  const tabIcons = [
    <PersonSearchIcon key={'person-search-icon'} style={{ fontSize: 21 }} />,
    <QueryStatsIcon key={'query-stats-icon'} style={{ fontSize: 21 }} />,
    <NotificationsIcon key={'notifications-icon'} style={{ fontSize: 21 }} />
  ];

  const [playerData, setPlayerData] = useState<PlayerOverviews | undefined>(undefined);


  // const numberOfNewPlayersSinceLastViewed = scoutingConfig !== null && scoutingConfig.alerts
  //   ? scoutingConfig.alerts.reduce((acc, alert) => acc + alert.numberOfNewPlayersSinceLastViewed, 0)
  //   : 0;


  return (
    <div className='platform-view-section'>

      <div className={'player-view-outer-container' + (selectedPlayer ? ' player-view-outer-container-player-selected' : '')}>
        {selectedPlayer && playerData && (
          <PlayerView
            playerOverview={playerData[Number(selectedPlayer.id)]}
          />
        )}
      </div>

      <FixedTabLine
        tabOptions={tabOptions}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabIcons={tabIcons}
      // numberOfNewPlayersSinceLastViewed={numberOfNewPlayersSinceLastViewed}
      />

      {activeTab === 0 && (
        <PlayerScouting
          playerData={playerData}
          setPlayerData={setPlayerData}
        />
      )}

      {activeTab === 1 && (
        <SeasonScouting
          playerData={playerData}
          setPlayerData={setPlayerData}
        />
      )}

      {activeTab === 2 && (
        <div className='scouting-view-content-container'>
          <div style={{ color: '#ffffffd7', margin: 'auto', fontSize: 24 }}>
            {userConfig ? staticLanguageMap['underDevelopment'][userConfig.language] : ''}
          </div>
        </div>
      )}

      {/* {activeTab === 2 && (
        <Alerts
          setPlayerData={setPlayerData}
        />
      )} */}

    </div >
  );
};
