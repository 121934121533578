import './playerView.css';

import { useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { selectedPlayerState } from '../../recoil/atoms/selectedPlayerState';
import { playersState } from '../../recoil/atoms/playersState';
import { teamsState } from '../../recoil/atoms/teamsState';
import { squadsState } from '../../recoil/atoms/squadsState';
import { playerTeamDataState } from '../../recoil/selectors/playerTeamDataState';
import { playerSquadDataState } from '../../recoil/selectors/playerSquadDataState';
import { competitionsState } from '../../recoil/atoms/competitionsState';
import { useAddPlayerDetails } from '../../recoil/hooks/addPlayerDetails';
import { globalModalPropsState } from '../../recoil/atoms/globalModalPropsState';
import { useGlobalModal } from '../../recoil/hooks/openGlobalModal';
import { AuthContextType, useAuthContext } from '../../../common/contexts/AuthContext';

import Dialog from '@mui/material/Dialog';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ConstructionIcon from '@mui/icons-material/Construction';
import AddIcon from '@mui/icons-material/Add';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import TimelineIcon from '@mui/icons-material/Timeline';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import SummarizeIcon from '@mui/icons-material/Summarize';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import EditIcon from '@mui/icons-material/Edit';
// import GraphicEqIcon from '@mui/icons-material/GraphicEq';
// import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
// import TrackChangesIcon from '@mui/icons-material/TrackChanges';
// import LineAxisIcon from '@mui/icons-material/LineAxis';

import { getClubColor } from '../../utils/configUtils';
import { FlexibleJsonMapping, PlayerReport } from '../../types';
import { staticLanguageMap } from '../../../common/static/staticLanguageMap';
import { FixedTabLine } from '../tabLines/FixedTabLine';
import { PlayerViewOverview } from './PlayerViewOverview';
import { PlayerViewCompare } from './PlayerViewCompare';
import { PlayerViewCareer } from './PlayerViewCareer';
import { PlayerViewReports } from './PlayerViewReports';
import { getReportsForPlayer } from '../../services/firestore/reports';
import { PlayerHistoryModal } from '../modals/PlayerHistoryModal';
import { getCompletePlayerHistory } from '../../services/firestore/activities';
import { staticTeamKeys } from '../../static/propertyValues';
import { countryCodeToCountryInfo } from '../../static/countries';
import { getPlayerOverviews } from '../../services/fokusServer/playerOverviews';
import { EditPlayerInfoModal } from '../modals/EditPlayerInfoModal';
import { getRoleIcon, getTeamIcon } from '../../utils/iconUtils';
import { PlayerViewWithoutData } from './PlayerViewWithoutData';
import {
  getAge,
  getDateLocaleFormat,
  getDisplayCompetition,
  getDisplayPosition,
  getPlayerPropertyValue
} from '../../utils/playerUtils';


interface PlayerViewProps {
  playerOverview: FlexibleJsonMapping | undefined;
  squadId?: string | undefined; // the squadKey of the squad from which the player is clicked (player may be in the squad or in the AddPlayerView table)
  squadName?: string | undefined;
}

export const PlayerView: React.FC<PlayerViewProps> = ({ playerOverview, squadId, squadName }) => {

  const { currentUser } = useAuthContext() as AuthContextType;

  const userConfig = useRecoilValue(userConfigState);
  const competitions = useRecoilValue(competitionsState);

  const setGlobalModalProps = useSetRecoilState(globalModalPropsState);
  const { handleOpenPlayerViewModal } = useGlobalModal();

  const players = useRecoilValue(playersState);
  const [selectedPlayer, setSelectedPlayer] = useRecoilState(selectedPlayerState);

  // note: this depends on the current ID format, and could alternatively be determined from the playersState with some added delay (?)
  const isNewPlayerWithoutData: boolean = selectedPlayer !== undefined
    && isNaN(Number(selectedPlayer.id))
    && selectedPlayer.id === 'newPlayerWithoutData';

  const isExistingPlayerWithoutData: boolean = selectedPlayer !== undefined
    && isNaN(Number(selectedPlayer.id))
    && players !== null
    && selectedPlayer.id in players;

  const isDeletedPlayerWithoutData: boolean = selectedPlayer !== undefined
    && isNaN(Number(selectedPlayer.id))
    && selectedPlayer.id !== 'newPlayerWithoutData'
    && players !== null && !(selectedPlayer.id in players);


  const tabOptions = ['overview', 'compare', 'career', 'reports'];
  const tabIcons = [
    <EqualizerIcon key={'equalizer-icon'} style={{ fontSize: 21 }} />,
    <SsidChartIcon key={'ssid-icon'} style={{ fontSize: 21, marginTop: 0.5 }} />,
    <TimelineIcon key={'timeline-icon'} style={{ fontSize: 21 }} />,
    <SummarizeIcon key={'summarize-icon'} style={{ fontSize: 21 }} />
  ];
  const [activeTab, setActiveTab] = useState(0);

  const [isLoadingPlayerOverview, setIsLoadingPlayerOverview] = useState(playerOverview === undefined);
  const [effectivePlayerOverview, setEffectivePlayerOverview] = useState<FlexibleJsonMapping | undefined>(playerOverview);

  const teams = useRecoilValue(teamsState);
  const squads = useRecoilValue(squadsState);

  const playerTeamData = useRecoilValue(playerTeamDataState);
  const playerSquadData = useRecoilValue(playerSquadDataState);

  const addPlayerDetails = useAddPlayerDetails();

  const [reports, setReports] = useState<PlayerReport[]>([]);
  const [playerHistory, setPlayerHistory] = useState<FlexibleJsonMapping[]>([]);

  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);

  const [isEditPlayerInfoModalOpen, setIsEditPlayerInfoModalOpen] = useState(false);
  const [editPlayerInfoProperty, setEditPlayerInfoProperty] = useState<string | undefined>(undefined);

  const [buttonHighlighted, setButtonHighlighted] = useState<string | undefined>(undefined);

  const [isConnectingPlayer, setIsConnectingPlayer] = useState(false);


  useEffect(() => {
    if (!selectedPlayer || !userConfig?.club) return;

    addPlayerDetails(selectedPlayer.id, currentUser);

    getReportsForPlayer(selectedPlayer.id, userConfig.club).then((data) => {
      setReports(data);
    });

  }, [currentUser, selectedPlayer, userConfig?.club]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (selectedPlayer && !playerOverview) {
      const numberId = Number(selectedPlayer.id);
      if (isNaN(numberId)) {
        setEffectivePlayerOverview(undefined);
        setIsLoadingPlayerOverview(false);
      }
      else {
        getPlayerOverviews([numberId], currentUser).then((data) => {
          setEffectivePlayerOverview(data ? Object.values(data)[0] : undefined);
          setIsLoadingPlayerOverview(false);
        });
      }
    }
    else if (playerOverview) {
      setIsLoadingPlayerOverview(false);
    }
    // console.log('playerOverview', playerOverview);
  }, [currentUser, playerOverview, selectedPlayer]);


  useEffect(() => {
    if (teams && userConfig?.club && selectedPlayer) {
      getCompletePlayerHistory(selectedPlayer.id, reports, userConfig.club).then((history) => {
        setPlayerHistory(history);
      });
    }
  }, [teams, reports, userConfig?.club, selectedPlayer]);


  const handleStatusButtonClick = () => {
    if (selectedPlayer && buttonHighlighted !== 'statusButton') {
      handleOpenPlayerViewModal(
        selectedPlayer,
        closePlayerModal,
        squadId,
        squadName
      );
    }
  };


  const closePlayerModal = (feedback = false) => {
    if (feedback) {
      setButtonHighlighted('statusButton');
      setTimeout(() => {
        setButtonHighlighted(undefined);
      }, 800);
    }
    setGlobalModalProps(undefined);
  };


  const handleEditInfoIconClick = (infoProperty: string) => {
    if (buttonHighlighted !== infoProperty) {
      setIsEditPlayerInfoModalOpen(true);
      setEditPlayerInfoProperty(infoProperty);
    }
  };


  const closeEditPlayerInfoModal = (infoProperty: string | undefined = undefined) => {
    setIsEditPlayerInfoModalOpen(false);

    if (infoProperty === 'role') {
      setTimeout(() => {
        setButtonHighlighted('role');
      }, 100);
      setTimeout(() => {
        setButtonHighlighted(undefined);
      }, 700);
    }
    else {
      setButtonHighlighted(infoProperty);
      setTimeout(() => {
        setButtonHighlighted(undefined);
      }, 800);
    }

    setTimeout(() => {
      setEditPlayerInfoProperty(undefined);
    }, 200);
  };


  const getDisplayName = () => {
    if (effectivePlayerOverview && 'fullname' in effectivePlayerOverview) {
      return effectivePlayerOverview['fullname'];
    }
    if (selectedPlayer && 'fullname' in selectedPlayer) {
      return selectedPlayer.fullname;
    }
    return '-';
  };


  const getStatusButtonText = () => {

    if (squadId !== undefined) {
      if (playerSquadData && selectedPlayer && selectedPlayer.id in playerSquadData && squadId in playerSquadData[selectedPlayer.id]) {
        return squads ? squads[squadId]['name'] : '';
      }
      return buttonHighlighted !== 'statusButton' && userConfig ? staticLanguageMap['addToSquad'][userConfig.language] : '';
    }

    if (playerTeamData && selectedPlayer && selectedPlayer.id in playerTeamData && userConfig) {
      return staticTeamKeys.includes(playerTeamData[selectedPlayer.id].currentTeam)
        ? staticLanguageMap[playerTeamData[selectedPlayer.id].currentTeam][userConfig.language]
        : (teams ? teams[playerTeamData[selectedPlayer.id].currentTeam]['name'] : '');
    }
    return buttonHighlighted !== 'statusButton' && userConfig ? staticLanguageMap['addToTeam'][userConfig.language] : '';
  };

  const getStatusButtonIcon = () => {
    if (squadId !== undefined) {
      if (playerSquadData && selectedPlayer && selectedPlayer.id in playerSquadData && squadId in playerSquadData[selectedPlayer.id]) {
        return <ConstructionIcon style={{ fontSize: 20 }} />;
      }
      return buttonHighlighted !== 'statusButton' ? <AddIcon style={{ fontSize: 20 }} /> : undefined;
    }

    if (playerTeamData && selectedPlayer && selectedPlayer.id in playerTeamData) {
      const currentTeam = playerTeamData[selectedPlayer.id].currentTeam;
      return getTeamIcon(currentTeam);
    }

    return buttonHighlighted !== 'statusButton' ? <AddIcon style={{ fontSize: 20 }} /> : undefined;
  };


  const getPlayerRoleIcon = () => {
    if (players && playerTeamData && selectedPlayer && selectedPlayer.id in playerTeamData) {
      const role = getPlayerPropertyValue('role', undefined, players[selectedPlayer.id]);
      return getRoleIcon(role, buttonHighlighted === 'role' ? clubColor : '#ffffffd7', 20, true);
    }
  };


  const clubColor = getClubColor(userConfig?.club ?? '');


  return (
    <div className='player-view-container'>

      {/* <Dialog
        open={isPlayerViewModalOpen}
        onClose={() => closePlayerModal(false)}
        PaperProps={{ sx: { borderRadius: '0px' } }}>
        <PlayerViewModal
          closeModal={closePlayerModal}
          squadId={squadId}
          squadName={squadName}
        />
      </Dialog> */}

      <Dialog
        open={isEditPlayerInfoModalOpen}
        onClose={() => closeEditPlayerInfoModal()}
        PaperProps={{ sx: { borderRadius: '0px' } }}>
        <EditPlayerInfoModal
          editPlayerInfoProperty={editPlayerInfoProperty}
          closeModal={closeEditPlayerInfoModal}
          playerToEdit={{
            ...selectedPlayer,

            contract_expiration: getPlayerPropertyValue(
              'contract_expiration',
              effectivePlayerOverview,
              players && selectedPlayer ? players[selectedPlayer.id] : undefined),
            contract_expiration_in_database: effectivePlayerOverview ? effectivePlayerOverview['contract_expiration'] : undefined,

            birth_date: getPlayerPropertyValue(
              'birth_date',
              effectivePlayerOverview,
              players && selectedPlayer ? players[selectedPlayer.id] : undefined),
            birth_date_in_database: effectivePlayerOverview ? effectivePlayerOverview['birth_date'] : undefined,

            role: players && selectedPlayer && selectedPlayer.id in players ? players[selectedPlayer.id].role : undefined,
            isPlayerWithoutData: isExistingPlayerWithoutData,
          }}
          isAcademyPlayer={
            (playerTeamData
              && selectedPlayer
              && selectedPlayer.id in playerTeamData
              && playerTeamData[selectedPlayer.id].currentTeam === 'academyTeam') ?? false
          }
        />
      </Dialog>

      <Dialog
        open={isHistoryModalOpen}
        onClose={() => setIsHistoryModalOpen(false)}
        PaperProps={{ sx: { borderRadius: '0px' } }}>
        <PlayerHistoryModal
          history={playerHistory}
          closeModal={() => setIsHistoryModalOpen(false)}
        />
      </Dialog>

      <ArrowBackIcon
        className='modal-icon player-view-exit-icon'
        style={{ fontSize: 26 }}
        onClick={() => setSelectedPlayer(undefined)}
      />

      <div className='player-view-header'>


        <div className='player-view-image-container'>
          {effectivePlayerOverview &&
            <img src={effectivePlayerOverview['image_url'] ?? 'https://media.api-sports.io/football/players/0.png'} alt='' className='player-view-image' draggable={false} />}

          {!effectivePlayerOverview && !isLoadingPlayerOverview &&
            <img src={'https://media.api-sports.io/football/players/0.png'} alt='' className='player-view-image' draggable={false} />}
        </div>

        <div className='player-view-info-section'>

          <div className='player-view-name'>
            {getDisplayName()}

            {userConfig && playerTeamData && selectedPlayer && selectedPlayer.id in playerTeamData &&
              (playerTeamData[selectedPlayer.id].currentTeam === 'ownTeam' || playerTeamData[selectedPlayer.id].currentTeam === 'academyTeam') &&
              (
                <div className='player-view-role-icon-container' >
                  <div onClick={() => handleEditInfoIconClick('role')}>
                    {getPlayerRoleIcon()}
                  </div>

                  <div className='player-view-role-hover-text-container'>
                    <div className='player-view-icon-button-hover-text'>
                      {userConfig
                        ? players && selectedPlayer && selectedPlayer.id in players && players[selectedPlayer.id].role
                          ? staticLanguageMap['editRole'][userConfig.language]
                          : staticLanguageMap['addRole'][userConfig.language]
                        : ''}
                    </div>
                  </div>
                </div>
              )}
          </div>

          {userConfig && (
            <div className='player-view-info-row'>
              <div className='player-view-info-column player-view-info-age-column'>
                <div className='player-view-info-label'>{staticLanguageMap['age'][userConfig.language]}</div>
                <div className='player-view-info-value player-view-info-value-row'>
                  {getPlayerPropertyValue(
                    'birth_date',
                    effectivePlayerOverview,
                    players && selectedPlayer ? players[selectedPlayer.id] : undefined,
                    getAge) ?? '-'}
                  {isExistingPlayerWithoutData && (
                    <div className='player-view-edit-info-icon' style={{ marginTop: -2 }}>
                      <EditIcon
                        style={{ fontSize: 16, color: buttonHighlighted === 'birth_date' ? clubColor : undefined }}
                        onClick={() => handleEditInfoIconClick('birth_date')}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className='player-view-info-column'>
                <div className='player-view-info-label'>{staticLanguageMap['position'][userConfig.language]}</div>
                <div className='player-view-info-value'>{getDisplayPosition(effectivePlayerOverview, userConfig)}</div>
              </div>
              <div className='player-view-info-column'>
                <div className='player-view-info-label'>{staticLanguageMap['nationality'][userConfig.language]}</div>
                <div className='player-view-info-value-row'>
                  {getPlayerPropertyValue(
                    'country_code',
                    effectivePlayerOverview,
                    players && selectedPlayer ? players[selectedPlayer.id] : undefined) &&
                    (
                      <img
                        className='player-view-flag'
                        src={countryCodeToCountryInfo[
                          getPlayerPropertyValue(
                            'country_code',
                            effectivePlayerOverview,
                            players && selectedPlayer ? players[selectedPlayer.id] : undefined)
                        ].flagUrl}
                        alt=''
                        draggable={false}
                      />
                    )}
                  <div className='player-view-info-value player-view-info-value-row'>
                    {getPlayerPropertyValue(
                      'country_code',
                      effectivePlayerOverview,
                      players && selectedPlayer ? players[selectedPlayer.id] : undefined)
                      ? countryCodeToCountryInfo[
                        getPlayerPropertyValue(
                          'country_code',
                          effectivePlayerOverview,
                          players && selectedPlayer ? players[selectedPlayer.id] : undefined)
                      ].name[userConfig.language]
                      : '-'}
                    {isExistingPlayerWithoutData && (
                      <div className='player-view-edit-info-icon' style={{ marginTop: -2 }}>
                        <EditIcon
                          style={{ fontSize: 16, color: buttonHighlighted === 'country_code' ? clubColor : undefined }}
                          onClick={() => handleEditInfoIconClick('country_code')}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='player-view-info-column'>
                <div className='player-view-info-label'>{staticLanguageMap['league'][userConfig.language]}</div>
                <div className='player-view-info-value'>{getDisplayCompetition(effectivePlayerOverview, competitions)}</div>
              </div>
              <div className='player-view-info-column'>
                <div className='player-view-info-label' style={{ display: 'flex', flexDirection: 'row' }}>
                  {staticLanguageMap['team'][userConfig.language]}

                  {effectivePlayerOverview && effectivePlayerOverview.on_loan_from && (
                    <div style={{ marginLeft: 7, fontSize: 11, display: 'flex', flexDirection: 'row', marginTop: 1.5, color: '#ffffffdd' }}>
                      <div style={{ marginTop: -0.5 }}>
                        {'('}
                      </div>

                      {staticLanguageMap['_loanFrom'][userConfig.language]}

                      {effectivePlayerOverview && effectivePlayerOverview.on_loan_from && effectivePlayerOverview.on_loan_from.logo_url && (
                        <div className='player-view-loan-club-logo-container'>
                          <img
                            className='player-view-loan-club-logo'
                            src={effectivePlayerOverview.on_loan_from.logo_url}
                            alt=''
                            draggable={false}
                          />
                        </div>
                      )}

                      <div className='player-view-info-valuesfd'>
                        {effectivePlayerOverview?.on_loan_from?.name ?? '-'}
                      </div>

                      <div style={{ marginTop: -0.5 }}>
                        {')'}
                      </div>
                    </div>
                  )}
                </div>
                <div className='player-view-info-value-row'>
                  {effectivePlayerOverview && effectivePlayerOverview.club && effectivePlayerOverview.club.logo_url && (
                    <div className='player-view-club-logo-container'>
                      <img
                        className='player-view-club-logo'
                        src={effectivePlayerOverview.club.logo_url}
                        alt=''
                        draggable={false}
                      />
                    </div>
                  )}
                  <div
                    className='player-view-info-value'
                    style={{ marginLeft: effectivePlayerOverview && effectivePlayerOverview.club && effectivePlayerOverview.club.logo_url ? 20 : undefined }}
                  >
                    {effectivePlayerOverview?.club?.name ?? '-'}
                  </div>
                </div>
              </div>
              <div className='player-view-info-column'>
                <div className='player-view-info-label'>{staticLanguageMap['height'][userConfig.language]}</div>
                <div className='player-view-info-value'>{getPlayerPropertyValue('height', effectivePlayerOverview) ?? '-'}</div>
              </div>
              <div className='player-view-info-column player-view-info-contract-column'>
                <div className='player-view-info-label'>{staticLanguageMap['contract'][userConfig.language]}</div>
                <div className='player-view-info-value'>
                  {getPlayerPropertyValue(
                    'contract_expiration',
                    effectivePlayerOverview,
                    players && selectedPlayer ? players[selectedPlayer.id] : undefined,
                    (value) => getDateLocaleFormat(value)) ?? '-'}
                </div>
              </div>
            </div>
          )}
        </div>

        {(buttonHighlighted === 'contract_expiration' || (playerTeamData && selectedPlayer && selectedPlayer.id in playerTeamData &&
          (playerTeamData[selectedPlayer.id].currentTeam === 'ownTeam' || playerTeamData[selectedPlayer.id].currentTeam === 'academyTeam'))) &&
          (
            <div className='player-view-icon-button player-view-edit-contract-icon-button'>
              <div className='player-view-edit-contract-icon'>
                <EditCalendarIcon
                  style={{ fontSize: 18, color: buttonHighlighted === 'contract_expiration' ? clubColor : undefined }}
                  onClick={() => handleEditInfoIconClick('contract_expiration')}
                />
              </div>
              <div className='player-view-edit-contract-hover-text-container'>
                <div className='player-view-icon-button-hover-text'>
                  {userConfig ? staticLanguageMap['editContract'][userConfig.language] : ''}
                </div>
              </div>
            </div>
          )}

        <div className='player-view-icon-button player-view-history-icon-button' onClick={() => setIsHistoryModalOpen(true)}>
          <div className='player-view-history-icon'>
            <LibraryBooksIcon style={{ fontSize: 20 }} />
          </div>
          <div className='player-view-history-hover-text-container'>
            <div className='player-view-icon-button-hover-text'>
              {userConfig ? staticLanguageMap['playerHistory'][userConfig.language] : ''}
            </div>
          </div>
        </div>

        {!isDeletedPlayerWithoutData && (
          <div>
            <div className='player-view-team-status-text'>
              {userConfig
                ? (squadId !== undefined ? staticLanguageMap['squadStatus'][userConfig.language] : staticLanguageMap['teamStatus'][userConfig.language])
                : ''
              }
            </div>

            <div className='player-view-team-status-button-container'>
              <div
                className='player-view-team-status-button'
                style={{ backgroundColor: buttonHighlighted === 'statusButton' ? clubColor : '#fbfcfd' }}
                onClick={() => handleStatusButtonClick()}
              >
                <div className='player-view-team-status-button-text'>
                  {getStatusButtonText()}&nbsp;
                </div>

                <div className='player-view-team-status-button-icon'>
                  {getStatusButtonIcon()}
                </div>

                <div
                  className={
                    'player-view-team-status-button-overlay' +
                    (buttonHighlighted === 'statusButton' ? ' player-view-team-status-button-overlay-highlighted' : '')
                  }
                >
                  &nbsp;
                </div>
              </div>
            </div>
          </div>
        )}

      </div>

      {!isDeletedPlayerWithoutData && !isNewPlayerWithoutData && (
        <FixedTabLine
          tabOptions={tabOptions}
          tabIcons={tabIcons}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          marginTop={'13vh'}
          marginTopLine={'16.9vh'}

          isPlayerView={true}
          playerOverview={effectivePlayerOverview}
          isLoadingPlayerOverview={isLoadingPlayerOverview}
        />
      )}

      {activeTab === 0 && !isDeletedPlayerWithoutData && effectivePlayerOverview && (
        <PlayerViewOverview
          playerOverview={effectivePlayerOverview}
        />
      )}

      {activeTab === 0 && isExistingPlayerWithoutData && (
        <PlayerViewWithoutData
          setIsConnectingPlayer={setIsConnectingPlayer}
        />
      )}

      {activeTab === 1 && effectivePlayerOverview && (
        <PlayerViewCompare
          isGoalkeeper={effectivePlayerOverview ? effectivePlayerOverview['primary_position'] === 'GK' : false}
        />
      )}

      {activeTab === 2 && effectivePlayerOverview &&
        <PlayerViewCareer />}

      {activeTab === 3 && (
        <PlayerViewReports
          playerOverview={effectivePlayerOverview}
          reports={reports}
          setReports={setReports}
        />
      )}


      {isDeletedPlayerWithoutData && !isConnectingPlayer && (
        <div className='player-view-deleted-player-text'>
          {userConfig ? staticLanguageMap['playerIsDeleted'][userConfig.language] : ''}
        </div>
      )}

    </div>
  );
};
