import './playerView.css';

import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { selectedPlayerState } from '../../recoil/atoms/selectedPlayerState';
import { playerDetailsState } from '../../recoil/atoms/playerDetailsState';
import { competitionsState } from '../../recoil/atoms/competitionsState';
import { userSettingsState } from '../../recoil/atoms/userSettingsState';

import { staticLanguageMap } from '../../../common/static/staticLanguageMap';
import { CareerTable } from '../tables/careerTable/CareerTable';
import { getSeasonStartDate } from '../../views/economy/Economy';
import { Club } from '../../types';


export interface CareerIterationEntry {
  club: Club;
  minutes_played: number;
  appearances: number;
  games_started: number;
  primary_position: string;
  secondary_position?: string;
  n_goals: number;
  n_assists: number;

  // for club iterations
  season?: string;
  competition_id?: string;
  plot_date?: string;

  // for national iterations
  country_code?: string;
}

export const PlayerViewCareer: React.FC = () => {

  const userConfig = useRecoilValue(userConfigState);
  const selectedPlayer = useRecoilValue(selectedPlayerState);
  const competitions = useRecoilValue(competitionsState);
  const userSettings = useRecoilValue(userSettingsState);

  const playerDetails = useRecoilValue(playerDetailsState);
  const [playerClubDetailsArray, setPlayerClubDetailsArray] = useState<CareerIterationEntry[]>([]);
  const [playerNationalDetailsArray, setPlayerNationalDetailsArray] = useState<CareerIterationEntry[]>([]);


  useEffect(() => {
    if (playerDetails && selectedPlayer && selectedPlayer.id in playerDetails && userSettings) {

      const clubIterationMap = playerDetails[Number(selectedPlayer.id)];
      const clubDetailsArray: CareerIterationEntry[] = [];
      let nationalDetailsArray: CareerIterationEntry[] = [];

      // set containing keys that should be unique, used temporary to mitigate issue with duplicate entries
      const uniqueClubIterationKeys: Set<string> = new Set();

      Object.keys(clubIterationMap).forEach((clubIterationId) => {

        const clubIteration = clubIterationMap[clubIterationId];

        const iterationEntry: CareerIterationEntry = {
          club: clubIteration['club'],
          minutes_played: clubIteration['minutes_played'],
          appearances: clubIteration['appearances'],
          games_started: clubIteration['games_started'],
          primary_position: clubIteration['primary_position'],
          secondary_position: clubIteration['secondary_position'],
          n_goals: clubIteration['n_goals'],
          n_assists: clubIteration['n_assists'],
        };

        // national teams will be sorted based on the team (U17, U19, U21, A)
        if (clubIteration['club']?.is_national_team) {
          iterationEntry['country_code'] = clubIteration['country_code'];

          nationalDetailsArray.push(iterationEntry);
        }
        else if (!clubIteration['club']?.is_national_team) {

          const isCup = competitions && clubIteration['competition_id'] in competitions && competitions[clubIteration['competition_id']].type === 'Cup';
          if (isCup && userSettings.seasonStatsToggles?.hideCareerCups) {
            return null;
          }

          const uniqueClubIterationKey =
            clubIteration['club']?.id + '_' +
            clubIteration['season'] + '_' +
            clubIteration['country_code'] + '_' +
            clubIteration['appearances'] + '_' +
            clubIteration['n_goals'] + '_' +
            clubIteration['n_assists'] + '_' +
            clubIteration['minutes_played'] + '_';

          if (!uniqueClubIterationKeys.has(uniqueClubIterationKey)) {
            iterationEntry['season'] = clubIteration['season'];
            iterationEntry['competition_id'] = clubIteration['competition_id'];

            const countryCode = (competitions && clubIteration['competition_id'] in competitions)
              ? competitions[clubIteration['competition_id']]?.countryCode
              : undefined;

            iterationEntry['country_code'] = countryCode;

            // if plot_date exists, we will use that, if not we will create one based on the season string
            if (clubIteration['plot_date']) {
              iterationEntry['plot_date'] = clubIteration['plot_date'];
            }
            else {
              const seasonString = clubIteration['season'];
              const plotDate = getSeasonStartDate(seasonString).toISOString();
              iterationEntry['plot_date'] = plotDate;
            }

            uniqueClubIterationKeys.add(uniqueClubIterationKey);
            clubDetailsArray.push(iterationEntry);
          }
        }
      });

      // aggregate national team data into one row per team (U17, U19, U21, A)
      interface NationalTeamEntry extends CareerIterationEntry {
        totalValues: { [key: string]: number };
      }
      nationalDetailsArray = Object.values(nationalDetailsArray.reduce((acc: { [key: string]: NationalTeamEntry }, item: CareerIterationEntry) => {
        const clubId = item.club.id.toString();
        if (!acc[clubId]) {
          acc[clubId] = { ...item, totalValues: { [item['primary_position']]: item['appearances'] } };
        } else {
          acc[clubId]['minutes_played'] += item['minutes_played'];
          acc[clubId]['appearances'] += item['appearances'];
          acc[clubId]['games_started'] += item['games_started'];
          acc[clubId]['n_goals'] += item['n_goals'];
          acc[clubId]['n_assists'] += item['n_assists'];

          if (!acc[clubId].totalValues[item['primary_position']]) {
            acc[clubId].totalValues[item['primary_position']] = item['appearances'];
          } else {
            acc[clubId].totalValues[item['primary_position']] += item['appearances'];
          }

          // Select the position with the highest total number of appearances
          acc[clubId]['primary_position'] = Object.keys(
            acc[clubId].totalValues
          ).reduce(
            (a, b) => acc[clubId].totalValues[a] > acc[clubId].totalValues[b] ? a : b
          );
        }
        return acc;
      }, {}));

      clubDetailsArray.sort((a, b) => b.plot_date && a.plot_date ? b.plot_date.localeCompare(a.plot_date) : 0);
      nationalDetailsArray.sort((a, b) => b.club?.name && a.club?.name ? b.club.name.localeCompare(a.club.name) : 0);

      setPlayerClubDetailsArray(clubDetailsArray);
      setPlayerNationalDetailsArray(nationalDetailsArray);
    }
  }, [competitions, playerDetails, selectedPlayer, userSettings]);


  const getNationalTableHeight = () => {
    const height = playerNationalDetailsArray.length > 0
      ? 31 + (playerNationalDetailsArray.length * 37)
      : 38;

    // todo: disable scroll-y for national table if height is less than 300
    // return Math.min(height, 300);
    return height;
  };


  return (
    <div className='player-view-main-section'>
      <div className='player-view-career-container'>

        <div className='player-view-career-national-entry' style={{ height: getNationalTableHeight() }}>

          {playerNationalDetailsArray.length === 0 && (
            <div className='player-view-career-entry-top-section'>
              <div className='player-view-career-entry-title'>
                {userConfig ? staticLanguageMap['noNationalCareer'][userConfig.language] : ''}
              </div>
            </div>
          )}

          {playerNationalDetailsArray.length > 0 && (
            <CareerTable
              data={playerNationalDetailsArray}
              isNationalTeam={true}
            />
          )}
        </div>

        <div className='player-view-career-club-entry' style={{ top: getNationalTableHeight() + 24 }}>
          {playerClubDetailsArray.length > 0 && (
            <CareerTable
              data={playerClubDetailsArray}
              isNationalTeam={false}
            />
          )}
        </div>

      </div>
    </div>
  );
};
