import axios from 'axios';

import { FirebaseUser } from '../../../firebase';
import { PlayerDetails, PlayerId } from '../../types';

import { BASE_URL } from './constants';
import { getBearerToken } from './token';


// Get player details for all given playerIds
export const getPlayerDetails = async (
  playerIds: PlayerId[],
  user: FirebaseUser
): Promise<PlayerDetails | undefined> => {

  playerIds = playerIds.filter((playerId) => !isNaN(Number(playerId))).map((playerId) => Number(playerId));

  if (playerIds.length === 0) return undefined;

  const token = await getBearerToken(user);
  const playerIdsString = playerIds.join(',');

  const result = await axios.get(`${BASE_URL}/playerDetails/${playerIdsString}`, {
    headers: { Authorization: token },
  });

  if (result === undefined) {
    return undefined;
  }

  return result.data;
};
